import styled from 'styled-components'

export const HeaderLanguage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 100px;
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.55);
`

export const Label = styled.div`
  font-weight: 500;
`

export const StyleForm = styled.form`
  max-width: 500px;
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 12px 201px 0 rgb(0 0 0 / 6%);
  padding: 40px;

  .login {
    &__title {
      text-align: center;
      font-size: 2rem;
      padding: 20px;
      font-weight: 500;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    &__logo {
      display: flex;
      justify-content: center;

      .img__logo {
        width: 100px;
      }
    }

    &__form-detail {
      .field {
        margin-bottom: 2rem;

        ${Label} {
          margin-bottom: 5px;
        }
        
        .field-input {
          border-radius: 5px;
          height: 40px;

          > * {
            background-color: transparent;
          }
        }

        &.btn-login {
          margin-top: 60px;
          margin-bottom: 0rem;
          
          button {
            height: 46px;
            text-transform: uppercase;
            font-weight: 700;
          }
        }
      }
    }
  }
`

export const BoxForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 80px 20px;
`

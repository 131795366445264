import { Avatar, List, Typography } from 'antd'
import Box from 'components/box/Box'
import Container from 'components/container/Container'
import TitleHeader from 'components/title-header'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Path from '../../commons/breadcrumbs'
import { HeaderList } from './index.style'
import { fakeDataUsers } from './sampleUsersData'

const users = fakeDataUsers
const { Title, Paragraph } = Typography

const UsersAndPermissions = () => {
  const { t } = useTranslation()

  const storeOwner = users.filter((object) => object.isOwner === true)
  console.log('Store Owner', storeOwner)
  return (
    <>
      <TitleHeader
        listOfBreadcrumb={[
          { name: 'Home', path: '/' },
          { name: Path.SETTINGS.name, path: Path.SETTINGS.pathname },
          {
            name: Path.USERS_AND_PERMISIONS.name,
          },
        ]}
        title={t(Path.USERS_AND_PERMISIONS.name)}
      />
      <Box>
        <Container>
          <Title level={3}>{t('Permissions')}</Title>
          <Paragraph>
            {t('Manage what users can see or do in your store.')}
          </Paragraph>
          <List
            header={
              <HeaderList>
                <h1>Store Owner ({storeOwner?.length})</h1>{' '}
                <Link to='/settings/users-and-permissions/add-staff'>
                  Add Staff
                </Link>
              </HeaderList>
            }
            dataSource={storeOwner}
            bordered
            style={{ marginBottom: '24px' }}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  style={{ alignItems: 'center' }}
                  avatar={
                    <Avatar
                      style={{
                        border: '1px solid black',
                      }}
                      src={`https://ui-avatars.com/api/?name=${
                        item.firstName
                      }+${item.lastName ? item.lastName : ''}`}
                    />
                  }
                  title={
                    <Link
                      to={`/settings/users-and-permissions/edit-staff/${item.id}`}
                    >
                      {item.firstName + ' ' + item.lastName}
                    </Link>
                  }
                  description={`Last login was ${item.lastLogin}`}
                />
              </List.Item>
            )}
          />
          <List
            header={
              <HeaderList>
                <h1>Staff ({users.length})</h1>{' '}
                <Link to='/settings/users-and-permissions/add-staff'>
                  Add Staff
                </Link>
              </HeaderList>
            }
            dataSource={users}
            bordered
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  style={{ alignItems: 'center' }}
                  avatar={
                    <Avatar
                      style={{
                        border: '1px solid black',
                      }}
                      src={`https://ui-avatars.com/api/?name=${
                        item.firstName
                      }+${item.lastName ? item.lastName : ''}`}
                    />
                  }
                  title={
                    <Link
                      to={{
                        pathname: `/settings/users-and-permissions/edit-staff/${item.id}`,
                        state: {
                          id: 1,
                          firstName: item.firstName,
                          lastName: item.lastName,
                          email: item.email,
                          isOwner: item.isOwner,
                        },
                      }}
                    >
                      {item.firstName + ' ' + item.lastName}
                    </Link>
                  }
                  description={`Last login was ${item.lastLogin}`}
                />
                {item.permissionsPage.length > 12 ? (
                  <div>Store Owner</div>
                ) : (
                  <div>Limited Permission</div>
                )}
              </List.Item>
            )}
          />
        </Container>
      </Box>
    </>
  )
}

export default UsersAndPermissions

import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { mediaApi } from "apis/mediaApi";
import purchaseApi from "apis/purchaseApi";
import styled from "styled-components";

const { Dragger } = Upload;

const DragDropFile = ({ selectedRowKeys, purchaseItem, setUploaded }) => {
  const activePurchaseItem = purchaseItem.filter((item) =>
    selectedRowKeys.includes(item.key)
  );
  const uploadProof = (options) => {
    const { onSuccess, onError, file } = options;
    const formData = new FormData();
    formData.append("file", file);
    mediaApi
      .uploadProofOfPurchase(formData)
      .then(({ proofOfPurchase }) => {
        console.log("RESPONSE FROM UPLOADED IMAGE!!!", proofOfPurchase);
        onSuccess("Uploaded");

        Promise.all(
          activePurchaseItem.map((item) =>
            purchaseApi.putUpdateProofOfPurchase(item.id, proofOfPurchase.id)
          )
        )
          .then(({ foundPurchaseItem }) => {
            message.success("Succesfully update Proof of Purchase");
            setUploaded(true);
            return foundPurchaseItem;
          })
          .catch((error) => message.error(error));
      })
      .catch((error) => onError(error.message));
  };

  return (
    <CustomDragger>
      <Dragger
        disabled={!activePurchaseItem.length}
        className="parrent"
        name="file"
        multiple={true}
        customRequest={uploadProof}
        showUploadList={true}
        onChange={(info) => console.log(info)}
      >
        <p
          className="ant-upload-drag-icon"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <InboxOutlined disabled={!activePurchaseItem.length} />
        </p>
        <p className="ant-upload-text">
          Please select a purchase to enable upload..
        </p>
        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
      </Dragger>
    </CustomDragger>
  );
};

export default DragDropFile;

const CustomDragger = styled.div`
  span {
    display: flex;
    justify-content: start;
    .ant-upload.ant-upload-drag {
      width: 50%;
      margin: 20px;
      justify-self: center;
    }

    .ant-upload-list.ant-upload-list-picture {
      width: 50%;
      margin: 20px;

      .ant-upload-list-item {
        border: 1px solid black;
        width: 200px;
        margin-right: 8px;
      }
    }
  }
`;

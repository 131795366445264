import { Card, Col, DatePicker, Form, Input, Row } from "antd";
import React from "react";

function AuthenticationDetails({ form }) {
  return (
    <Card title="Authentication details" style={{ marginBottom: 12 }}>
      <Form.Item noStyle shouldUpdate={({ apiType: prev }, { apiType: current }) => prev !== current}>
        {() => form.getFieldValue('apiType') !== 'fedex' ? (
          <Row gutter={[10]}>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name="key"
                label="Api Secret Key"
                rules={[{ required: true, message: "Api Secret Key is required" }]}
              >
                <Input.Password maxLength={150} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name="password"
                label="Api PassKey"
                rules={[
                  {
                    required: true,
                    message: "Api PassKey is required",
                  },
                ]}
              >
                <Input.Password maxLength={150} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name="apiUrl"
                label="Api Base Url"
                rules={[
                  {
                    required: true,
                    message: "Api Base Url is required",
                  },
                ]}
              >
                <Input maxLength={150} />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row gutter={[10]}>
            <Col xs={24} sm={24} md={16}>
              <Row gutter={[10]}>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    name="key"
                    label="Api Secret Key"
                    rules={[{ required: true, message: "Api Secret Key is required" }]}
                  >
                    <Input.Password maxLength={150} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    name="password"
                    label="Api PassKey"
                    rules={[
                      {
                        required: true,
                        message: "Api PassKey is required",
                      },
                    ]}
                  >
                    <Input.Password maxLength={150} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    name="apiUrl"
                    label="Api Base Url"
                    rules={[
                      {
                        required: true,
                        message: "Api Base Url is required",
                      },
                    ]}
                  >
                    <Input maxLength={150} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item Expiry
                    name="fedexOauthTokenExpiredAt"
                    label="Token Expiry"
                  >
                    <DatePicker showTime style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item Expiry
                name="fedexOauthToken"
                label="Oauth Token"
              >
                <Input.TextArea rows={5}/>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form.Item>
    </Card>
  );
};

export default AuthenticationDetails;

import CreatedShipment from "pages/created-shipment";
import Languages from "pages/languages";
import MakePurchase from "pages/make-purchase";
import PendingPurchases from "pages/pending-purchase";
import Policies from "pages/policies";
import RateShipment from "pages/rate-shipment";
import SalesAndChannels from "pages/sales-and-channels";
import StoreDetails from "pages/store-details";
import { Couriers, CourierDetail } from "pages/couriers";
import UsersAndPermissions from "pages/users-and-permissions";
import AddStaff from "pages/users-and-permissions/AddStaff";
import EditStaffWithId from "pages/users-and-permissions/EditStaffWithId";
import Shipments from "../pages/all-shipments";
import CreateOrder from "../pages/create-order";
import CreateProduct from "../pages/create-product";
import CreateShipment from "../pages/create-shipment";
import Login from "../pages/login/Login";
import OrderDetail from "../pages/order-detail";
import Orders from "../pages/orders";
import ProductDetail from "../pages/product-detail";
import Products from "../pages/products";
import PurchaseOrders from "../pages/purchase-order";
import Breadcrumbs from "./breadcrumbs";
import ShipperDetails from "pages/shipper-details";

const routes = [
  {
    exact: true,
    title: "Orders Detail",
    path: Breadcrumbs.ORDER_DETAIL.pathname,
    component: OrderDetail,
    permission: ["orderDetails"],
    loginRequired: false,
  },
  {
    exact: true,
    title: "Create Order",
    path: Breadcrumbs.CREATE_ORDER.pathname,
    component: CreateOrder,
    permission: ["createOrder"],
    loginRequired: true,
  },
  {
    exact: true,
    title: "Orders Management",
    path: Breadcrumbs.ORDERS.pathname,
    component: Orders,
    permission: ["orders"],
    loginRequired: false,
  },
  {
    title: "Shipment Management",
    path: Breadcrumbs.SHIPMENTS.pathname,
    exact: true,
    component: Shipments,
    permission: ["shipments"],
    loginRequired: true,
  },
  {
    title: "Shipper Details",
    path: Breadcrumbs.SHIPPER_DETAILS.pathname,
    exact: true,
    component: ShipperDetails,
    permission: ["shipperDetails"],
    loginRequired: true,
  },
  {
    title: "Create Shipment",
    path: Breadcrumbs.CREATE_SHIPMENT.pathname,
    exact: true,
    component: CreateShipment,
    permission: ["createShipment"],
    loginRequired: true,
  },
  {
    exact: true,
    title: "Product Detail",
    path: Breadcrumbs.PRODUCT_DETAIL.pathname,
    component: ProductDetail,
    permission: ["productDetail"],
  },
  {
    exact: true,
    title: "Create Product",
    path: Breadcrumbs.CREATE_PRODUCT.pathname,
    component: CreateProduct,
    permission: ["createProduct"],
  },
  {
    title: "Products",
    path: Breadcrumbs.PRODUCTS.pathname,
    exact: true,
    component: Products,
    permission: ["products"],
    loginRequired: false,
  },
  {
    title: "Store Details",
    path: Breadcrumbs.STORE_DETAILS.pathname,
    exact: true,
    component: StoreDetails,
    permission: ["storeDetails"],
    loginRequired: true,
  },
  {
    title: "Courier Accounts",
    path: Breadcrumbs.COURIERS.pathname,
    exact: true,
    component: Couriers,
    permission: ["storeDetails"],
    loginRequired: true,
  },
  {
    title: "Courier Accounts",
    path: Breadcrumbs.COURIER_ADD.pathname,
    exact: true,
    component: CourierDetail,
    permission: ["storeDetails"],
    loginRequired: true,
  },
  {
    title: "Courier Accounts",
    path: Breadcrumbs.COURIER_UPDATE.pathname,
    exact: true,
    component: CourierDetail,
    permission: ["storeDetails"],
    loginRequired: true,
  },
  {
    title: "Users And Permissions",
    path: Breadcrumbs.USERS_AND_PERMISIONS.pathname,
    exact: true,
    component: UsersAndPermissions,
    permission: ["usersAndPermissions"],
    loginRequired: true,
  },
  {
    title: "Sales And Channels",
    path: Breadcrumbs.SALES_AND_CHANNELS.pathname,
    exact: true,
    component: SalesAndChannels,
    permission: ["salesAndChannels"],
    loginRequired: true,
  },
  {
    title: "Languages",
    path: Breadcrumbs.LANGUAGES.pathname,
    exact: true,
    component: Languages,
    permission: ["languages"],
    loginRequired: true,
  },
  {
    title: "Policies",
    path: Breadcrumbs.POLICIES.pathname,
    exact: true,
    component: Policies,
    permission: ["policies"],
    loginRequired: true,
  },
  {
    title: "Add Staff",
    path: Breadcrumbs.ADD_STAFF.pathname,
    exact: true,
    component: AddStaff,
    permission: ["addStaff"],
    loginRequired: true,
  },
  {
    title: "Edit Staff",
    path: Breadcrumbs.EDIT_STAFF_WITH_ID.pathname,
    exact: true,
    component: EditStaffWithId,
    permission: ["editStaff"],
    loginRequired: true,
  },
  {
    title: "Pending Purchases",
    path: Breadcrumbs.PENDING_PURCHASE.pathname,
    exact: true,
    component: PendingPurchases,
    // permission: [],
    loginRequired: false,
  },
  {
    title: "Purchase Orders",
    path: Breadcrumbs.PURCHASE_ORDERS.pathname,
    exact: true,
    component: PurchaseOrders,
    // permission: [],
    loginRequired: false,
  },
  {
    title: "Make Purchase",
    path: Breadcrumbs.MAKE_PURCHASE.pathname,
    component: MakePurchase,
    loginRequired: false,
  },
  {
    title: "Created Shipment",
    path: Breadcrumbs.CREATED_SHIPMENT.pathname,
    component: CreatedShipment,
    loginRequired: false,
  },
  {
    title: "Rate Shipment",
    path: Breadcrumbs.RATE_SHIPMENT.pathname,
    component: RateShipment,
    loginRequired: false,
  },
];

// Routes will rejected when logged
const routesSpecialLogged = [
  {
    title: "Login",
    path: Breadcrumbs.LOGIN,
    exact: true,
    component: Login,
    loginRequired: false,
  },
];

export { routes, routesSpecialLogged };

import styled from 'styled-components'

export const VariantGrid = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
`

export const VariantCard = styled.button`
  border: none;
  border-radius: 20px;
  height: 155px;
  width: 155px;
  overflow: hidden;
  position: relative;
  &:focus {
  }

  .variantTitle {
    margin: 15px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    z-index: 2;
  }

  .variantTitleGradient {
    background: rgb(0, 0, 0);
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.6839110644257703) 35%,
      rgba(0, 0, 0, 0.4150035014005602) 55%,
      rgba(0, 0, 0, 0.21052170868347342) 71%,
      rgba(0, 0, 0, 0) 87%
    );
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  img {
    height: 155px;
    width: 155px;
    object-fit: cover;
  }
`
export const AddVariantBtn = styled.button`
  border-radius: 20px;
  border: 1px dotted #67676749;
  height: 155px;
  width: 155px;
  overflow: hidden;
  position: relative;

  img {
    height: 155px;
    width: 155px;
    object-fit: cover;
  }
`

import { Col } from "antd";
import styled from "styled-components";

export const CourierButton = styled.button`
  width: 100%;
  text-align: left;
  padding: 10px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  background: transparent;
  transition: cubic-bezier(0.17, 0.67, 0.83, 0.67);

  &.isActive {
    background: #0073ff;
    color: white;
  }
  &.secondaryActive {
    background: #0a2463;
    color: white;
  }

  &:active {
    background-color: #0a2463;
    transform: translateY(4px);
  }
`;

export const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  background-color: #edf1f5;
  padding: 12px 24px;
  border-bottom: 1px solid #78828c21;
  h1 {
    font-size: 16px;
    text-transform: uppercase;
    color: #3e5569;
    font-weight: 600;
    margin-bottom: 0;
  }
`;

export const FormWrapper = styled.div`
  background-color: #fff;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #f3f3f3;

  .greenBoldTitle {
    font-size: 1rem;
    font-weight: 600;
    color: #2d9a2a;
  }
  .redBoldTitle {
    font-size: 1rem;
    font-weight: 600;
    color: #dc143c;
  }
`;

export const FormHeader = styled.div`
  padding: 10px;
  border-bottom: 1px solid #f3f3f3;
  background-color: rgba(235, 243, 254, 0.47);
`;

export const FormBody = styled.div`
  padding: 10px;
  .greyText {
    color: #888888;
  }
`;

export const ButtonPlacement = styled.div`
  display: flex;
  justify-content: end;

  .submit {
    border: 3px solid #28a745;
    color: #28a745;

    &:hover {
      background-color: #28a745;
      color: white;
      border: none;
    }

    &:disabled {
      border: none;
    }
  }

  .reset {
    border: 3px solid #ff0062;
    color: #ff0062;

    &:hover {
      background-color: #ff0062;
      color: white;
      border: none;
    }

    &:disabled {
      border: none;
    }
  }

  .misc {
    border: 3px solid #ffab2e;
    color: #ffab2e;

    &:hover {
      background-color: #ffab2e;
      color: white;
      border: none;
    }

    &:disabled {
      border: none;
    }
  }

  button {
    margin: 5px;
    background-color: white;
    border-radius: 5px;
    font-weight: 600;
    line-height: 10px;
  }
`;
export const CustomerDetails = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  margin-bottom: 2px;
`;
export const LineItem = styled.li`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;
export const ShippingAccountsGroupsWrapper = styled(Col)`
  .title {
    font-weight: 600;
  }
  .shipping-accounts-group {
    padding-top: 4px;
    padding-bottom: 8px;
    .api-type {
      font-weight: 600;
    }
    .ant-card {
      box-shadow: 0px 2px 4px #c0c0c0;
      border-radius: 4px;
    }
    .ant-card-body {
      padding: 4px 8px;
      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        .ant-checkbox {
          margin-bottom: 6px;
        }
      }
    }
  }
`;

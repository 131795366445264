import styled from 'styled-components'

export const SalesFunnelButton = styled.div`
  button {
    padding: 20px;
    border-radius: 20px;
    border: 0.1px solid #96969623;
    height: 100%;
    width: 100%;
    background-color: transparent;

    &:hover {
      border: 0.1px solid #96969648;
      margin: 0;
    }
  }
`

import styled from 'styled-components'

export const HeaderList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1,
  a {
    margin: none !important;
  }
`

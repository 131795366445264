import * as React from 'react'

const HarumioLogo = (props) => (
  <svg
    viewBox='0 0 70 70'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect y='7' width='30.6849' height='4.79452' fill='#CE2F3A' />
    <rect y='16.589' width='30.6849' height='4.79452' fill='#CE2F3A' />
    <rect y='25.2192' width='30.6849' height='4.79452' fill='#CE2F3A' />
    <rect x='39.3151' y='7' width='30.6849' height='4.79452' fill='#CE2F3A' />
    <rect
      x='39.3151'
      y='25.2192'
      width='30.6849'
      height='4.79452'
      fill='#CE2F3A'
    />
    <rect y='39.6027' width='12.4658' height='4.79452' fill='#00469F' />
    <rect
      x='39.3151'
      y='39.6027'
      width='12.4658'
      height='4.79452'
      fill='#00469F'
    />
    <rect
      x='39.3151'
      y='49.1918'
      width='12.4658'
      height='4.79452'
      fill='#00469F'
    />
    <rect
      x='39.3151'
      y='16.589'
      width='12.4658'
      height='4.79452'
      fill='#CE2F3A'
    />
    <rect
      x='39.3151'
      y='57.8219'
      width='12.4658'
      height='4.79452'
      fill='#00469F'
    />
    <rect y='57.8219' width='12.4658' height='4.79452' fill='#00469F' />
    <rect
      x='18.2192'
      y='39.6027'
      width='12.4658'
      height='4.79452'
      fill='#00469F'
    />
    <rect
      x='57.5342'
      y='39.6027'
      width='12.4658'
      height='4.79452'
      fill='#00469F'
    />
    <rect
      x='57.5342'
      y='49.1918'
      width='12.4658'
      height='4.79452'
      fill='#00469F'
    />
    <rect
      x='57.5342'
      y='16.589'
      width='12.4658'
      height='4.79452'
      fill='#CE2F3A'
    />
    <rect
      x='57.5342'
      y='57.8219'
      width='12.4658'
      height='4.79452'
      fill='#00469F'
    />
    <rect
      x='18.2192'
      y='57.8219'
      width='12.4658'
      height='4.79452'
      fill='#00469F'
    />
    <rect y='49.1918' width='30.6849' height='4.79452' fill='#00469F' />
  </svg>
)

export default HarumioLogo

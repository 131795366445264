import React from "react";
import { Button, Space, Spin, Table } from "antd";
import { debounce, findKey } from "lodash";
import shipmentsApi from "apis/shipmentsApi";
import produce from "immer";
import { Link } from "react-router-dom";
import Path from "../../commons/breadcrumbs";
import axios from "axios";

const ShipmentTable = ({
  data,
  params,
  setParams,
  getShipments,
  setLabelModal,
  labelLoading,
  setLabelLoading,
}) => {
  const requestZpl = async (zplString, width, height) => {
    const labelNumbers = zplString.match(/XA/g);
    console.log("ZPL STRING", zplString);
    if (labelNumbers.length > 1) {
      setLabelLoading(true);

      try {
        const res = await Promise.all(
          labelNumbers.map((item, index) =>
            axios({
              method: "post",
              url: `https://api.labelary.com/v1/printers/8dpmm/labels/${width}x${height}/${index}/`,
              headers: {
                // prettier-ignore
                "Accept": "image/png",
                "Content-Type": "application/x-www-form-urlencoded",
              },
              data: JSON.stringify(zplString),
              responseType: "blob",
            })
          )
        );

        const restructure = res.map((blobItem) => blobItem.data);
        const urlBlobArr = restructure?.map((item) =>
          URL.createObjectURL(item)
        );
        console.log(urlBlobArr);
        setLabelModal((prev) => {
          const draft = {
            status: true,
            payload: urlBlobArr,
          };
          return {
            ...prev,
            label: draft,
          };
        });
        setLabelLoading(false);
      } catch (error) {
        console.log(error.message);
        setLabelLoading(false);
      }
    } else {
      setLabelLoading(true);
      try {
        const response = await axios({
          url: `https://api.labelary.com/v1/printers/8dpmm/labels/${width}x${height}/0/`,
          method: "post",
          headers: {
            // prettier-ignore
            "Accept": "image/png",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: JSON.stringify(zplString),
          responseType: "blob",
        });

        const responseBlob = response.data;

        const imgUrl = URL.createObjectURL(responseBlob);
        setLabelModal((prev) => {
          const draft = {
            status: true,
            payload: Array(imgUrl),
          };
          return {
            ...prev,
            label: draft,
          };
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLabelLoading(false);
      }
    }
  };

  const requestLabelZpl = (courier, zpl) => {
    if (courier === "dhl") {
      requestZpl(zpl, 4, 8);
    } else if (courier === "rincos") {
      requestZpl(zpl, 6, 12);
    } else if (courier === "fedex") {
      requestZpl(zpl, 4, 7);
    }
  };

  const previewInvoice = (base64) => {
    setLabelModal((prev) => {
      const draft = {
        status: true,
        payload: base64,
      };
      return {
        ...prev,
        invoice: draft,
      };
    });
  };

  const printLabel = async (id, label) => {
    // if (process.env.REACT_APP_DEVELOPMENT) {
    //   setLabelLoading(true);
    //   try {
    //     const payload = {
    //       id,
    //       documentType: [label],
    //     };
    //     const resPrint = await shipmentsApi.printShipmentDocuments(payload);
    //     alert("Printing");
    //     console.log(resPrint);
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     setLabelLoading(false);
    //   }
    // }
    setLabelLoading(true);
    try {
      const payload = {
        id,
        documentType: [label],
      };
      const resPrint = await shipmentsApi.printShipmentDocuments(payload);
      alert("Printing");
      console.log(resPrint);
    } catch (error) {
      console.log(error);
    } finally {
      setLabelLoading(false);
    }
  };

  const pageSizeOnChange = debounce((page, pageSize) => {
    const newParam = produce(params, (draft) => {
      draft.page = page;
      draft.pageSize = pageSize;
    });
    setParams(newParam);
    getShipments(newParam);
  }, 500);

  const columns = [
    {
      key: 1,
      title: "ID",
      dataIndex: "id",
      render: (text) => <>{text}</>,
    },
    {
      key: 2,
      title: "Order Number",
      dataIndex: "sourceId",
      render: (value, record) => {
        return record.orderId.match("#") ? (
          <p style={{ margin: 0 }}>{value}</p>
        ) : (
          <Link to={Path.ORDER_DETAIL.pathParam(record.orderId)}>{value}</Link>
        );
      },
    },
    {
      key: 3,
      title: "Courier",
      dataIndex: "courier",
      render: (text) => <strong>{text.toUpperCase()}</strong>,
    },
    {
      key: "airwayBillNumber",
      title: "Airway Bill Number",
      dataIndex: "airwayBillNumber",
    },
    {
      key: 4,
      title: "PLT/ETD Status",
      dataIndex: "pltStatus",
      render: (text) => <>{text ? "Yes" : "No"}</>,
    },
    {
      key: 5,
      title: "Orders",
      dataIndex: "payload",
      render: (text) => (
        <>
          {text.exportDeclaration.length ? text.exportDeclaration.length : "0"}
        </>
      ),
    },
    {
      key: 6,
      title: "Email",
      dataIndex: "payload",
      render: (text) => <>{text.consignee.personEmail}</>,
    },
    {
      key: 7,
      title: "Country Code",
      dataIndex: "payload",
      render: (text) => <>{text.consignee.countryCode}</>,
    },
    {
      key: 8,
      title: "Weight",
      dataIndex: "payload",
      render: (value) => {
        const count = value.exportDeclaration
          .map((object) => {
            return Number(object.weight);
          })
          .reduce((prev, curr) => prev + curr);
        const weightUnit = value.invoiceWeightUnit;
        return (
          <>
            {count} {weightUnit.toUpperCase()}
          </>
        );
      },
    },
    {
      key: 9,
      title: "Fees",
      dataIndex: "shippingFee",
    },
    {
      key: 10,
      title: "Printed Count",
      dataIndex: "printedCount",
    },
    {
      key: 11,
      title: "Shipment",
      dataIndex: ["labelBase64", "labelZpl"],
      render: (_, record) => {
        return (
          <>
            {labelLoading ? (
              <Space>
                <Spin />
              </Space>
            ) : (
              <Space>
                <Button
                  onClick={() =>
                    requestLabelZpl(record.courier, record.labelZpl)
                  }
                >
                  Preview
                </Button>
                <Button
                  type="primary"
                  onClick={() => printLabel(record.id, "label_base64")}
                >
                  Print
                </Button>
              </Space>
            )}
          </>
        );
      },
    },
    {
      key: 12,
      title: "Invoice",
      dataIndex: "invoiceBase64",
      render: (value, record) => {
        return (
          <>
            {labelLoading ? (
              <Space>
                <Spin />
              </Space>
            ) : (
              <Space>
                <Button onClick={() => previewInvoice(value)}>Preview</Button>
                <Button
                  type="primary"
                  onClick={() => printLabel(record.id, "invoice_base64")}
                >
                  Print
                </Button>
              </Space>
            )}
          </>
        );
      },
    },
  ];
  console.log("DATA SHIPMENT", data.shipment);

  return (
    <>
      <Table
        columns={columns}
        dataSource={data.shipment}
        pagination={{
          pageSizeOptions: ["10", "20", "30"],
          showSizeChanger: true,
          defaultPageSize: 10,
          onChange: pageSizeOnChange,
          current: params.page,
          pageSize: params.pageSize,
          total: data.totalCount,
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </>
  );
};

export default ShipmentTable;

import { Card, Col, Form, message, Row, Select } from "antd";
import shipmentsApi from "apis/shipmentsApi";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const { Option } = Select;

function PrinterDetails() {
  const [loading, setLoading] = useState(false);
  const [printers, setPrinters] = useState([]);

  useEffect(() => {
    const getPrinters = async () => {
      try {
        setLoading(true);
        const res = await shipmentsApi.getPrinters();
        if (Array.isArray(res)) {
          setPrinters(res);
        }
      } catch (error) {
        message.error('Load printer fail');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getPrinters();
  }, []);

  return (
    <Card title="Printer details">
      <Row gutter={[10]}>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            name="labelPrinterId"
            label="Label Printer"
            rules={[{ required: true, message: "Label Printer is required" }]}
          >
            <Select loading={loading}>
              {printers.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            name="invoicePrinterId"
            label="Invoice Printer"
            rules={[{ required: true, message: "Invoice Printer is required" }]}
          >
            <Select loading={loading}>
              {printers.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
}
export default PrinterDetails;

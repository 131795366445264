import { Select as BaseSelect } from 'antd'
import { get } from 'lodash'
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StyledSelect } from './Select.styles'

const { Option } = BaseSelect

const Select = forwardRef(({
  options,
  onChange,
  value,
  optionBinding,
  isTranslate,
  fullWidth,
  ...props
}, ref) => {
  const { t } = useTranslation()

  const handleChange = (value) => {
    if (onChange) { onChange({ target: { value, name: props.name } }) }
  }

  return (
    <StyledSelect
      fullwidth={`${fullWidth}`}
      size="large"
      {...props}
      onChange={ref ? onChange : handleChange}
      value={value}
      ref={ref}
    >
      {options?.map((option) => {
        const value = get(option, optionBinding.value)
        const name = get(option, optionBinding.name)
        return (
          <Option key={value} value={value}>
            {isTranslate ? t(name) : name}
          </Option>
        )
      })}
    </StyledSelect>
  )
})

Select.propTypes = {
  optionBinding: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
  }),
  onChange: PropTypes.func,
  isTranslate: PropTypes.bool,
  fullWidth: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  name: PropTypes.string,
}

Select.defaultProps = {
  optionBinding: { value: 'value', name: 'name' },
  onChange: null,
  fullWidth: true,
  isTranslate: true,
  options: [],
  value: null,
}

export default Select

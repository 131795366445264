import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    sideBarMobile: false,
    currentLanguage: "en",
    countries: [],
    currency: "USD",
  },
  reducers: {
    toggedSideBar(state) {
      state.sideBarMobile = !state.sideBarMobile;
    },
    changeLanguage(state, action) {
      state.currentLanguage = action.payload.language;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },
    setCurrency(state, action) {
      state.currency = action.payload.currency;
    },
  },
});

const { actions, reducer } = commonSlice;

export const { setCountries, toggedSideBar, changeLanguage, setCurrency } =
  actions;

export default reducer;

export const ORDER_SORT = {
  OrderNumberDesc: { name: 'Order Number (Desc)', value: 'id_desc' },
  OrderNumberAsc: { name: 'Order Number (Asc)', value: 'id_asc' },
  OrderNumberSource: { name: 'Order Number (Source/Channel)', value: `source_asc&id_asc` },
  OrderDateDesc: { name: 'Order Date (Desc)', value: 'createdAt_desc' },
  OrderDateAsc: { name: 'Order Date (Asc)', value: 'createdAt_asc' },
}

export const ORDER_STATUS = {
  Unfulfilled: { name: 'Unfulfilled', value: '1' },
  PartiallyFulfilled: { name: 'Partially Fulfilled', value: '2' },
  Fulfilled: { name: 'Fulfilled', value: '3' },
  Refunded: { name: 'Refunded', value: '4' },
  Cancelled: { name: 'Cancelled', value: '5' },
  Closed: { name: 'Closed', value: '6' },
  ReadyForShipping: { name: 'Ready For Shipping', value: '7' },
}

export const FINANCIAL_STATUS = {
  Authorized: { name: 'Authorized', value: '1' },
  PartiallyRefunded: { name: 'Partially Refunded', value: '2' },
  Pending: { name: 'Pending', value: '3' },
  Refunded: { name: 'Refunded', value: '4' },
  Paid: { name: 'Paid', value: '5' },
  Voided: { name: 'Voided', value: '6' },
  PartiallyPaid: { name: 'Partially Paid', value: '7' },
}

export const ORDER_FILTER_STATUS = {
  'pending purchase': { name: 'Pending purchase', value: 'pending purchase' },
  allocated: { name: 'Allocated', value: 'allocated' },
  confirmed: { name: 'Confirmed', value: 'confirmed' },
  'partially allocated': { name: 'Partially allocated', value: 'partially allocated' },
  'ready to pack': { name: 'Ready to pack', value: 'ready to pack' },
  received: { name: 'Received', value: 'received' },
  // cancelled: { name: 'Cancelled', value: 'cancelled' },
}
export const ORDER_FILTER_FINANCIAL_STATUS = {
  paid: { name: 'Paid', value: 'paid' },
  pending: { name: 'Pending', value: 'pending' },
  voided: { name: 'Voided', value: 'voided' },
}

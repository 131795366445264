import { Button, Card, Divider, message, Modal, Space } from 'antd'
import Box from 'components/box/Box'
import Container from 'components/container/Container'
import TitleHeader from 'components/title-header'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Path from '../../commons/breadcrumbs'
import PrivacyPolicyTemplate from './templates/PrivacyPolicyTemplate.md'
import RefundPolicyTemplate from './templates/RefundPolicyTemplate.md'
import TermsOfServiceTemplate from './templates/TermsOfServiceTemplate.md'

const Policies = () => {
  const { t } = useTranslation()
  const [value, setValue] = useState({
    refundPolicy: '',
    privacyPolicy: '',
    termsOfService: '',
    shippingPolicy: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isModal, setIsModal] = useState({
    modalPolicy: false,
    modalReplaceContent: { status: false, fromButton: '' },
    clearWarning: { status: false },
  })

  const fetchCurrentPolicyTemplate = (currentPolicyTemplate, policyName) => {
    fetch(currentPolicyTemplate)
      .then((res) => {
        return res.text()
      })
      .then((markdown) =>
        setValue((prev) => {
          return { ...prev, [policyName]: markdown }
        })
      )

    message.success('Template created', 3)

    // close the modal
    setIsModal((prev) => {
      return {
        ...prev,
        modalReplaceContent: { ...isModal.modalReplaceContent, status: false },
      }
    })
  }

  const yesReplaceHandler = (policyName) => {
    if (policyName === 'refundPolicy') {
      return fetchCurrentPolicyTemplate(RefundPolicyTemplate, policyName)
    } else if (policyName === 'privacyPolicy') {
      return fetchCurrentPolicyTemplate(PrivacyPolicyTemplate, policyName)
    } else if (policyName === 'termsOfService') {
      return fetchCurrentPolicyTemplate(TermsOfServiceTemplate, policyName)
    }
  }

  const cancelReplaceHandler = () => {
    // what do you want when people click cancel replace?
    setIsModal((prev) => {
      return {
        ...prev,
        modalReplaceContent: { ...isModal.modalReplaceContent, status: false },
      }
    })
  }

  // onClick Button from Create From Template
  const createFromTemplate = (policyName) => {
    // check wherether the box already got content
    if (!!value[policyName]) {
      // create warning before replace current content
      setIsModal((prev) => {
        return {
          ...prev,
          modalReplaceContent: { fromButton: policyName, status: true },
        }
      })
    } else {
      // no need permission, no context
      yesReplaceHandler(policyName)
    }
  }

  const onChangeText = (value, policyName) => {
    setValue((prev) => {
      return { ...prev, [policyName]: value }
    })
  }

  const savePolicies = () => {
    // savePolicies Here
    setIsLoading(true)
    try {
      // api push endpoint here
      message.success('Succesfully update policies', 3)
      setIsLoading(false)
    } catch (error) {
      message.error(error.message, 3)
      setIsLoading(false)
    }
  }

  const discardHandler = () => {
    setIsModal((prev) => {
      return { ...prev, modalPolicy: false }
    })
  }

  const handleOk = () => {
    setValue((prev) => {
      return {
        refundPolicy: '',
        privacyPolicy: '',
        termsOfService: '',
        shippingPolicy: '',
      }
    })
    message.success('Discarded policies', 3)
    setIsModal((prev) => {
      return { ...prev, modalPolicy: false }
    })
  }

  const handleCancel = () => {
    message.info('Cancel discard', 3)
    setIsModal((prev) => {
      return { ...prev, modalPolicy: false }
    })
  }

  const CreateAndClearBtn = ({ policyName }) => {
    return (
      <Space>
        <Button onClick={() => createFromTemplate(policyName)}>
          Create From Template
        </Button>
        <Button
          danger
          onClick={() => {
            setValue((prev) => {
              return { ...prev, [policyName]: '' }
            })
            message.success('Removed policy', 3)
          }}
        >
          Clear
        </Button>
      </Space>
    )
  }

  useEffect(() => {
    // fetch current policies from DB
    // update value state
  }, [])

  return (
    <>
      <Modal
        title={'Discard All Policies'}
        visible={isModal.modalPolicy}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Yes, I'm Sure"}
        okType='danger'
      >
        Are you sure want to discard all the written policies?
      </Modal>

      <Modal
        title={'Replace Policies Content'}
        visible={isModal.modalReplaceContent.status}
        onOk={() => yesReplaceHandler(isModal.modalReplaceContent.fromButton)}
        onCancel={cancelReplaceHandler}
        okText={"Yes, I'm Sure"}
        okType='danger'
      >
        Are you sure want to replace with the template content? We suggest you
        keep it other place before click YES.
      </Modal>

      <TitleHeader
        listOfBreadcrumb={[
          { name: 'Home', path: '/' },
          { name: Path.SETTINGS.name, path: Path.SETTINGS.pathname },
          { name: Path.POLICIES.name },
        ]}
        title={t(Path.POLICIES.name)}
      />
      <Box>
        <Container>
          <Card title={t('Refund Policy')} style={{ marginBottom: '24px' }}>
            <ReactQuill
              theme='snow'
              onChange={(value) => onChangeText(value, 'refundPolicy')}
              style={{ marginBottom: '24px' }}
              value={value.refundPolicy}
            />
            <CreateAndClearBtn policyName='refundPolicy' />
          </Card>
          <Card title={t('Privacy Policy')} style={{ marginBottom: '24px' }}>
            <ReactQuill
              theme='snow'
              onChange={(value) => onChangeText(value, 'privacyPolicy')}
              style={{ marginBottom: '24px' }}
              value={value.privacyPolicy}
            />
            <CreateAndClearBtn policyName='privacyPolicy' />
          </Card>
          <Card title={t('Terms Of Service')} style={{ marginBottom: '24px' }}>
            <ReactQuill
              theme='snow'
              onChange={(value) => onChangeText(value, 'termsOfService')}
              style={{ marginBottom: '24px' }}
              value={value.termsOfService}
            />
            <CreateAndClearBtn policyName='termsOfService' />
          </Card>
          <Card title={t('Shipping Policy')} style={{ marginBottom: '24px' }}>
            <ReactQuill
              theme='snow'
              onChange={(value) => onChangeText(value, 'shippingPolicy')}
              style={{ marginBottom: '24px' }}
              value={value.shippingPolicy}
            />
          </Card>
          <Divider />
          <Space align='end'>
            <Button type='primary' onClick={savePolicies} disabled={isLoading}>
              Save Policies
            </Button>
            <Button type='danger' onClick={discardHandler} disabled={isLoading}>
              Discard
            </Button>
          </Space>
        </Container>
      </Box>
    </>
  )
}

export default Policies

import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row, Typography, message } from "antd";
import Container from "components/container/Container";
import Breadcrumb from "components/breadcrumb/Breadcrumb";
import Path from "../../commons/breadcrumbs";
import { useTranslation } from "react-i18next";
import ShipmentRateDetail from "pages/create-shipment/ShipmentRateDetail";
import ShipmentDetail from "./ShipmentDetail";
import ConsigneeDetail from "./ConsingeeDetail";
import PackagingInfo from "./PackagingInfo";
import { useSelector } from "react-redux";
import queryString from "query-string";
import ExportDeclare from "./ExportDeclare";
import ordersApi from "apis/ordersApi";
import moment from "moment";
import { debounce, lowerCase } from "lodash";
import RightColumn from "./RightColumn";
import shipmentsApi from "apis/shipmentsApi";
import {
  generateIdWithChar,
  getCountryCode,
  gramToKg,
  twoDecimal,
} from "utils/funcs";
import ShipperDetail from "./ShipperDetail";
import produce from "immer";
import { TitleBox } from "components/title-header/index.style";

const { Title } = Typography;

const CreateShipment = ({ history }) => {
  const { t } = useTranslation();
  const { currency } = useSelector((state) => state.common);
  const { orderId } = queryString.parse(history.location.search);
  const [init, setInit] = useState(true);

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [fromOrder, setFromOrder] = useState();
  const [rates, setRates] = useState([]);
  const [ratesError, setRatesError] = useState([]);
  const [createShipmentError, setCreateShipmentError] = useState([]);
  const [summary, setSummary] = useState();
  const [shippingAccounts, setShippingAccounts] = useState([]);
  const [activeCourier, setActiveCourier] = useState({});
  const [shipper, setShipper] = useState([]);
  const [activeShipper, setActiveShipper] = useState({});
  const [generatedOrderId, setGeneratedOrderId] = useState("");

  console.log("FROM ORDER", fromOrder);

  const ifNullThenUndefined = (input) => {
    if (input === null) {
      return undefined;
    } else {
      return input;
    }
  };
  const getStateProvinceCode = (provinceName, countryCode) => {
    const ca = {
      Alberta: "AB",
      "British Columbia": "BC",
      Manitoba: "MB",
      "New Brunswick": "NB",
      Newfoundland: "NL",
      "Northwest Territories": "NT",
      "Nova Scotia": "NS",
      Nunavut: "NU",
      Ontario: "ON",
      "Prince Edward Island": "PE",
      Quebec: "QC",
      Saskatchewan: "SK",
      Yukon: "YT",
    };
    const us = {
      Alabama: "AL",
      Alaska: "AK",
      Arizona: "AZ",
      Arkansas: "AR",
      California: "CA",
      Colorado: "CO",
      Connecticut: "CT",
      Delaware: "DE",
      "District of Columbia": "DC",
      Florida: "FL",
      Georgia: "GA",
      Hawaii: "HI",
      Idaho: "ID",
      Illinois: "IL",
      Indiana: "IN",
      Iowa: "IA",
      Kansas: "KS",
      Kentucky: "KY",
      Louisiana: "LA",
      Maine: "ME",
      Maryland: "MD",
      Massachusetts: "MA",
      Michigan: "MI",
      Minnesota: "MN",
      Mississippi: "MS",
      Missouri: "MO",
      Montana: "MT",
      Nebraska: "NE",
      Nevada: "NV",
      "New Hampshire": "NH",
      "New Jersey": "NJ",
      "New Mexico": "NM",
      "New York": "NY",
      "North Carolina": "NC",
      "North Dakota": "ND",
      Ohio: "OH",
      Oklahoma: "OK",
      Oregon: "OR",
      Pennsylvania: "PA",
      "Rhode Island": "RI",
      "South Carolina": "SC",
      "South Dakota": "SD",
      Tennessee: "TN",
      Texas: "TX",
      Utah: "UT",
      Vermont: "VT",
      Virginia: "VA",
      "Washington State": "WA",
      "West Virginia": "WV",
      Wisconsin: "WI",
      Wyoming: "WY",
      "Puerto Rico": "PR",
    };
    const mx = {
      Aguascalientes: "AG",
      "Baja California": "BC",
      "Baja California Sur": "BS",
      Campeche: "CM",
      Chiapas: "CS",
      Chihuahua: "CH",
      "Ciudad de México": "DF",
      Coahuila: "CO",
      Colima: "CL",
      Durango: "DG",
      "Estado de México": "EM",
      Guanajuato: "GT",
      Guerrero: "GR",
      Hidalgo: "HG",
      Jalisco: "JA",
      Michoacán: "MI",
      Morelos: "MO",
      Nayarit: "NA",
      "Nuevo León": "NL",
      Oaxaca: "OA",
      Puebla: "PU",
      Querétaro: "QE",
      "Quintana Roo": "QR",
      "San Luis Potosí": "SL",
      Sinaloa: "SI",
      Sonora: "SO",
      Tabasco: "TB",
      Tamaulipas: "TM",
      Tlaxcala: "TL",
      Veracruz: "VE",
      Yucatán: "YU",
      Zacatecas: "ZA",
    };
    const ae = {
      "Abu Dhabi": "AB",
      Ajman: "AJ",
      Dubai: "DU",
      Fujairah: "FU",
      "Ras al-Khaimah": "RA",
      Sharjah: "SH",
      "Umm al-Quwain": "UM",
    };
    const india = {
      "Andaman & Nicobar (U.T)": "AN",
      "Andhra Pradesh": "AP",
      "Arunachal Pradesh": "AR",
      Assam: "AS",
      Bihar: "BR",
      Chattisgarh: "CG",
      "Chandigarh (U.T.)": "CH",
      "Daman & Diu (U.T.)": "DD",
      "Delhi (U.T.)": "DL",
      "Dadra and Nagar Haveli (U.T.)": "DN",
      Goa: "GA",
      Gujarat: "GJ",
      Haryana: "HR",
      "Himachal Pradesh": "HP",
      "Jammu & Kashmir": "JK",
      Jharkhand: "JH",
      Karnataka: "KA",
      Kerala: "KL",
      "Lakshadweep (U.T)": "LD",
      "Madhya Pradesh": "MP",
      Maharashtra: "MH",
      Manipur: "MN",
      Meghalaya: "ML",
      Mizoram: "MZ",
      Nagaland: "NL",
      Orissa: "OR",
      Punjab: "PB",
      "Puducherry (U.T.)": "PY",
      Rajasthan: "RJ",
      Sikkim: "SK",
      "Tamil Nadu": "TN",
      Tripura: "TR",
      Uttaranchal: "UA",
      "Uttar Pradesh": "UP",
      "West Bengal": "WB",
    };

    if (countryCode === "IN") {
      return india[provinceName];
    } else if (countryCode === "CA") {
      return ca[provinceName];
    } else if (countryCode === "MX") {
      return mx[provinceName];
    } else if (countryCode === "AE") {
      return ae[provinceName];
    } else if (countryCode === "US") {
      return us[provinceName];
    } else {
      return countryCode;
    }
  };

  const detectBookCategory = (word) => {
    const bookCat = [
      "manhwa",
      "book",
      "books",
      "comic",
      "comics",
      "magazine",
      "magazines",
      "manga",
    ];
    const splitterWord = word?.toLowerCase().split(" ");
    return bookCat.some((cat) => splitterWord?.some((word) => word === cat));
  };

  const getInsuranceParams = (formValue) => {
    if (
      activeCourier?.apiType !== "dhl" ||
      !formValue.insuranceRequested ||
      !formValue.insuranceCost
    ) {
      return {
        insuranceCost: "",
        insuranceRequested: false,
        insuranceCurrency: "",
      };
    }
    return {
      insuranceCost: formValue.insuranceCost,
      insuranceRequested: true,
      insuranceCurrency: currency,
    };
  };

  // in case they want 0.02 back
  // const setMinWeight = (weight, courier) => {
  //   if (courier === "dhl" && weight < 0.02) {
  //     return 0.02;
  //   } else if (Number(weight) === 0) {
  //     return "0.00";
  //   }
  // };

  const onFinish = () => {
    if (activeCourier.hasOwnProperty("id")) {
      form
        .validateFields()
        .then(async (res) => {
          setIsLoading(true);
          const value = res;
          const shippingAccountId = activeCourier?.id;
          const newCS = {
            userId: "123", // hardcoded, please change them once user done
            orderId: orderId !== undefined ? orderId : generatedOrderId,
            orderNumber: value.orderNumber,
            shippingAccountId,
            shipperDetailsId: activeShipper.id,
            sampleResponse: false,
            invoiceNumber: String(value?.invoice),
            invoiceDate: moment(value?.invoiceDate).utc().endOf("day").format(),
            shipmentDate: moment(value?.shipmentDate)
              .utc()
              .endOf("day")
              .format(),
            declaredValue: String(value?.totalPackageWeight),
            email: value?.personEmail,
            notificationMessage: value?.notificationMessage,
            notification:
              value?.additionalCourierOptions?.includes("notification"),
            shopifyNotification: value?.additionalCourierOptions?.includes(
              "shopifyNotification"
            ),
            contents: value?.contents.substring(0, 69),
            pltShipment:
              value?.additionalCourierOptions?.includes("pltShipment"),
            documentsOnly:
              value?.additionalCourierOptions?.includes("documentsOnly"),
            signatureRequired:
              value?.additionalCourierOptions?.includes("signatureRequired"),
            signatureType: "DIRECT", // <-- hardcoded
            useOwnInvoice:
              value?.additionalCourierOptions?.includes("useOwnInvoice"),
            fedexPackagingType: value?.fedexPackagingType, // <-- ada dalam packagingInformation
            fedexServiceType: value?.fedexServiceType, // <-- ada dalam packagingInformation
            fedexDutiesPaidAccount: value?.fedexDutiesPaidAccount,
            shipmentPurpose: value?.shipmentPurpose, // <-- please refer documentation from Edward and Kaamesh
            ...getInsuranceParams(value),
            shippingCost: "", // <-- hardcoded
            packingCost: String(value?.totalPackageValue),
            handlingCost: "", // <-- hardcoded
            otherCost: "", // <-- hardcoded
            trackingNumber: "80329091290", // <-- hardcoded
            invoiceRemark: value?.invoiceRemark,
            invoiceCurrency: currency,
            invoiceWeightUnit: "kg", // <-- hardcoded
            invoiceLetterhead: {
              content: "", // <-- hardcoded
              fedexImageId: "IMAGE_1", // <-- hardcoded
            },
            invoiceSignature: {
              content: "", // <-- hardcoded
              fedexImageId: "IMAGE_2", // <-- hardcoded
            },
            invoiceData: {
              documentType: "COMMERCIAL_INVOICE", // <-- hardcoded
              fileName: "Invoice.pdf", // <-- hardcoded
              content:
                "JVBERi0xLjQKJeLjz9MKNiAwIG9iago8PC9GaWx0ZXIvRmxhdGVEZWNvZGUvTGVuZ3RoIDUxPj5zdHJl", // <-- hardcoded
            },
            rincosServiceCode: "", // <-- hardcoded
            rincosServiceName: "", // <-- hardcoded
            labelType: "ZPLII", // <-- hardcoded
            consignee: {
              companyName: value?.companyName,
              personName: value?.personName,
              personPhone: value?.personPhone,
              personEmail: value?.personEmail,
              address1: value?.address1,
              address2: value?.address2,
              stateOrProvinceName: value?.stateOrProvinceName,
              city: value?.city,
              division: value?.division,
              provinceCode: value?.provinceCode,
              postal: value?.postal,
              countryCode: value?.countryCode,
            },
            exportDeclaration: value?.exportDeclarationItems?.map((item) => {
              return {
                id: generateIdWithChar(),
                quantity: Number(item.quantity),
                quantityUnit: "PCS",
                description: item.description.substring(0, 69),
                value: String(Number(item.unitPrice).toFixed(2)),
                totalValue: String(Number(item.totalValue).toFixed(2)),
                weight: String(Number(item.weight).toFixed(2)),
                grossWeight: String(Number(item.grossWeight).toFixed(2)),
                HSCode: item.hsCode,
                sku: item.sku,
                originCountry: item.originCountry,
                category: item.category,
                productVariantId: item.productVariantId
                  ? item.productVariantId
                  : "",
                updateVariant:
                  item.updateVariant?.includes("updateVariant") || false,
              };
            }),
            pieces: value?.pieces?.map((item) => {
              return {
                id: generateIdWithChar(),
                height: String(item.height),
                width: String(item.width),
                depth: String(item.depth),
                value: String(item.value),
                weight: String(item.weight),
                reference: item.reference,
              };
            }),
            shipper: {
              name: value.shipperName,
              phonenumber: value.shipperPhoneNumber,
              utcOffset: shipper[value.shipper - 1]?.utcOffset,
              email: value.shipperEmail,
              address1: value.shipperAddress1,
              address2: value.shipperAddress2,
              city: value.shipperCity,
              postal: value.shipperPostal,
              provinceCode: value.shipperProvinceCode,
              countryCode: value.shipperCountryCode,
              country: value.shipperCountryCode,
            },
          };

          const chooseCourier = activeCourier?.apiType;

          console.log(
            `${chooseCourier.toUpperCase()} CREATE SHIPMENT JSON: `,
            JSON.stringify(newCS)
          );

          const pushResponse = (response) => {
            const payload = {
              trackingNumber: response?.trackingNumber,
              orderId: orderId !== undefined ? orderId : generatedOrderId,
              shopifyNotification: value?.additionalCourierOptions?.includes(
                "shopifyNotification"
              ),
              etd: value?.additionalCourierOptions?.includes("pltShipment"),
            };
            return history.push({
              pathname: "/created-shipment",
              search: `?${queryString.stringify(payload)}`,
              state: response,
            });
          };

          if (chooseCourier === "fedex") {
            try {
              const resFedex = await shipmentsApi.createShipmentFedex(newCS);
              if (resFedex.response === undefined) {
                message.success("Succesfull", 3);
                pushResponse(resFedex);
                setIsLoading(false);
              } else if (resFedex.response && resFedex.response?.data) {
                setCreateShipmentError((prev) => {
                  return [...prev, resFedex?.response.data];
                });
                setIsLoading(false);
              }
            } catch (error) {
              console.log("RESPON FROM CATCH", error);
              setIsLoading(false);
            }
          } else if (chooseCourier === "dhl") {
            try {
              const resDhl = await shipmentsApi.createShipmentDhl(newCS);
              if (resDhl.response === undefined) {
                message.success("Succesfull", 3);
                pushResponse(resDhl);
                setIsLoading(false);
              } else if (resDhl.response && resDhl.response?.data) {
                setCreateShipmentError((prev) => {
                  return [...prev, resDhl?.response.data];
                });
                setIsLoading(false);
              }
            } catch (error) {
              console.log("RESPON FROM CATCH", error);
              setIsLoading(false);
            }
          } else if (chooseCourier === "rincos") {
            try {
              const resRincos = await shipmentsApi.createShipmentRincos(newCS);
              if (resRincos.response === undefined) {
                message.success("Succesfull", 3);
                pushResponse(resRincos);
                setIsLoading(false);
              } else if (resRincos.response && resRincos.response?.data) {
                setCreateShipmentError((prev) => {
                  return [...prev, resRincos?.response.data];
                });
                setIsLoading(false);
              }
            } catch (error) {
              console.log("RESPON FROM CATCH", error);
              setIsLoading(false);
            }
          }
        })
        .catch((error) => {
          alert(
            "Please fill in all the required information before Check Rates"
          );
        });
    } else {
      alert("Please choose your courier first");
    }
  };

  const companyNameChecker = (companyName, courier) => {
    if (companyName?.length <= 1 && !!courier) {
      if (courier.apiType === "dhl") {
        return "-";
      } else if (courier.apiType === "fedex") {
        return " ";
      } else {
        return "-";
      }
    }

    return companyName;
  };

  const debounceOnChange = debounce((event) => {
    setSummary(() => form.getFieldsValue());
  }, 1000);

  const recheckDataHandler = debounce(() => {
    const piecesArr = form.getFieldValue("pieces");
    if (piecesArr === undefined || piecesArr.length === 0) {
      return form.setFieldsValue({
        totalPackageWeight: 0,
        totalPackageGrossWeight: 0,
        totalPackageValue: 0,
        totalPackagePiece: 0,
      });
    } else if (piecesArr.length === 1) {
      form.setFieldsValue({
        totalPackageWeight:
          Number(piecesArr[0].weight) === 0 ? "0.00" : piecesArr[0].weight,
        totalPackageGrossWeight:
          Number(piecesArr[0].grossWeight) === 0
            ? "0.00"
            : piecesArr[0].grossWeight,
        totalPackageValue: !piecesArr[0].value ? 0 : piecesArr[0].value,
        totalPackagePiece: piecesArr.length,
      });
    } else if (piecesArr.length > 1) {
      // total weight
      const totalPackageWeight = piecesArr
        .map((item) => item.weight)
        .reduce((prev, curr) => Number(prev) + Number(curr));

      // total gross weight
      const totalPackageGrossWeight = piecesArr
        .map((item) => item.grossWeight)
        .reduce((prev, curr) => Number(prev) + Number(curr));

      const totalPackageValue = piecesArr
        .map((item) => item.value)
        .reduce((prev, curr) => Number(prev) + Number(curr));

      form.setFieldsValue({
        totalPackageWeight: totalPackageWeight || "0.00",
        totalPackageGrossWeight: totalPackageGrossWeight || "0.00",
        totalPackageValue: totalPackageValue || 0,
        totalPackagePiece: piecesArr.length,
      });
    }
  }, 500);

  useEffect(() => {
    const go = generateIdWithChar();
    setGeneratedOrderId(go);
    const params = {
      page: 1,
      pageSize: 10,
    };

    shipmentsApi.getListShippingAccounts().then((res) => {
      if (res === undefined) {
        return alert("Undefined response from /list-shipping-accounts");
      }
      const { shipping_account } = res;
      setShippingAccounts(shipping_account);
    });

    // get list of shipper
    shipmentsApi
      .getListShipper(params)
      .then(({ shipper_details, errors }) => {
        setShipper(shipper_details);
        const selectedShipper = shipper_details[0];
        if (shipper) {
          form.setFieldsValue({
            shipper: selectedShipper?.id,
            shipperAddress1: selectedShipper?.address1,
            shipperAddress2: selectedShipper?.address2,
            shipperCity: selectedShipper?.city,
            shipperCompanyName: selectedShipper?.companyName,
            shipperCountryCode: selectedShipper?.countryCode,
            shipperEmail: selectedShipper?.email,
            shipperName: selectedShipper?.name,
            shipperPhoneNumber: selectedShipper?.phonenumber,
            shipperPostal: selectedShipper?.postal,
            shipperProvinceCode: selectedShipper?.provinceCode,
          });
        }
        setActiveShipper(selectedShipper);
      })
      .catch((error) => {
        console.log("Error from active shipper", error.response.data);
      });

    // fetch data from order
    if (orderId) {
      setIsLoading(true);
      ordersApi
        .getOrderById({ id: orderId, params: { listCombinedOrders: false } })
        .then((res) => {
          if (res === undefined) {
            return alert("Order is undefined");
          }
          // change all gram to kg included weight
          const lineItems = res?.order?.lineItems?.map((item) => {
            const weightUnit = item.weightUnit.toLowerCase();
            const validGram =
              weightUnit === "gram" ||
              weightUnit === "gm" ||
              weightUnit === "g" ||
              weightUnit === "grams";

            if (validGram) {
              const weight = gramToKg(Number(item.weight));
              return { ...item, weight, weightUnit: "kg" };
            }
          });
          const newRes = produce(res?.order, (draft) => {
            draft.lineItems = lineItems;
          });
          setIsLoading(false);
          message.success(`Succesfully fetch Order ${orderId}`, 5);
          return setFromOrder(newRes);
        })
        .catch((error) => {
          setIsLoading(false);
          message.error(error);
        });
    } else {
      form.setFieldsValue({
        orderId: go,
        orderNumber: go,
        invoice: go,
        additionalCourierOptions: ["shopifyNotification", "pltShipment"],
        pieces: [
          {
            quantity: 1,
            depth: "10",
            grossWeight: 1,
            height: "10",
            packagingType: "YOUR_PACKAGING",
            reference: "",
            value: 1,
            weight: 1,
            width: "10",
          },
        ],
        exportDeclarationItems: [
          {
            originCountry: "KR",
            quantityUnit: "PCS",
            description: "",
            sku: "",
            commCode: null,
            hsCode: null,
            weight: "0.00",
            unitPrice: 0,
            quantity: 0,
            category: "Other",
            grossWeight: "0.00",
            totalValue: 0,
          },
        ],
      });
    }
    setInit(false);
  }, []);

  useEffect(() => {
    if (fromOrder) {
      const additionalCourierOptions = ["shopifyNotification", "pltShipment"];

      const firstName =
        ifNullThenUndefined(fromOrder.addressFirstName) ||
        ifNullThenUndefined(fromOrder.billingFirstName);
      const lastName =
        ifNullThenUndefined(fromOrder.addressLastName) ||
        ifNullThenUndefined(fromOrder.billingLastName);
      const fullName = !lastName ? firstName : `${firstName} ${lastName}`;

      const nonShipmentItems = [
        "Korea Shopping Agent",
        "Courier ",
        "Signature ",
        "Domestic Shipping",
        "Bunjang",
        "Top-up",
        "Top up",
        "Topup",
        "Keyword Search",
        "Change Of",
      ].map((item) => item.toLowerCase());

      const exportDeclarationItems = fromOrder?.lineItems?.filter(
        (item) =>
          !nonShipmentItems.some((nonShipTitle) =>
            lowerCase(item.productTitle).startsWith(nonShipTitle)
          )
      );

      const totalInArray = (unit) => {
        return twoDecimal(
          exportDeclarationItems
            ?.map((item) => item[unit])
            .reduce((prev, current) => prev + current)
        );
      };

      const exportItemDescription = (
        productTitle,
        productVariantTitle,
        customProductName
      ) => {
        const description = productTitle
          ? `${productTitle} ${productVariantTitle}`
          : customProductName;

        if (detectBookCategory(description)) {
          return `BOOK ${description}`;
        } else {
          return description;
        }
      };

      const decriptionAndContent = exportDeclarationItems.map((item) =>
        exportItemDescription(
          item.productTitle,
          item.productVariantTitle,
          item.customProductName
        )
      );

      form.setFieldsValue({
        orderNumber: fromOrder.orderNumber,
        courier: activeCourier?.apiType?.toLowerCase(),
        courierAccount: activeCourier,
        fedexPackagingType: "YOUR_PACKAGING",
        invoiceRemark: "",
        fedexServiceType: "INTERNATIONAL_PRIORITY",
        fedexDutiesPaidAccount: "31200310",
        shipmentPurpose: "SOLD",
        shipmentDate: moment().utc(),
        contents: decriptionAndContent,
        notificationMessage: "",
        additionalCourierOptions,
        address1: ifNullThenUndefined(fromOrder.addressLineOne),
        address2: ifNullThenUndefined(fromOrder.addressLineTwo),
        city: ifNullThenUndefined(fromOrder.addressCity),
        countryCode: getCountryCode(fromOrder?.addressCountry),
        personEmail: ifNullThenUndefined(fromOrder.customerEmail),
        personName: fullName,
        companyName: "-",
        personPhone: fromOrder?.customerPhone || fromOrder?.billingPhone,
        postal: ifNullThenUndefined(fromOrder.addressZip),
        division:
          ifNullThenUndefined(fromOrder.addressProvince) ||
          ifNullThenUndefined(fromOrder.addressCity),
        provinceCode:
          ifNullThenUndefined(
            getStateProvinceCode(
              fromOrder.addressProvince,
              getCountryCode(fromOrder?.addressCountry)
            )
          ) || ifNullThenUndefined(fromOrder.addressProvince),
        totalPackageWeight: totalInArray("weight"),
        totalPackageGrossWeight: totalInArray("weight"),
        totalPieces: 1,
        invoice: fromOrder.orderNumber,
        invoiceDate: moment().utc(),
        totalDeclaredValue: totalInArray("price"),
        totalDeclaredWeight: totalInArray("weight"),
        totalDeclaredGrossWeight: totalInArray("weight"),
        pieces: [
          {
            quantity: 1,
            depth: "10",
            grossWeight: totalInArray("weight"),
            height: "10",
            packagingType: "YOUR_PACKAGING",
            reference: "",
            value: 1,
            weight: totalInArray("weight"),
            width: "10",
          },
        ],
        exportDeclarationItems: exportDeclarationItems.map((item) => {
          return {
            productVariantId: item.productVariantId,
            originCountry: "KR",
            quantityUnit: "PCS",
            description: exportItemDescription(
              item.productTitle,
              item.productVariantTitle,
              item.customProductName
            ),

            sku: "",
            commCode: null,
            hsCode: item.variant.HSCode ? item.variant.HSCode : "",
            weight: twoDecimal(Number(item.weight)),
            grossWeight: twoDecimal(Number(item.weight)),
            unitPrice: Number(item.price),
            quantity: Number(item.quantity),
            category: detectBookCategory(item.productType) ? "BOOK" : "OTHER",
            totalValue: Number(item.quantity) * Number(item.price),
          };
        }),
      });
    }
  }, [fromOrder]);

  console.log(form.getFieldsValue());

  return (
    <>
      <TitleBox className="title-page">
        <Title>{t("Create Shipment")}</Title>
        <Breadcrumb
          items={[
            { name: "Home", path: "/" },
            { name: Path.CREATE_SHIPMENT.name },
          ]}
        />
      </TitleBox>

      <Container style={{ backgroundColor: "#ececec" }}>
        <Row>
          {/* left */}
          <Col md={18}>
            <Form
              wrapperCol={{ span: 24 }}
              layout="verticle"
              form={form}
              scrollToFirstError
              onChange={debounceOnChange}
            >
              <ShipmentRateDetail
                isLoading={isLoading}
                form={form}
                setIsLoading={setIsLoading}
                setRates={setRates}
                setRatesError={setRatesError}
                shippingAccounts={shippingAccounts}
                activeShipper={activeShipper}
              />
              <ShipmentDetail
                shipper={shipper}
                form={form}
                activeCourier={activeCourier}
                setActiveCourier={setActiveCourier}
                shippingAccounts={shippingAccounts}
                currency={currency}
                companyNameChecker={companyNameChecker}
              />
              <ShipperDetail form={form} />
              <ConsigneeDetail form={form} />
              <ExportDeclare
                // setMinWeight={setMinWeight} <-- incase they want back 0.02
                form={form}
                currency={currency}
                detectBookCategory={detectBookCategory}
                activeCourier={activeCourier}
                piecesRecheckDataHandler={recheckDataHandler}
              />
              <PackagingInfo
                activeCourier={activeCourier}
                currency={currency}
                fromOrder={fromOrder}
                form={form}
                recheckDataHandler={recheckDataHandler}
              />
            </Form>
          </Col>
          {/* right */}
          <Col md={6}>
            <RightColumn
              isLoading={isLoading}
              activeCourier={activeCourier}
              rates={rates}
              ratesError={ratesError}
              createShipmentError={createShipmentError}
              summary={summary}
              setActiveCourier={setActiveCourier}
              form={form}
              shippingAccounts={shippingAccounts}
              onFinish={onFinish}
              companyNameChecker={companyNameChecker}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateShipment;

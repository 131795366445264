import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { routes, routesSpecialLogged } from "../../commons/routes";
import Header from "../../components/header/Header";
import Route from "../../components/router-custom/route";
import SideBar from "../../components/sidebar/SideBar";
import NotFound from "../../pages/not-found";
import { check2ArrayPermission } from "../../utils/funcs";

const ConditionRouter = ({
  component: Component,
  condition,
  redirect,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      condition() ? <Component {...props} /> : <Redirect to={redirect} />
    }
  />
);

ConditionRouter.propTypes = {
  component: PropTypes.elementType,
  condition: PropTypes.func.isRequired,
  redirect: PropTypes.string,
};

const Routes = () => {
  const { permissionKey, token } = useSelector((state) => state.account);

  const renderAuthRoutes = () => (
    <div className="box__body">
      <div className="header-box">
        <Header />
      </div>
      <div className="box__content">
        <SideBar />
        <div className="content-box">
          <Switch>
            {routes
              .filter((route) =>
                check2ArrayPermission(permissionKey, route.permission)
              )
              .map((router, i) => (
                <Route key={i} {...router} />
              ))}
            <Route exact path="/">
              <Redirect to="/orders" />
            </Route>

            <Route exact path="*">
              <Redirect to="/orders" />
            </Route>

            <Route exact path="*" component={NotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );

  console.log(!!token);

  return (
    <Router>
      <Switch>
        {routesSpecialLogged.map((router, i) => (
          <ConditionRouter
            {...router}
            key={i}
            condition={() => !token} // <- no token in redux (not auth)
            component={router.component} // <- go here if satisfied with condition
            redirect="/orders" // <- else if not satisfied with condition
          />
        ))}
        <ConditionRouter
          condition={() => !!token} // <- got token in redux (auth)
          component={renderAuthRoutes} // <- go here if satisfied with condition
          redirect="/login" // <- else if not satisfied with condition
        />
        <Route path="*" exact component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;

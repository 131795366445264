import { Card, Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'

const { Option } = Select

const storeIndustyOption = [
  { id: 0, name: 'Beauty' },
  { id: 1, name: 'Clothing' },
  { id: 2, name: 'Electronics' },
  { id: 3, name: 'Furniture' },
  { id: 4, name: 'Handcrafts' },
  { id: 5, name: 'Jewelry' },
  { id: 6, name: 'Painting' },
  { id: 7, name: 'Photography' },
  { id: 8, name: 'Restaurants' },
  { id: 9, name: 'Groceries' },
  { id: 10, name: 'Other food & drink' },
  { id: 11, name: 'Sports' },
  { id: 12, name: 'Toys' },
  { id: 13, name: 'Services' },
  { id: 14, name: 'Virtual services' },
  { id: 15, name: 'Other' },
  { id: 16, name: "I haven't decided yet" },
]

const StoreAddress = ({ setStoreDetail }) => {
  const [storeAddress, setStoreAddress] = useState({
    companyName: '',
    companyPhone: '',
    address: '',
    unit: '',
    postcode: '',
    city: '',
    state: '',
    country: '',
  })

  const changeInputHandler = (event) => {
    return setStoreAddress((prev) => {
      return { ...prev, [event.target.name]: event.target.value }
    })
  }

  useEffect(() => {
    setStoreDetail((prev) => {
      return { ...prev, storeAddress }
    })
  }, [storeAddress])

  return (
    <Card title='Store Address' style={{ marginBottom: '24px' }}>
      <Form layout='vertical'>
        <Row gutter={16}>
          <Col md={24}>
            <p>
              This address will appear on your invoices. You can edit the
              address used to calculate shipping rates in your shipping
              settings. Your primary business location could affect which apps
              can be used on your store. Learn more about app compatibility
              (opens a new window)
            </p>
          </Col>
          <Col md={24}>
            <Form.Item label='Legal name of company'>
              <Input
                text='text'
                name='companyName'
                value={storeAddress.companyName}
                placeholder='Legal Name Of Company'
                onChange={changeInputHandler}
              />
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.Item label='Phone'>
              <Input placeholder='Phone' />
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.Item label='Address'>
              <Input placeholder='Address' />
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.Item label='Apartment, suite, etc.'>
              <Input placeholder='Apartment, suite, etc.' />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label='Postcode'>
              <Input
                style={{ width: '100%' }}
                placeholder='Apartment, suite, etc.'
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label='City'>
              <Input style={{ width: '100%' }} placeholder='City' />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label='State/territory' initialValue='MYR'>
              <Select
                defaultValue='Selangor'
                onChange={(value) => {
                  return setStoreAddress((prev) => {
                    return { ...prev, state: value }
                  })
                }}
              >
                <Option value='Perlis'>Perlis</Option>
                <Option value='Kedah'>Kedah</Option>
                <Option value='Pulau Pinang'>Pulau Pinang</Option>
                <Option value='Perak'>Perak</Option>
                <Option value='Selangor'>Selangor</Option>
                <Option value='WP Kuala Lumpur'>WP Kuala Lumpurt</Option>
                <Option value='WP Putrajaya'>WP Putrajaya</Option>
                <Option value='Negeri Sembilan'>Negeri Sembilan</Option>
                <Option value='Melaka'>Melaka</Option>
                <Option value='Johor'>Johor</Option>
                <Option value='Pahang'>Pahang</Option>
                <Option value='Terengganu'>Terengganu</Option>
                <Option value='Kelantan'>Kelantan</Option>
                <Option value='Sabah'>Sabah</Option>
                <Option value='Sarawak'>Sarawak</Option>
                <Option value='WP Labuan'>WP Labuan</Option>
                <Option value='WP Langkawi'>WP Langkawi</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label='Country/region'>
              <Select>
                <Option>Malaysia</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default StoreAddress

import { Button, message, Table } from "antd";
import shipmentsApi from "apis/shipmentsApi";
import Path from "commons/breadcrumbs";
import Box from "components/box/Box";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import produce from "immer";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ListStyle } from "./index.style";

const { Column } = Table;

const Couriers = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState({
    shipping_account: [],
    totalCount: 0,
  });
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  });

  const gettingListCouriers = async () => {
    setIsLoading(true);
    try {
      const res = await shipmentsApi.getListShippingAccounts({ params });
      if (Array.isArray(res.shipping_account)) {
        setDataList(res);
      }
    } catch (error) {
      message.error('Get List Couriers fail');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    gettingListCouriers();
  }, [params]);

  const pageSizeOnChange = debounce((page, pageSize) => {
    const newParam = produce(params, (draft) => {
      draft.page = page;
      draft.pageSize = pageSize;
    });
    setParams(newParam);
  }, 500);

  return (
    <ListStyle>
      <TitleHeader
        listOfBreadcrumb={[
          { name: "Home", path: "/" },
          { name: Path.SETTINGS.name, path: Path.SETTINGS.pathname },
          { name: Path.COURIERS.name },
        ]}
        title={t(Path.COURIERS.name)}
      />
      <Box>
        <Container>
          <div style={{ textAlign: "right", paddingBottom: 12 }}>
            <Link to={Path.COURIER_ADD.pathname}>
              <Button type="primary">Add Account</Button>
            </Link>
          </div>
          <Table
            dataSource={dataList.shipping_account}
            loading={isLoading}
            pagination={{
              pageSizeOptions: ["10", "20", "30"],
              showSizeChanger: true,
              defaultPageSize: 10,
              onChange: pageSizeOnChange,
              current: params.page,
              pageSize: params.pageSize,
              total: dataList.totalCount,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`,
            }}
          >
            <Column
              title="Account Name"
              dataIndex="accountName"
              key="accountName"
            />
            <Column title="Courier" dataIndex="apiType" key="apiType" />
            <Column
              title="Account Number"
              dataIndex="accountNumber"
              key="accountNumber"
            />
            <Column
              title="Label Printer"
              dataIndex="labelPrinterName"
              key="labelPrinterName"
            />
            <Column
              title="Invoice Printer"
              dataIndex="invoicePrinterName"
              key="invoicePrinterName"
            />
            <Column
              title="Terms Of Trade"
              dataIndex="termsOfTrade"
              key="termsOfTrade"
            />
            <Column
              title=""
              key="action"
              render={(record) => (
                <Link to={`${Path.COURIER_UPDATE.pathname}?id=${record.id}`}>
                  <Button type="primary">Edit</Button>
                </Link>
              )}
            />
          </Table>
        </Container>
      </Box>
    </ListStyle>
  );
};

export default Couriers;

import { message, Pagination, Spin, Typography } from "antd";
import purchaseApi from "apis/purchaseApi";
import Container from "components/container/Container";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Path from "../../commons/breadcrumbs";
import Breadcrumbs from "../../components/breadcrumb/Breadcrumb";
import FilterPurchaseOrder from "./FilterPurchaseOrder";
import { CardGrid, TitleBox } from "./index.style";
import PurchaseOrderCardItem from "./PurchaseOrderCardItem";

const { Title } = Typography;

const PurchaseOrders = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [listPurchaseOrders, setListPurchaseOrders] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    totalCount: 0,
  });

  const getPurchaseOrder = () => {
    setIsLoading(true);
    const param = {
      page: params.page,
      pageSize: params.pageSize,
    };
    purchaseApi
      .getListPurchaseOrders(param)
      .then(({ purchaseOrderList, totalCount }) => {
        setListPurchaseOrders((prev) => purchaseOrderList);
        setParams((prev) => {
          return { ...prev, totalCount };
        });
        setIsLoading(false);
      })
      .catch((error) => {
        message.error(error, 5);
        setIsLoading(false);
      });
  };

  const changePageHandler = (page, pageSize) => {
    setParams({ ...params, page, pageSize });
  };

  useEffect(() => {
    getPurchaseOrder();
  }, [params.pageSize]);

  return (
    <>
      <TitleBox className="title-page">
        <Title>{t("Purchase Orders")}</Title>
        <Breadcrumbs
          items={[
            { name: "Home", path: "/" },
            { name: Path.PURCHASE_ORDERS.name },
          ]}
        />
      </TitleBox>
      <Container>
        <FilterPurchaseOrder />
        <Pagination
          style={{ marginTop: "10px" }}
          showSizeChanger
          onChange={changePageHandler}
          total={params.totalCount}
          // showTotal={(total, range) =>
          //   `Showing ${range[0]}-${range[1]} of ${total} items`
          // }
          current={params.page}
          pageSize={params.pageSize}
          defaultPageSize={10}
          pageSizeOptions={[10, 20, 30]}
        />
        <CardGrid>
          {isLoading ? (
            <Spin />
          ) : listPurchaseOrders ? (
            listPurchaseOrders.map((item, index) => (
              <PurchaseOrderCardItem
                key={item.orderNumber}
                index={index}
                item={item}
              />
            ))
          ) : (
            "No Data"
          )}
        </CardGrid>
      </Container>
    </>
  );
};

export default PurchaseOrders;

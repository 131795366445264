import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { BoxContainer } from './Box.styles'

function Box({ title = '', children, className }) {
  return (
    <BoxContainer className={cx(className, 'container-box')}>
      {/* <Helmet>
        <title>Ware harum - {title}</title>
      </Helmet> */}
      {children}
    </BoxContainer>
  )
}

Box.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default Box

import {
  Col,
  Row,
  Form,
  Input,
  Select,
  Button,
  message,
  Typography,
  Card,
  Checkbox,
} from "antd";
import shipmentsApi from "apis/shipmentsApi";
import kodNegara from "kod-negara";
import { groupBy, merge } from "lodash";
import React, { useMemo, useState, useEffect } from "react";
import {
  ButtonPlacement,
  FormBody,
  FormHeader,
  FormWrapper,
  ShippingAccountsGroupsWrapper,
} from "./index.style";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";

const { Option } = Select;
const { Text } = Typography;

const ShipmentRateDetail = ({
  isLoading,
  form,
  setIsLoading,
  setRates,
  setRatesError,
  shippingAccounts,
  activeShipper,
}) => {
  const { currency } = useSelector((state) => state.common);
  const [shippingAccountsSelected, setShippingAccountsSelected] = useState([]);
  const shippingAccountsGroups = useMemo(
    () => Object.values(groupBy(shippingAccounts, "apiType")),
    [shippingAccounts]
  );

  useEffect(() => {
    if (shippingAccounts.length > 0) {
      setShippingAccountsSelected(
        [].concat(
          ...shippingAccountsGroups.map((groupShippings) =>
            groupShippings.map((item) => item.id)
          )
        )
      );
    }
  }, [shippingAccounts]);

  const handleCheckShippingAccount = (e, id) => {
    if (e.target.checked) {
      setShippingAccountsSelected([...shippingAccountsSelected, id]);
    } else {
      setShippingAccountsSelected(
        shippingAccountsSelected.filter((item) => item !== id)
      );
    }
  };

  const rateShipments = () => {
    if (shippingAccounts === undefined) {
      return alert("Shipping Accounts Undefined");
    }

    form
      .validateFields()
      .then((response) => {
        setIsLoading(true);
        const callObj = {
          userId: "123", // hardcoded, please change them once user done
          shippingAccounts: shippingAccounts.map((acc) => ({
            type: acc.apiType,
            id: acc.id,
          })),
          shipperDetailsId: activeShipper.id,
          sampleResponse: false,
          invoiceNumber: String(response?.invoice),
          invoiceDate: moment(response?.exportDeclarations?.invoiceDate)
            .utc()
            .format(),
          shipmentDate: moment(response?.shipmentDate).utc().format(),
          declaredValue: String(response?.totalDeclaredValue),
          email: response?.personEmail,
          notificationMessage: response?.notificationMessage,
          contents: response?.contents,
          notification:
            response?.additionalCourierOptions?.includes("notification"),
          shopifyNotification: response?.additionalCourierOptions?.includes(
            "shopifyNotification"
          ),
          pltShipment:
            response?.additionalCourierOptions?.includes("pltShipment"),
          documentsOnly:
            response?.additionalCourierOptions?.includes("documentsOnly"),
          signatureRequired:
            response?.additionalCourierOptions?.includes("signatureRequired"),
          signatureType: "DIRECT", // <-- hardcoded
          useOwnInvoice:
            response?.additionalCourierOptions?.includes("useOwnInvoice"),
          fedexPackagingType: response?.fedexPackagingType, // <-- ada dalam packagingInformation
          fedexServiceType: response?.fedexServiceType, // <-- ada dalam packagingInformation
          fedexDutiesPaidAccount: response?.fedexDutiesPaidAccount,
          shipmentPurpose: response?.shipmentPurpose, // <-- please refer documentation from Edward and Kaamesh
          insuranceCost: "", // <-- hardcoded
          shippingCost: "", // <-- hardcoded
          packingCost: String(response?.totalDeclaredValue),
          handlingCost: "", // <-- hardcoded
          otherCost: "", // <-- hardcoded
          trackingNumber: "80329091290", // <-- hardcoded
          invoiceRemark: response?.invoiceRemark,
          invoiceCurrency: currency,
          invoiceWeightUnit: "KG", // <-- hardcoded
          invoiceLetterhead: {
            content: "", // <-- hardcoded
            fedexImageId: "IMAGE_1", // <-- hardcoded
          },
          invoiceSignature: {
            content: "", // <-- hardcoded
            fedexImageId: "IMAGE_2", // <-- hardcoded
          },
          invoiceData: {
            documentType: "COMMERCIAL_INVOICE", // <-- hardcoded
            fileName: "Invoice.pdf", // <-- hardcoded
            content:
              "JVBERi0xLjQKJeLjz9MKNiAwIG9iago8PC9GaWx0ZXIvRmxhdGVEZWNvZGUvTGVuZ3RoIDUxPj5zdHJl", // <-- hardcoded
          },
          rincosServiceCode: "", // <-- hardcoded
          rincosServiceName: "", // <-- hardcoded
          labelType: "ZPLII", // <-- hardcoded
          consignee: {
            companyName: response?.companyName,
            personName: response?.personName,
            personPhone: response?.personPhone,
            personEmail: response?.personEmail,
            address1: response?.address1,
            address2: response?.address2,
            stateOrProvinceName: response?.stateOrProvinceName,
            city: response?.city,
            division: response?.division,
            provinceCode: response?.provinceCode,
            postal: response?.postal,
            countryCode: response?.countryCode,
          },
          exportDeclaration: response?.exportDeclarationItems?.map((item) => {
            return {
              id: uuidv4(),
              updateVariant:
                item.updateVariant?.includes("updateVariant") || false,
              productVariantId: item.productVariantId,
              quantity: item.quantity,
              quantityUnit: "PCS",
              description: item.description,
              value: String(item.unitPrice),
              totalValue: String(item.totalValue),
              weight: String((Math.round(item.weight * 100) / 100).toFixed(2)),
              grossWeight: String(
                (Math.round(item.grossWeight * 100) / 100).toFixed(2)
              ),
              HSCode: item.hsCode,
              sku: item.sku,
              originCountry: item.originCountry,
              category: item.category,
            };
          }),
          pieces: response?.pieces?.map((item) => {
            return {
              id: uuidv4(),
              height: String(item.height),
              width: String(item.width),
              depth: String(item.depth),
              value: String(item.value),
              weight: String((Math.round(item.weight * 100) / 100).toFixed(2)),
              reference: item.reference,
            };
          }),
        };

        console.log("RATE SHIPMENT JSON:", JSON.stringify(callObj));

        shipmentsApi
          .getRateList(callObj)
          .then((res) => {
            if (res === undefined) {
              return alert("Undefined response");
            }
            const { rates, errors } = res;
            const filteredRates =
              rates?.filter((item) => !isNaN(item.rateNetCharge)) || [];
            setRatesError(() => errors);
            setRates(() => filteredRates);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            message.error(error.message);
          });
      })
      .catch((error) => {
        console.log("from error", error);
        setRatesError(error?.errorFields);
        alert("Please fill in all the required information before Check Rates");
        window.scrollTo(0, 0);
      });
  };

  return (
    <FormWrapper>
      <FormHeader className="greenBoldTitle">Shipment Rate Details</FormHeader>
      <FormBody>
        <Row gutter={16}>
          <Col md={8}>
            <Form.Item
              name="postal"
              label="Postcode"
              tooltip="Postcode/Postal Code"
              required
              extra="Please fill Consignee/Poscode details"
            >
              <Input placeholder="Postal Code/ Postcode" disabled />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              name="countryCode"
              label="Destination"
              tooltip="Destination"
              required
              extra="Please fill Consignee/Destinations details"
            >
              <Select showSearch style={{ width: "100%" }} disabled>
                {kodNegara.all().map((item, index) => (
                  <Option key={index} value={item.countryCode}>
                    {item.countryNameEn} ({item.countryCode})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              name="totalPackageWeight"
              label="Check Weight"
              tooltip="Check Weight"
              extra="Click Recheck Data After Updated Packaging Informations"
              required
            >
              <Input
                type="number"
                step="0.01"
                style={{
                  width: "100%",
                }}
                addonBefore={"kg".toUpperCase()}
              />
            </Form.Item>
          </Col>
          <ShippingAccountsGroupsWrapper span={24}>
            <Text className="title">Rate for account:</Text>
            {shippingAccountsGroups?.map((groupShippings) => (
              <div
                key={groupShippings[0]?.apiType}
                className="shipping-accounts-group"
              >
                <Text className="api-type">{groupShippings[0]?.apiType}</Text>
                <Row gutter={16}>
                  {groupShippings.map((shippingAccount) => (
                    <Col xs={24} sm={12} md={6} key={shippingAccount.id}>
                      <Card>
                        <Checkbox
                          checked={shippingAccountsSelected.some(
                            (item) => item === shippingAccount.id
                          )}
                          onChange={(e) =>
                            handleCheckShippingAccount(e, shippingAccount.id)
                          }
                        >
                          <div>{shippingAccount.accountName}</div>
                          <div>{shippingAccount.accountNumber}</div>
                        </Checkbox>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
          </ShippingAccountsGroupsWrapper>
        </Row>
        <ButtonPlacement>
          <Button loading={isLoading} onClick={rateShipments}>
            Check Rate
          </Button>
        </ButtonPlacement>
      </FormBody>
    </FormWrapper>
  );
};

export default ShipmentRateDetail;

import { Card, Col, Divider, Form, Input, Row } from 'antd'
import React, { useEffect, useState } from 'react'

const StandardsAndFormats = ({ setStoreDetail }) => {
  const [standardAndFormat, setStandardAndFormats] = useState({
    timesZones: '',
    unitSystem: '',
    defaultWeightUnit: '',
    prefix: '',
    suffix: '',
    storeCurrency: '',
  })

  const changeInputHandler = (event) => {
    return setStandardAndFormats((prev) => {
      return { ...prev, [event.target.name]: event.target.value }
    })
  }

  useEffect(() => {
    setStoreDetail((prev) => {
      return { ...prev, standardAndFormat }
    })
  }, [standardAndFormat])

  return (
    <Card title='Standards And Formats' style={{ marginBottom: '24px' }}>
      <Form layout='vertical'>
        <Row gutter={16}>
          <Col md={24}>
            <p>
              Standards and formats are used to calculate product prices,
              shipping weights, and order times.
            </p>
          </Col>
          <Col md={24}>
            <Form.Item label='Time Zones'>
              <Input
                text='text'
                name='timesZones'
                value={standardAndFormat.timesZones}
                placeholder='Time Zones'
                onChange={changeInputHandler}
              />
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item label='Unit System'>
              <Input
                text='text'
                name='unitSystem'
                value={standardAndFormat.unitSystem}
                placeholder='Time Zones'
                onChange={changeInputHandler}
              />
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item label='Default Weight Unit'>
              <Input
                text='text'
                name='defaultWeightUnit'
                value={standardAndFormat.defaultWeightUnit}
                placeholder='Default Weight Unit'
                onChange={changeInputHandler}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col md={24}>
            <h1>EDIT ORDER ID FORMAT (OPTIONAL)</h1>
            <p>
              Order numbers start at #1001 by default. While you can’t change
              the order number itself, you can add a prefix or suffix to create
              IDs like "EN1001" or "1001-A."
            </p>
          </Col>

          <Col md={12}>
            <Form.Item
              label='Prefix'
              extra='Your order ID will appear as #1001, #1002, #1003 ...'
            >
              <Input
                text='text'
                name='prefix'
                value={standardAndFormat.prefix}
                placeholder='Prefix'
                onChange={changeInputHandler}
              />
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item label='Suffix'>
              <Input
                text='text'
                name='suffix'
                value={standardAndFormat.suffix}
                placeholder='Suffix'
                onChange={changeInputHandler}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default StandardsAndFormats

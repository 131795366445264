import { Button, Divider, message, Space } from "antd";
import Box from "components/box/Box";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setCurrency } from "store/slices/commonSlice";
import Path from "../../commons/breadcrumbs";
import StandardsAndFormats from "./StandardsAndFormats";
import StoreAddress from "./StoreAddress";
import StoreContactInformation from "./StoreContactInformation";
import StoreCurrency from "./StoreCurrency";

const StoreDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [storeDetail, setStoreDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async () => {
    setIsLoading(true);
    try {
      // const { res } = await storeDetailApi(storeDetail)
      setIsLoading(false);
      message.success("Submited", 3);
    } catch (error) {
      setIsLoading(false);
      message.error(error.message, 3);
    }
  };

  const clearHandler = () => {
    setStoreDetail({});
  };

  useEffect(() => {
    if (!!storeDetail.storeCurrency) {
      dispatch(setCurrency(storeDetail?.storeCurrency));
    }
  }, [storeDetail]);

  return (
    <>
      <TitleHeader
        listOfBreadcrumb={[
          { name: "Home", path: "/" },
          { name: Path.SETTINGS.name, path: Path.SETTINGS.pathname },
          {
            name: Path.STORE_DETAILS.name,
          },
        ]}
        title={t(Path.STORE_DETAILS.name)}
      />
      <Box>
        <Container>
          <StoreContactInformation setStoreDetail={setStoreDetail} />
          <StoreAddress setStoreDetail={setStoreDetail} />
          <StandardsAndFormats setStoreDetail={setStoreDetail} />
          <StoreCurrency setStoreDetail={setStoreDetail} />
          <Divider />
          <Space>
            <Button type="primary" onClick={submitHandler} disabled={isLoading}>
              Submit
            </Button>
            <Button type="danger" onClick={clearHandler} disabled={isLoading}>
              Decline
            </Button>
          </Space>
        </Container>
      </Box>
    </>
  );
};

export default StoreDetails;

import { Form, Select } from 'antd'
import Box from 'components/box/Box'
import Container from 'components/container/Container'
import TitleHeader from 'components/title-header'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import LocalStorage from 'utils/storage'
import Path from '../../commons/breadcrumbs'

const { Option } = Select

const Languages = () => {
  const currentLanguage = useSelector((state) => state.common.currentLanguage)

  const { t, i18n } = useTranslation()

  const onSelectHandler = (value) => {
    i18n.changeLanguage(value)
    LocalStorage.set('lang', value)
  }

  return (
    <div>
      <TitleHeader
        listOfBreadcrumb={[
          { name: 'Home', path: '/' },
          { name: Path.SETTINGS.name, path: Path.SETTINGS.pathname },
          { name: Path.LANGUAGES.name },
        ]}
        title={t('Languages')}
      />
      <Box>
        <Container>
          <Form layout='vertical'>
            <Form.Item label={t('Languages')}>
              <Select defaultValue={currentLanguage} onSelect={onSelectHandler}>
                <Option value='kr'>Korea (kr)</Option>
                <Option value='en'>English (en)</Option>
              </Select>
            </Form.Item>
          </Form>
        </Container>
      </Box>
    </div>
  )
}

export default Languages

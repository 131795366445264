import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Space, Typography } from 'antd'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import React from 'react'
import { TitleBox } from './index.style'

const { Title } = Typography

const TitleHeader = ({ listOfBreadcrumb, title, currentHistory }) => {
  return (
    <TitleBox className='title-page'>
      <Space>
        {currentHistory && (
          <Button
            onClick={() => {
              currentHistory.goBack()
            }}
          >
            <ArrowLeftOutlined />
          </Button>
        )}
        <Title>{title}</Title>
      </Space>
      <Breadcrumb items={listOfBreadcrumb} />
    </TitleBox>
  )
}

export default TitleHeader

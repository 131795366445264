import { Card, Col, Form, Row, Select } from "antd";
import React from "react";
import { } from "lodash";

const { Option } = Select;

const COURIER_TERMS_OF_TRADE = ["FCA", "FOB", "EXW", "CIP", "CPT", "DDU", "DDP", "DAP", "DPU"];
const FEDEX_INTERNATION = ["FEDEX_INTERNATIONAL_CONNECT_PLUS", "FEDEX_INTERNATIONAL_PRIORITY"];
const DROPOFF_TYPE = ["REGULAR_PICKUP", "USE_SCHEDULED_PICKUP"];

function ShipmentDetails({ form }) {
  return (
    <Card title="Shipment details" style={{ marginBottom: 12 }}>
      <Row gutter={[10]}>
        <Form.Item noStyle shouldUpdate={({ apiType: prev }, { apiType: current }) => prev !== current}>
          {() => form.getFieldValue('apiType') === 'fedex' && (
            <>
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  name="fedexServiceType"
                  label="Service Type"
                  rules={[{ required: true, message: "Terms of Trade is required" }]}
                >
                  <Select>
                    {FEDEX_INTERNATION.map((item) => (
                      <Option key={item} value={item}>
                        {item.replaceAll('_', ' ')}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  name="fedexDropoffType"
                  label="Dropoff Type"
                  rules={[{ required: true, message: "Dropoff Type is required" }]}
                >
                  <Select>
                    {DROPOFF_TYPE.map((item) => (
                      <Option key={item} value={item}>
                        {item.replaceAll('_', ' ')}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
        </Form.Item>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            name="termsOfTrade"
            label="Terms of Trade"
            rules={[{ required: true, message: "Terms of Trade is required" }]}
          >
            <Select>
              {COURIER_TERMS_OF_TRADE.map((item) => (
                <Option key={item} value={item}>
                  {item.toUpperCase()}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            name="shippingFeePaidBy"
            label="Shipping Fees Paid By"
            rules={[
              { required: true, message: "Shipping Fees Paid By is required" },
            ]}
          >
            <Select>
              {["RECIPIENT", "SENDER"].map((item) => (
                <Option key={item} value={item}>
                  {item.toUpperCase()}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            name="fedexDutiesPaidBy"
            label="Duties Paid By"
            rules={[{ required: true, message: "Duties Paid By is required" }]}
          >
            <Select>
              {["RECIPIENT", "SENDER"].map((item) => (
                <Option key={item} value={item}>
                  {item.toUpperCase()}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
}
export default ShipmentDetails;

import { Col, Form, Input, Row, Select } from "antd";
import kodNegara from "kod-negara";
import { values } from "lodash";
import PropTypes from "prop-types";
import { FormBody, FormHeader, FormWrapper } from "./index.style";

const { Option } = Select;

const ConsigneeDetail = ({ form }) => {
  const onSelect = (value, fieldName) => {
    const countryCode = value.match("[a-zA-Z]+")[0];
    return form.setFieldsValue({ [fieldName]: countryCode });
  };

  return (
    <FormWrapper>
      <FormHeader className="greenBoldTitle">Consignee Details</FormHeader>
      <FormBody>
        <Row gutter={16}>
          <Col lg={6}>
            <Form.Item
              name="personName"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Receiver name is required",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              name="personEmail"
              label="Email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please insert consignee email",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Email" type="email" />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              name="personPhone"
              label="Phone"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Consignee's contact number",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="input placeholder" />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              name="companyName"
              label="Company"
              rules={[
                {
                  required: true,
                  message: "Insert customer name if they don't have company",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="input placeholder" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={8}>
            <Form.Item
              name="address1"
              label="Address Line 1"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Address 1 is required",
                },
                {
                  max: 35,
                  message: "Value should be less or equal to 35 character",
                },
              ]}
              hasFeedback
              extra="Eg. Apartment Number, Unit, Block"
            >
              <Input placeholder="Unit 10, Level 2, Manor Apartment" />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              name="address2"
              label="Address Line 2"
              rules={[
                {
                  type: "string",
                  required: false,
                },
                {
                  max: 35,
                  message: "Value should be less or equal to 35 character",
                },
              ]}
              hasFeedback
              extra="Eg. Road, Street"
            >
              <Input placeholder="Abbey Road" />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "City is required",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="London" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={6}>
            <Form.Item
              name="provinceCode"
              label="Province Code"
              rules={[
                {
                  required: true,
                  message: "Please choose one",
                },
              ]}
              initialValue={"KR"}
              extra="Eg. 'KR' for Korea"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              name="division"
              label="Division/State/Province"
              rules={[
                {
                  type: "string",
                  required: false,
                },
              ]}
            >
              <Input placeholder="input placeholder" />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              name="countryCode"
              label="Country Code"
              rules={[
                {
                  required: true,
                  message: "Please choose one",
                },
              ]}
              initialValue={"KR"}
              extra="Eg. 'KR' for Korea"
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                onSelect={(value) => onSelect(value, "countryCode")}
              >
                {kodNegara.all().map((item, index) => (
                  <Option key={index} value={item.countryCode}>
                    {item.countryNameEn} ({item.countryCode})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              name="postal"
              label="Postal Code"
              rules={[
                {
                  required: true,
                  message: "Postal Code is required",
                },
              ]}
              extra="Eg. 93050"
              hasFeedback
            >
              <Input placeholder="93050" />
            </Form.Item>
          </Col>
        </Row>
      </FormBody>
    </FormWrapper>
  );
};

ConsigneeDetail.propTypes = {
  consignee: PropTypes.object,
  onChange: PropTypes.func,
};

export default ConsigneeDetail;

import React from 'react'

const CustomPendingPurchases = () => {
  return (
    <div>
      <h1>Custom Pending Purchases</h1>
    </div>
  )
}

export default CustomPendingPurchases

const Breadcrumbs = {
  HOME: "/",
  LOGIN: "/login",
  ORDERS: {
    pathname: "/orders",
    name: "Orders",
  },
  CREATE_ORDER: {
    pathname: "/orders/create-order",
    name: "Create Order",
  },
  ORDER_DETAIL: {
    pathname: "/orders/order-details/:id",
    pathParam: (id) => `/orders/order-details/${id}`,
    name: "Orders Detail",
  },
  SHIPMENTS: {
    pathname: "/shipments",
    name: "Shipments",
  },
  SHIPPER_DETAILS: {
    pathname: "/shipper-details",
    name: "Shipper Details",
  },
  READY_TO_SHIP: {
    pathname: "/ready-to-ship",
    name: "Ready To Ship",
  },
  CREATE_SHIPMENT: {
    pathname: "/create-shipment",
    name: "Create Shipment",
  },
  TODAYS_SHIPMENT: {
    pathname: "/todays-shipment",
    name: "Today's Shipment",
  },
  COURIER_LIST: {
    pathname: "/courier-list",
    name: "Courier List",
  },
  PRODUCTS: {
    pathname: "/products",
    name: "Products",
  },
  CREATE_PRODUCT: {
    pathname: "/products/create-product",
    name: "Create Product",
  },
  PRODUCT_DETAIL: {
    pathname: "/products/product-detail/",
    pathParam: (id) => `/products/product-detail/?id=${id}`,
    name: "Product Detail",
  },
  SETTINGS: {
    pathname: "/settings/",
    name: "Settings",
  },
  COURIERS: {
    pathname: "/settings/couriers",
    name: "Courier Accounts",
  },
  COURIER_ADD: {
    pathname: "/settings/couriers/add",
    name: "Add Courier Accounts",
  },
  COURIER_UPDATE: {
    pathname: "/settings/couriers/update",
    name: "Update Courier Accounts",
  },
  STORE_DETAILS: {
    pathname: "/settings/store-details",
    name: "Store Details",
  },
  USERS_AND_PERMISIONS: {
    pathname: "/settings/users-and-permissions",
    name: "Users And Permissions",
  },
  SALES_AND_CHANNELS: {
    pathname: "/settings/sales-and-channels",
    name: "Sales And Channel",
  },
  LANGUAGES: {
    pathname: "/settings/languages",
    name: "Languages",
  },
  POLICIES: {
    pathname: "/settings/policies",
    name: "Policies",
  },
  ADD_STAFF: {
    pathname: "/settings/users-and-permissions/add-staff",
    name: "Add Staff",
  },
  EDIT_STAFF_WITH_ID: {
    pathParam: (id) => `/settings/users-and-permissions/edit-staff/${id}`,
    pathname: `/settings/users-and-permissions/edit-staff/:id`,
    name: "Edit Staff",
  },
  INVENTORY: {
    pathname: "/inventory",
    name: "Inventory",
  },
  PURCHASES: {
    pathname: "/purchases",
    name: "Purchases",
  },
  PENDING_PURCHASE: {
    pathname: "/purchases/pending-purchases",
    name: "Pending Purchases",
  },
  PURCHASE_ORDERS: {
    pathname: "/purchases/purchase-orders",
    name: "Purchase Orders",
  },
  SUPPLIER_LIST: {
    pathname: "/purchases/supplier-list",
    name: "Supplier List",
  },
  PROOF_OF_PURCHASE: {
    pathname: "/purchases/proof-of-purchase",
    name: "Proof Of Purchase",
  },
  MAKE_PURCHASE: {
    pathname: "/purchases/pending-purchases/make-purchase",
    name: "Make Purchase",
  },
  CREATED_SHIPMENT: {
    pathname: "/created-shipment",
    name: "Created Shipment",
  },
  RATE_SHIPMENT: {
    pathname: "/rate-shipment",
    name: "Rate Shipment",
  },
};

export default Breadcrumbs;

import { Card, Checkbox, Form, Input, Radio, Typography } from 'antd'
import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useStateCallback from 'hooks/useStateCallback'
import { debounce } from 'lodash'

const { Title } = Typography
const { Search } = Input

const SenderDetails = () => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const [params, setParams] = useStateCallback({
    page: 1,
    pageSize: 10,
    q: '',
  })

  const debounceCallbackSearch = useCallback(
    debounce((value) => {
      setParams((prev) => {
        return { ...prev, q: value }
      })
    }, 500),
    []
  )

  const handleChangeQuery = (event) => {
    const { value } = event.target
    setSearchText(value)
    debounceCallbackSearch(value)
  }

  return (
    <>
      <Card style={{ marginBottom: '24px' }}>
        <Title level={5}>{t('Sender Details')}</Title>
        <Form.Item label='Search Sender'>
          <Search
            placeholder='Input search text'
            // onSearch={() => onSearch()}
            name='q'
            value={searchText}
            onChange={handleChangeQuery}
          />
        </Form.Item>
        <Form.Item label='Filer Sender'>
          <Checkbox value='dropshipping'>Dropshipping</Checkbox>
          <Checkbox value='forwarding'>Forwarding</Checkbox>
          <Checkbox value='useSenderDetails'>useSenderDetails</Checkbox>
          <Checkbox value='customLabel'>Custom Label/Branding</Checkbox>
        </Form.Item>
        <Form.Item label='Address' style={{ marginBottom: 0 }}>
          <Radio.Group optionType='button' defaultValue='Harumio Store'>
            <Radio.Button value='Harumio Store'>Harumio Store</Radio.Button>
            <Radio.Button value='Sender Store'>Sender Store</Radio.Button>
            <Radio.Button value='Custom Store'>Custom Store</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Card>
    </>
  )
}

export default SenderDetails

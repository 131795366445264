import { FileImageOutlined, PlusCircleOutlined } from '@ant-design/icons'
import React from 'react'
import { AddVariantBtn, VariantCard, VariantGrid } from './index.style'

const ProductVariant = ({ variants, setIsModal, addOrderBtn }) => {
  const addVariantProd = () => {
    return setIsModal((prev) => {
      return { ...prev, addProductVariant: true }
    })
  }

  return (
    <VariantGrid>
      {variants?.map((variant, index) => (
        <VariantCard key={variant.id} onClick={() => addOrderBtn(index)}>
          <div className='variantTitle'>{variant.title}</div>
          {/* dont change/ delete this variant div title gradient  */}
          <div className='variantTitleGradient'></div>
          {variant.images.length > 0 ? (
            <img src={variant.images[0]} alt={variant.title} />
          ) : (
            <FileImageOutlined
              style={{
                fontSize: '60pt',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          )}
        </VariantCard>
      ))}
      <AddVariantBtn onClick={addVariantProd}>
        <PlusCircleOutlined />
      </AddVariantBtn>
    </VariantGrid>
  )
}

export default ProductVariant

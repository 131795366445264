import { useState } from 'react'

const useModal = (defaultValue) => {
  const [item, setItem] = useState(defaultValue)

  const onClose = () => setItem(null)
  const onOpen = (value) => setItem(value)
  const onToggle = () => setItem(!item)

  return [item, onClose, onOpen, onToggle]
}

export default useModal

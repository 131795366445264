import { Card, Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'

const { Option } = Select

const storeIndustyOption = [
  { id: 0, name: 'Beauty' },
  { id: 1, name: 'Clothing' },
  { id: 2, name: 'Electronics' },
  { id: 3, name: 'Furniture' },
  { id: 4, name: 'Handcrafts' },
  { id: 5, name: 'Jewelry' },
  { id: 6, name: 'Painting' },
  { id: 7, name: 'Photography' },
  { id: 8, name: 'Restaurants' },
  { id: 9, name: 'Groceries' },
  { id: 10, name: 'Other food & drink' },
  { id: 11, name: 'Sports' },
  { id: 12, name: 'Toys' },
  { id: 13, name: 'Services' },
  { id: 14, name: 'Virtual services' },
  { id: 15, name: 'Other' },
  { id: 16, name: "I haven't decided yet" },
]

const StoreContactInformation = ({ setStoreDetail }) => {
  const [storeContactInformation, setStoreContactInformation] = useState({
    storeName: '',
    storeEmail: '',
    senderEmail: '',
    storeIndustry: '',
  })

  const changeInputHandler = (event) => {
    return setStoreContactInformation((prev) => {
      return { ...prev, [event.target.name]: event.target.value }
    })
  }

  useEffect(() => {
    setStoreDetail((prev) => {
      return { ...prev, storeContactInformation }
    })
  }, [storeContactInformation])

  return (
    <Card title='Store contact information' style={{ marginBottom: '24px' }}>
      <Form layout='vertical'>
        <Row gutter={16}>
          <Col md={24}>
            <p>
              Shopify and your customers will use this information to contact
              you.
            </p>
          </Col>
          <Col md={24}>
            <Form.Item label='Store Name'>
              <Input
                text='text'
                name='storeName'
                value={storeContactInformation.storeName}
                placeholder='Your store name'
                onChange={changeInputHandler}
              />
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item
              label='Store contact email'
              extra="We'll use this address if we need to contact you about your store."
            >
              <Input
                type='email'
                name='storeEmail'
                value={storeContactInformation.storeEmail}
                placeholder='Store Email'
                onChange={changeInputHandler}
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label='Sender email'
              extra='Your customers will see this address if you email them.'
            >
              <Input
                type='email'
                name='senderEmail'
                value={storeContactInformation.senderEmail}
                placeholder='Sender Email'
                onChange={changeInputHandler}
              />
            </Form.Item>
          </Col>

          <Col md={24}>
            <Form.Item label='Store Industry'>
              <Select
                defaultValue='Other'
                placeholder='Please choose one'
                onChange={(value) =>
                  setStoreContactInformation((prev) => {
                    return { ...prev, storeIndustry: value }
                  })
                }
              >
                {storeIndustyOption.map((option) => (
                  <Option key={option.id} value={option.name}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default StoreContactInformation

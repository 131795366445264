import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Affix, Button, Col, Form, message, Modal, Row, Space, Spin } from "antd";
import shipmentsApi from "apis/shipmentsApi";
import Path from "commons/breadcrumbs";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import useQuery from "hooks/useQuery";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AuthenticationDetails from "./AuthenticationDetails";
import GeneralAccountDetails from "./GeneralAccountDetails";
import { CourierFormStyle } from "./index.style";
import PrinterDetails from "./PrinterDetails";
import ShipmentDetails from "./ShipmentDetails";

const { confirm } = Modal;

const CourierForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const history = useHistory();
  const query = useQuery();

  const getDetail = async (id) => {
    setInit(true);
    try {
      const res = await shipmentsApi.getListShippingAccounts({ params: { filter: [`id_eq=${id}`] } });
      if (Array.isArray(res.shipping_account) && res.shipping_account.length === 1) {
        const account = res.shipping_account[0];
        const expiredAt = account.fedexOauthTokenExpiredAt ? moment(account.fedexOauthTokenExpiredAt) : null;
        setAccountNumber(account.accountNumber);
        form.setFieldsValue({
          apiType: account.apiType,
          accountName: account.accountName,
          accountNumber: account.accountNumber,
          meterNumber: account.meterNumber,
          termsOfTrade: account.termsOfTrade,
          shippingFeePaidBy: account.shippingFeePaidBy,
          fedexDutiesPaidBy: account.fedexDutiesPaidBy,
          labelPrinterId: account.labelPrinterId,
          invoicePrinterId: account.invoicePrinterId,
          key: account.key,
          password: account.password,
          apiUrl: account.apiUrl,
          fedexOauthTokenExpiredAt: expiredAt,
          fedexOauthToken: account.fedexOauthToken,
          fedexServiceType: account.fedexServiceType,
          fedexDropoffType: account.fedexDropoffType,
        });
      }
    } catch (error) {
      message.error('Get List Couriers fail');
      console.error(error);
    } finally {
      setInit(false);
    }
  };

  useEffect(() => {
    if (query?.id) {
      // update form
      getDetail(query.id);
    } else {
      // add form
      form.setFieldsValue({ termsOfTrade: 'DAP' });
    }
  }, [])

  const onClickDelete = (values) => {
    confirm({
      title: 'Do you Want to delete this courier?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        const payload = { id: query.id, accountNumber };
        try {
          const res = await shipmentsApi.removeShippingAccount(payload);
          if (res.includes('Successfully')) {
            message.success(res);
            history.push(Path.COURIERS.pathname);
          }
        } catch (error) {
          message.error('Remove Shipping Account fail');
          console.error(error);
        } finally {
          return true;
        }
      },
    });
  };

  const onSave = async (values) => {
    try {
      setLoading(true);
      setAccountNumber(values.accountNumber);
      if (query.id) {
        const res = await shipmentsApi.updateShippingAccount({ ...values, id: query.id });
        if (res.id) {
          message.success('Update Courier success');
        }
      } else {
        const res = await shipmentsApi.addShippingAccount(values);
        if (res.shipping_account_id) {
          message.success('Add Courier success');
          history.push(Path.COURIERS.pathname);
        }
      }
    } catch (error) {
      message.error('Add Courier fail');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CourierFormStyle>
      <TitleHeader
        listOfBreadcrumb={[
          { name: "Home", path: "/" },
          Path.COURIERS,
          { name: `${query.id ? 'Update' : 'Create'} Courier Account` },
        ]}
        title={t(`${query.id ? 'Update' : 'Create'} Courier Account`)}
      />
      <Container style={{ backgroundColor: "#ececec" }}>
        <Spin spinning={init}>
          <Form
            wrapperCol={{ span: 24 }}
            layout="vertical"
            form={form}
            scrollToFirstError
            onFinish={onSave}
          >
            <Row gutter={[12, 12]}>
              <Col xs={24} md={20}>
                <GeneralAccountDetails />
                <AuthenticationDetails form={form} />
                <ShipmentDetails form={form} />
                <PrinterDetails />
              </Col>
              <Col xs={24} md={4} className="actions">
                <Affix
                  offsetTop={6}
                  target={() => document.getElementsByClassName("content-box")[0]}
                >
                  <Space direction="vertical">
                    <Button size="large" htmlType="submit" type="primary" loading={loading}>
                      Save
                    </Button>
                    {!!query?.id && (
                      <Button size="large" danger type="primary" onClick={onClickDelete}>
                        Delete
                      </Button>
                    )}
                  </Space>
                </Affix>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Container>
    </CourierFormStyle>
  );
};

export default CourierForm;

import React, { useState } from "react";
import AllTable from "./AllTable";
import Filter from "./Filter";

const AllPendingPurchases = ({
  params,
  setParams,
  isLoading,
  setIsLoading,
  dataList,
  setDataList,
  gettingListPending,
  loadingVariantImage,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [filter, setFilter] = useState({
    showAllPending: true,
    showPendingPurchasedForOrdersPlaces: true,
  });

  return (
    <>
      <Filter
        filter={filter}
        setFilter={setFilter}
        dataList={dataList}
        selectedRows={selectedRows}
        setIsLoading={setIsLoading}
        gettingListPending={gettingListPending}
        params={params}
        setParams={setParams}
      />
      <AllTable
        params={params}
        setParams={setParams}
        setSelectedRows={setSelectedRows}
        dataList={dataList}
        setDataList={setDataList}
        gettingListPending={gettingListPending}
        isLoading={isLoading}
        loadingVariantImage={loadingVariantImage}
      />
    </>
  );
};

export default AllPendingPurchases;

import {
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Typography,
} from "antd";
import Box from "components/box/Box";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";

import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Path from "../../commons/breadcrumbs";
import CreateVariantModal from "./CreateVariantModal";
import OrderSummary from "./OrderSummary";
import ProductDetails from "./ProductDetails";
import SalesFunnel from "./SalesFunnel";
import SenderDetails from "./SenderDetails";
import ordersApi from "../../apis/ordersApi";
import groupBy from "lodash.groupby";
import produce from "immer";

const { Title } = Typography;
const { Search } = Input;

const CreateOrder = ({ history }) => {
  const { t } = useTranslation();
  const [checkedValue, setCheckedValue] = useState({
    salesFunnel: "",
    senderAddress: "Harumio Store",
  });
  const [addedOrder, setAddedOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [variant, setVariant] = useState({});
  const [createNewProduct, setCreateNewProduct] = useState({
    title: "",
    vendor: "",
    type: "",
    tags: [],
    note: "",
    options: ["", ""],
    images: [],
    publishOnShopify: false,
    bodyHtml: "",
  });

  const [params, setParams] = useState({
    customerEmail: "",
    customerPhone: "",
    customerFirstName: "",
    financialStatus: "pending",
    priority: 1,
    sendToShopify: true,
    sendShopifyReceipt: true,
    lineItems: [],
  });

  const [isModal, setIsModal] = useState({
    addProductVariant: false,
  });

  const inputChangeHandler = useCallback((event) => {
    return setCreateNewProduct((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  }, []);

  const confirmedCreateOrder = async () => {
    const groupBaseOnVariantId = groupBy(addedOrder, (item) => item.id);

    // function to create line item
    for (const variantId in groupBaseOnVariantId) {
      params.lineItems.push({
        productVariantId: variantId,
        quantity: groupBaseOnVariantId[variantId].length,
        customProductName: "",
        customProductURL: "",
        customProductPrice: "",
        customProductCurrency: "",
      });
    }

    setIsLoading(true);
    console.log(JSON.stringify(params));
    try {
      const res = await ordersApi.createOrder(JSON.stringify(params));
      console.log("THIS IS RESPONSE FROM CREATE ORDER!", res);
      setIsLoading(false);
      message.success("Order was created", 3);
    } catch (error) {
      setIsLoading(false);
      message.error(error, 3);
    }
  };

  const inputHandler = (event) => {
    const data = produce(params, (draft) => {
      draft[event.target.name] = event.target.value;
    });
    return setParams(data);
  };

  return (
    <>
      <CreateVariantModal
        visible={isModal.addProductVariant}
        title="Add Variant"
        width={700}
        onCancel={() =>
          setIsModal((prev) => {
            return { ...prev, addProductVariant: false };
          })
        }
        okText="Add Product Variant"
        variant={variant}
        setVariant={setVariant}
      />
      <TitleHeader
        listOfBreadcrumb={[
          { name: t("Home"), path: "/" },
          { name: Path.ORDERS.name, path: Path.ORDERS.pathname },
          { name: Path.CREATE_ORDER.name },
        ]}
        title={t("Create Order")}
        currentHistory={history}
      />
      <Box>
        <Form layout="vertical">
          <Row gutter={24}>
            <Col className="left-row" md={16}>
              <Container>
                {/* Sales funnel  */}
                <SalesFunnel
                  setParams={setParams}
                  checkedValue={checkedValue}
                  setCheckedValue={setCheckedValue}
                />

                <SenderDetails />

                {/* Customer Details */}
                <Card style={{ marginBottom: "24px" }}>
                  <Title level={5}>{t("Customer Details")}</Title>
                  <Row gutter={8}>
                    <Col md={24}>
                      <Form.Item label="Search Customer">
                        <Search />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col md={8}>
                      <Form.Item label="Customer First Name">
                        <Input
                          name={"customerFirstName"}
                          value={params.customerFirstName}
                          onChange={inputHandler}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="Customer Phone">
                        <Input
                          name={"customerPhone"}
                          value={params.customerPhone}
                          onChange={inputHandler}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="Customer Email">
                        <Input
                          name={"customerEmail"}
                          value={params.customerEmail}
                          onChange={inputHandler}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>

                <ProductDetails
                  addedOrder={addedOrder}
                  setAddedOrder={setAddedOrder}
                  setIsModal={setIsModal}
                  setCreateNewProduct={setCreateNewProduct}
                  inputChangeHandler={inputChangeHandler}
                />

                <Divider />
              </Container>
            </Col>

            {/* Right column side start here */}
            <Col className="right-row" md={8}>
              <Container>
                <OrderSummary
                  params={params}
                  isLoading={isLoading}
                  salesFunnel={checkedValue.salesFunnel}
                  addedOrder={addedOrder}
                  setAddedOrder={setAddedOrder}
                  confirmedCreateOrder={confirmedCreateOrder}
                />
              </Container>
            </Col>
          </Row>
        </Form>
      </Box>
    </>
  );
};

export default CreateOrder;

import { Button, Space } from 'antd'
import styled from 'styled-components'
import Box from '../../components/box/Box'

export const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  background-color: #edf1f5;
  padding: 12px 24px;
  border-bottom: 1px solid #78828c21;
  h1 {
    font-size: 16px;
    text-transform: uppercase;
    color: #3e5569;
    font-weight: 600;
    margin-bottom: 0;
  }
`
export const OrdersPageStyled = styled(Box)`
  .table-box {
    .ant-table-wrapper {
      color: #3e5569;
      th.ant-table-cell {
        background-color: #fff;
      }
      .order-id a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .statistic {
    .ant-col:nth-child(1) {
      .ant-statistic-content-value-int, .ant-statistic-title {
        color: #707cd2;
      }
      .ant-card-body {
        background-color: #c6cdfd;
      }
    }
    .ant-col:nth-child(2) {
      .ant-statistic-content-value-int, .ant-statistic-title {
        color: #ffc36d;
      }
      .ant-card-body {
        background-color: #ffedd4;
      }
    }
    .ant-col:nth-child(3) {
      .ant-statistic-content-value-int, .ant-statistic-title {
        color: #2cd07e;
      }
      .ant-card-body {
        background-color: #b2ffd9;
      }
    }
    .ant-col:nth-child(4) {
      .ant-statistic-content-value-int, .ant-statistic-title {
        color: #ff5050;
      }
      .ant-card-body {
        background-color: #ffcaca;
      }
    }
    .ant-card {
      margin-bottom: 30px;
      transition: all .25s ease;
      border: 0 solid rgba(0,0,0,.125);
      &:hover {
        transform: translateY(-4px) scale(1.01);
        box-shadow: 0 14px 24px rgb(62 57 107 / 10%);
      }
    }
    .ant-card-body {
      padding: 8px;
      .ant-statistic {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        .ant-statistic-content-value-int {
          font-size: 36px;
          font-weight: normal;
        }
        .ant-statistic-title {
          font-size: 14px;
        }
      }
    }
  }
  
`

export const ActionBox = styled(Space)`
  &.action-box {
    button {
      min-width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      &.btn-update {
        border-color: #d9d9d9;
      }
    }
  }
`

export const PossibleButton = styled(Button)`
  min-width: 32px;
  height: 22px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
  padding: 0 7px;
  span {
    font-size: 12px;
    line-height: 20px;
  }
  &:hover, &:active, &:focus {
    border-color: #28a745;
    background-color: #28a745;
    color: #fff;
    opacity: 0.8;
  }
`

import { Form, Table } from 'antd'
import styled from 'styled-components'

export const FilterContainer = styled(Form)`
  padding: 16px 0;
`
export const TableContainer = styled(Table)`
  .product-title-cell {
    max-width: 600px;
  }
`

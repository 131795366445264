import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Table,
  Typography,
  Collapse,
  Spin,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import qs from "query-string";
import { Link, useParams, useHistory } from "react-router-dom";
import ordersApi from "../../apis/ordersApi";
import Path from "../../commons/breadcrumbs";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Container from "../../components/container/Container";
import { moment } from "../../utils/funcs";
import { OrderDetailPageStyled, TitleBox } from "./index.styles";
import { ORDER_FILTER_STATUS } from "../orders/SearchPanel/util";
import LinkButton from "components/linkButton/LinkButton";
import queryString from "query-string";

const { Title } = Typography;
const { Panel } = Collapse;

function OrderDetail() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState();
  const [orders, setOrders] = useState([]);
  const [combinedOrder, setCombinedOrder] = useState();
  const { id } = useParams();
  const history = useHistory();
  const [isModal, setIsModal] = useState(false);

  const fullFillOrder = async (fullFill) => {
    // fullFill: Bool
    setIsLoading(true);
    try {
      const payload = {
        orderId: order.id,
      };

      const data = {
        shopifyNotification: fullFill,
      };
      const res = await ordersApi.postFullFillOrder(
        payload,
        queryString.stringify(data)
      );
      setIsLoading(false);
      setIsModal(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsModal(false);
    }
  };

  const getDetail = async () => {
    if (!id) {
      return;
    }
    try {
      setIsLoading(true);
      let resCombineOrder = null;
      let orderItem = null;
      let combinedOrders = null;
      if (!qs.parse(history.location.search)?.combine) {
        const resOrder = await ordersApi.getOrderById({
          id,
          params: { listCombinedOrders: true },
        });
        if (!resOrder?.order?.id) {
          return;
        }
        orderItem = resOrder.order;
        combinedOrders = orderItem.combinedOrders;
        if (!Array.isArray(combinedOrders) || combinedOrders.length === 0) {
          setOrder(orderItem);
          return;
        }
        resCombineOrder = await ordersApi.getCombineOrders({
          filter: `subOrders_eq=${JSON.stringify(combinedOrders)}`,
        });

        if (
          !Array.isArray(resCombineOrder?.combinedOrders) ||
          resCombineOrder.combinedOrders?.length === 0
        ) {
          return;
        }
        setOrder(orderItem);
        setCombinedOrder(resCombineOrder.combinedOrders[0]);

        const resOrders = await Promise.all(
          combinedOrders.map((item) => {
            const payload = {
              id: item,
              params: {
                listCombinedOrders: true,
              },
            };
            ordersApi.getOrderById(payload);
          })
        );
        setOrders(resOrders.map((item) => item.order));
      } else {
        resCombineOrder = await ordersApi.getCombineOrders({
          filter: `id_eq=${id}`,
        });
        setCombinedOrder(resCombineOrder.combinedOrders[0]);
        combinedOrders = resCombineOrder.combinedOrders[0].subOrders;
        const resOrders = await Promise.all(
          combinedOrders.map((item) => {
            const payload = {
              id: item,
              params: { listCombinedOrders: true },
            };
            return ordersApi.getOrderById(payload);
          })
        );
        setOrders(Array(resOrders?.map((item) => item.combinedList)[0]));
        setOrder(resOrders[0]?.combinedList);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDetail();
  }, [id]);

  const columnsLineItems = [
    {
      title: "DETAILS",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <>
          <div className="bold">
            {record.productTitle || record.customProductName}
          </div>
          <div className="paragraph">{record.productVariantTitle}</div>
          <Space className="actions">
            <Button size="small" className="btn-success">
              Add to purchase
            </Button>
            <Button size="small" className="btn-info">
              Receive stock
            </Button>
            <Button size="small" className="btn-warning">
              Mark as fulfilled
            </Button>
          </Space>
        </>
      ),
    },
    {
      title: "QUANTITY",
      dataIndex: "quantity",
      key: "quantity",
      width: 120,
    },
    {
      title: "ALLOCATED",
      dataIndex: "allocatedQuantity",
      key: "allocatedQuantity",
      width: 120,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (value, record) => {
        return <strong>{value.toUpperCase()}</strong>;
      },
    },
    {
      title: "PRODUCT DETAIL",
      dataIndex: "id",
      key: "id",
      width: 160,
      render: (_, record) => {
        return (
          <LinkButton
            to={{
              pathname: "/products/product-detail",
              search: `?id=${record.productId}`,
            }}
            type="primary"
          >
            Product Details
          </LinkButton>
        );
      },
    },
  ];

  let totalPrice = order?.totalPrice;
  let note = order?.note ?? "----";

  if (combinedOrder?.id && Array.isArray(orders) && orders?.length > 0) {
    totalPrice = 0;
    orders.forEach((item) => {
      totalPrice += parseFloat(item.totalPrice);
    });
    note = orders.map((itemOrder) =>
      itemOrder.note ? <div key={itemOrder.id}>{itemOrder.note}</div> : ""
    );
    if (!note.join("")) {
      note = "----";
    }
  }

  return (
    <>
      <Modal
        visible={isModal}
        title="Confirmation"
        onCancel={() => setIsModal(false)}
        okText="Yes"
        footer={[
          <Button onClick={() => fullFillOrder(true)} type="primary">
            YES
          </Button>,
          <Button onClick={() => fullFillOrder(false)} type="danger">
            NO
          </Button>,
        ]}
      >
        <div style={{ marginTop: "20px" }}>Send Shopify Notification?</div>
      </Modal>
      {combinedOrder ? (
        <TitleBox className="title-page">
          <div>
            <Title>
              {t("Combined Order")} #{combinedOrder?.id}
            </Title>
            <Title level={2}>
              {order?.combinedOrders.map((item, index) => (
                <React.Fragment key={index}>
                  <Link to={Path.ORDER_DETAIL.pathParam(item)}>#{item}</Link>
                  {index + 1 < order.combinedOrders.length ? ", " : ""}
                </React.Fragment>
              ))}
            </Title>
          </div>
          <Breadcrumb
            items={[
              { name: "Home", path: "/" },
              Path.ORDERS,
              { name: `${t("Order")} #${combinedOrder?.id}` },
            ]}
          />
        </TitleBox>
      ) : (
        <TitleBox className="title-page">
          <Title>
            {t("Order")} {order?.orderNumber}
          </Title>
          <Breadcrumb
            items={[
              { name: "Home", path: "/" },
              Path.ORDERS,
              { name: `${t("Order")} ${order?.orderNumber || ""}` },
            ]}
          />
        </TitleBox>
      )}

      <OrderDetailPageStyled title={t("Order detail")}>
        <Container>
          <Spin spinning={isLoading}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={18}>
                <Card className="order-info">
                  <div className="order-details">
                    <Row className="detail-row">
                      <Col xs={24} md={6} className="box-data">
                        <div className="label">Order Number</div>
                        <div className="data bold">
                          {combinedOrder ? (
                            <>
                              <div>Combined Order: {combinedOrder?.id}</div>
                              <div>
                                Sub orders:{" "}
                                {order?.combinedOrders.map((item, index) => (
                                  <React.Fragment key={index}>
                                    {item}
                                    {index + 1 < order.combinedOrders.length
                                      ? ", "
                                      : ""}
                                  </React.Fragment>
                                ))}
                              </div>
                            </>
                          ) : (
                            <Title level={4}>
                              {t("Order")} {order?.orderNumber}
                            </Title>
                          )}
                        </div>
                      </Col>
                      <Col xs={24} md={6} className="box-data">
                        <div className="label">Email</div>
                        <div className="data">{order?.customerEmail}</div>
                      </Col>
                      <Col xs={24} md={6} className="box-data">
                        <div className="label">Total Price</div>
                        <div className="data">{totalPrice}</div>
                      </Col>
                      <Col xs={24} md={6} className="box-data">
                        <div className="label">
                          {order?.financialStatus === "paid"
                            ? "Financial Status"
                            : "Total outstanding"}
                        </div>
                        <div className="data uppercase">
                          {order?.financialStatus === "paid"
                            ? order?.financialStatus
                            : order?.totalOutstanding}
                        </div>
                      </Col>
                    </Row>
                    <Row className="detail-row">
                      <Col xs={24} md={6} className="box-data">
                        <div className="label">Status</div>
                        <div className="data uppercase">{order?.status}</div>
                      </Col>
                      <Col xs={24} md={6} className="box-data">
                        <div className="label">Shipping</div>
                        <div className="data">{order?.shopifyShippingLine}</div>
                      </Col>
                      <Col xs={24} md={6} className="box-data">
                        <div className="label">Created At</div>
                        <div className="data data-sm">
                          {moment(order?.createdAt).format(
                            "DD MMM YYYY, hh:MM a"
                          )}
                        </div>
                      </Col>
                      <Col xs={24} md={6} className="box-data">
                        <div className="label">Processed At</div>
                        <div className="data data-sm">
                          {moment(order?.updatedAt).format(
                            "DD MMM YYYY, hh:MM a"
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row className="billing-shipping-info">
                    <Col xs={24} md={12} className="box-data">
                      <div className="label">Billing Info:</div>
                      <div className="data address">
                        <span className="bold">
                          {`${order?.billingFirstName || ""} ${
                            order?.billingLastName || ""
                          }`}
                        </span>
                        <br />
                        Phone: {order?.billingPhone}
                        <br />
                        Zip: {order?.billingAddressZip}
                        <br />
                        {[
                          order?.billingAddressLineOne,
                          order?.billingAddressLineTwo,
                          order?.billingAddressCity,
                          order?.billingAddressProvince,
                          order?.billingAddressCountry,
                        ]
                          .filter((item) => item)
                          .join(", ")}
                      </div>
                    </Col>
                    <Col xs={24} md={12} className="box-data">
                      <div className="label">Shipping Info:</div>
                      <div className="data address">
                        <span className="bold">
                          {`${order?.addressFirstName || ""} ${
                            order?.addressLastName || ""
                          }`}
                        </span>
                        <br />
                        Phone: {order?.addressPhone}
                        <br />
                        Zip: {order?.addressZip}
                        <br />
                        {[
                          order?.addressLineOne,
                          order?.addressLineTwo,
                          order?.addressCity,
                          order?.addressProvince,
                          order?.addressCountry,
                        ]
                          .filter((item) => item)
                          .join(", ")}
                      </div>
                    </Col>
                    <Col xs={24} md={6} className="box-data">
                      <div className="label">Note:</div>
                      <div className="data">{note}</div>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col xs={24} md={6}>
                <Space direction="vertical" className="order-actions">
                  {order?.trackingNumber ? (
                    <Row gutter={[6, 6]}>
                      <Col xs={24}>
                        <div className="label">Airway Bill Number:</div>
                        <div className="data">{order?.trackingNumber}</div>
                      </Col>
                      {/* <Button
                        type="primary"
                        size="large"
                        className="download-invoice"
                      >
                        Download PDF invoice
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        className="download-label"
                      >
                        Download PDF label
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        className="preview-label"
                      >
                        Preview Label
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        className="reprint-label"
                        disabled
                      >
                        Reprint Label
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        className="view-shipment"
                      >
                        View Shipment Label Photos
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        className="clone-shipment border-warning bg-warning"
                        disabled
                      >
                        Clone Shipment details
                      </Button> */}
                    </Row>
                  ) : (
                    (order?.status ===
                      ORDER_FILTER_STATUS[`ready to pack`].value ||
                      order?.status ===
                        ORDER_FILTER_STATUS.allocated.value) && (
                      <Button
                        href="#"
                        type="primary"
                        size="large"
                        className="create-shipment"
                      >
                        Create Shipment
                      </Button>
                    )
                  )}

                  <form method="post" action="#">
                    <input
                      type="hidden"
                      name="csrfmiddlewaretoken"
                      value="2EA46FwyXggMXkM93cCbD411kNymoQvJ7Pl67a9K55a3Nawc7J1gX0udEcHUjNKp"
                    />
                    <Button
                      className="close-order"
                      htmlType="submit"
                      danger
                      type="primary"
                      size="large"
                    >
                      Close Order
                    </Button>
                    <Button
                      onClick={() => setIsModal(true)}
                      type="primary"
                      size="large"
                      style={{ marginTop: "15px" }}
                    >
                      Fulfil Order On Shopify
                    </Button>
                    <LinkButton
                      type="primary"
                      size="large"
                      style={{ marginTop: "15px" }}
                      to={{
                        pathname: "/create-shipment",
                        search: `?orderId=${id}`,
                      }}
                    >
                      Create Shipment
                    </LinkButton>
                  </form>
                </Space>
              </Col>
              <Col xs={24}>
                {combinedOrder?.id &&
                  Array.isArray(orders) &&
                  orders?.map((item, index) => (
                    <Collapse
                      defaultActiveKey={["1"]}
                      key={index}
                      className="collapse-line-items"
                    >
                      <Panel header={`Line Items #${item?.id}`} key="1">
                        <Table
                          pagination={false}
                          columns={columnsLineItems}
                          dataSource={item?.lineItems || []}
                          rowKey="id"
                          size="small"
                          scroll={{
                            x: "max-content",
                          }}
                        />
                      </Panel>
                    </Collapse>
                  ))}
                {!combinedOrder?.id && (
                  <Card title="Line Items" className="line-items">
                    <Table
                      pagination={false}
                      columns={columnsLineItems}
                      dataSource={order?.lineItems || []}
                      rowKey="id"
                      size="small"
                      scroll={{
                        x: "max-content",
                      }}
                    />
                  </Card>
                )}
              </Col>
            </Row>
          </Spin>
        </Container>
      </OrderDetailPageStyled>
    </>
  );
}

export default OrderDetail;

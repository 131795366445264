import { Card, Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const { Option } = Select;

const StoreCurrency = ({ setStoreDetail }) => {
  const { currency } = useSelector((state) => state.common);
  const [storeCurrency, setStoreCurrency] = useState({
    currency,
  });

  useEffect(() => {
    setStoreDetail((prev) => {
      return { ...prev, storeCurrency };
    });
  }, [storeCurrency]);

  return (
    <Card title="Store Currency">
      <Form layout="vertical">
        <Row gutter={16}>
          <Col md={24}>
            <p>
              This is the currency your products are sold in. After your first
              sale, currency is locked in and can’t be changed. To change your
              payout currency, go to payments settings. Learn more about your
              store currency
            </p>
          </Col>
          <Col md={24}>
            <Form.Item label="Default Currency">
              <Select
                style={{ marginBottom: "none" }}
                defaultValue={storeCurrency.currency}
                onChange={(value) => {
                  return setStoreCurrency((prev) => {
                    return { ...prev, currency: value };
                  });
                }}
              >
                <Option value="AUD">Australian Dollar (AUD)</Option>
                <Option value="BRL">Brazilian Real (BRL)</Option>
                <Option value="GBP">British Pound Sterline (GBP)</Option>
                <Option value="BGN">Bulgarian Lev (BGN)</Option>
                <Option value="CAD">Canadian Dollar (CAD)</Option>
                <Option value="CNY">Chinese Yuan Renminbi (CNY)</Option>
                <Option value="HRK">Croatian Kuna (HRK)</Option>
                <Option value="CZK">Czech Koruna (CZK)</Option>
                <Option value="DKK">Danish Krone (DKK)</Option>
                <Option value="EUR">Euro (EUR)</Option>
                <Option value="HKD">Hong Kong Dollar (HKD)</Option>
                <Option value="HUF">Hungarian Forint (HUF)</Option>
                <Option value="ISK">Icelandic Krona (ISK)</Option>
                <Option value="IDR">Indonesian Rupiah (IDR)</Option>
                <Option value="INR">Indian Rupee (INR)</Option>
                <Option value="ILS">Israeli Shekel (ILS)</Option>
                <Option value="JPY">Japanese Yen (JPY)</Option>
                <Option value="MYR">Malaysian Ringgit (MYR)</Option>
                <Option value="MXN">Mexican Peso (MXN)</Option>
                <Option value="NZD">New Zealand Dollar (NZD)</Option>
                <Option value="NOK">Norwegian Krone (NOK)</Option>
                <Option value="PHP">Philippine Peso (PHP)</Option>
                <Option value="PLN">Polish Zloty (PLN)</Option>
                <Option value="RON">Romanian Leu (RON)</Option>
                <Option value="RUB">Russian Rouble (RUB)</Option>
                <Option value="SGD">Singapore Dollar (SGD)</Option>
                <Option value="ZAR">South African Rand (ZAR)</Option>
                <Option value="KRW">South Korean Won (KRW)</Option>
                <Option value="SEK">Swedish Krona (SEK)</Option>
                <Option value="CHF">Swiss Franc (CHF)</Option>
                <Option value="THB">Thai Baht (THB)</Option>
                <Option value="TRY">Turkish Lira (TRY)</Option>
                <Option value="USD">US Dollar (USD)</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default StoreCurrency;

import { Space, Button } from "antd";
import styled from "styled-components";
import Box from "../../components/box/Box";

export const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  background-color: #edf1f5;
  padding: 12px 24px;
  border-bottom: 1px solid #78828c21;
  h1 {
    font-size: 16px;
    text-transform: uppercase;
    color: #3e5569;
    font-weight: 600;
    margin-bottom: 0;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  background-color: white;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 7px;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background: #f9f9f9;
  }

  &::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 10px;
  }
`;

export const InnerHeader = styled.div`
  display: flex;
  width: 1000px;
`;

export const ShipCard = styled.div`
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: rgba(209, 253, 202, 0.2);
  box-shadow: 0px 4px 4px rgba(51, 96, 35, 0.25);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
`;

export const CardCol = styled.div`
  display: flex;
  div {
    margin-right: 10px;
  }
  p {
    margin-bottom: 0 !important;
  }
`;

export const Tag = styled.div`
  border-radius: 20px;
  padding: 0 5px;
  border: 3px solid
    ${(props) =>
      props.color === "completed"
        ? "#41D874"
        : props.color === "pending"
        ? "#4150D8"
        : "red"};
  color: ${(props) =>
    props.color === "completed"
      ? "#41D874"
      : props.color === "pending"
      ? "#4150D8"
      : "red"};
`;

export const ShipmentPageStyled = styled(Box)`
  .statistic {
    .ant-col:nth-child(1) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #707cd2;
      }
      .ant-card-body {
        background-color: #c6cdfd;
      }
    }
    .ant-col:nth-child(2) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #ffc36d;
      }
      .ant-card-body {
        background-color: #ffedd4;
      }
    }
    .ant-col:nth-child(3) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #2cd07e;
      }
      .ant-card-body {
        background-color: #b2ffd9;
      }
    }
    .ant-col:nth-child(4) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #ff5050;
      }
      .ant-card-body {
        background-color: #ffcaca;
      }
    }
    .ant-col:nth-child(5) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #707cd2;
      }
      .ant-card-body {
        background-color: #c6cdfd;
      }
    }
    .ant-col:nth-child(6) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #ffc36d;
      }
      .ant-card-body {
        background-color: #ffedd4;
      }
    }
    .ant-col:nth-child(7) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #2cd07e;
      }
      .ant-card-body {
        background-color: #b2ffd9;
      }
    }
    .ant-col:nth-child(8) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #ff5050;
      }
      .ant-card-body {
        background-color: #ffcaca;
      }
    }
    .ant-col:nth-child(9) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #ff5050;
      }
      .ant-card-body {
        background-color: #ffcaca;
      }
    }
    .ant-card {
      margin-right: 20px;
      transition: all 0.25s ease;
      border: 0 solid rgba(0, 0, 0, 0.125);
      &:hover {
        transform: translateY(-4px) scale(1.01);
        box-shadow: 0 14px 24px rgb(62 57 107 / 10%);
      }
    }
    .ant-card-body {
      padding: 8px;
      .ant-statistic {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        .ant-statistic-content-value-int {
          font-size: 36px;
          font-weight: normal;
        }
        .ant-statistic-title {
          font-size: 14px;
        }
      }
    }
  }
`;

export const ActionBox = styled(Space)`
  &.action-box {
    button {
      min-width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      &.btn-update {
        border-color: #d9d9d9;
      }
    }
  }
`;
export const DownloadButton = styled.a`
  padding: 6.5px 14px;
  border-radius: 2px;
  color: white;
  background: #2babe3;
`;

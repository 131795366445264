import { Button, Card, Checkbox, DatePicker, Form, Input, Space } from "antd";
import moment from "moment";
import React from "react";

const { RangePicker } = DatePicker;

const ShipmentFilterForm = ({
  setParams,
  form,
  isLoading,
  setIsLoading,
  setParam,
}) => {
  const onFinish = (value) => {
    const courier = [];

    if (Array.isArray(value.courier)) {
      value.courier.map((item) => courier.push(`courier_eq=${item}`));
    } else if (value.courier !== undefined) {
      courier.push(`courier_eq=${value.courier}`);
    }
    setParams((prev) => {
      return {
        ...prev,
        search: value.searchFor,
        courier,
        createdAt: value.createdAt,
      };
    });
  };

  return (
    <Card>
      <h1>Shipment Filters</h1>
      <Form layout={"vertical"} form={form} onFinish={onFinish}>
        <Form.Item
          name="searchFor"
          label="Search for:"
          extra="Press Enter For Search"
        >
          <Input placeholder="Airway Bill Number/ Order Number/ Email" />
        </Form.Item>
        <Form.Item name="createdAt" label="Show Shipments Created">
          <RangePicker
            ranges={{
              Today: [moment(), moment()],
              Yesterday: [
                moment().subtract(1, "day"),
                moment().subtract(1, "day"),
              ],
              "Last 7 days": [moment().startOf("week"), moment().endOf("week")],
              "This Week": [moment().startOf("week"), moment().endOf("week")],
              "This Month": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
            }}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item name="courier" label="Courier">
          <Checkbox.Group
            options={[
              { label: "DHL", value: "dhl" },
              { label: "FedEx", value: "fedex" },
              { label: "Rincos", value: "rincos" },
            ]}
          >
            <Checkbox />
          </Checkbox.Group>
        </Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Apply Filter
          </Button>
        </Space>
      </Form>
    </Card>
  );
};

export default ShipmentFilterForm;

import i18n from 'i18next'
import LngDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import LocalStorage from '../utils/storage'
import en from './en.json'
import kr from './kr.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LngDetector)
  .init({
    resources: {
      en: {
        translations: en,
      },
      kr: {
        translations: kr,
      },
    },
    lng:
      LocalStorage.has('lang') && LocalStorage.get('lang') === 'en' ? 'en' : '',
    fallbackLng: ['en'],
    debug: false,
    ns: ['translations', 'signup', 'createService'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  })

export default i18n

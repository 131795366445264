import { ShoppingCartOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import React from "react";
import Breadcrumbs from "./breadcrumbs";

export const menu = [
  {
    name: "Orders",
    key: "orders",
    permission: ["orders"],
    icon: <ShoppingCartOutlined />,
    url: Breadcrumbs.ORDERS.pathname,
  },
  {
    name: "Shipments",
    key: "shipments",
    permission: ["shipments"],
    icon: <Icon icon="mdi:truck-delivery" />,
    url: Breadcrumbs.SHIPMENTS.pathname,
    children: [
      // {
      //   url: Breadcrumbs.READY_TO_SHIP.pathname,
      //   name: 'Ready To Ship',
      //   permission: ['readyToShip'],
      //   boldName: false,
      // },
      {
        url: Breadcrumbs.CREATE_SHIPMENT.pathname,
        name: "Create Shipment",
        permission: ["createShipment"],
        boldName: false,
      },
      {
        url: Breadcrumbs.RATE_SHIPMENT.pathname,
        name: "Rate Shipment",
        permission: ["rateShipment"],
        boldName: false,
      },
      // {
      //   url: Breadcrumbs.TODAYS_SHIPMENT.pathname,
      //   name: "Today's Shipment",
      //   permission: ['todayShipment'],
      //   boldName: false,
      // },
      {
        url: Breadcrumbs.SHIPMENTS.pathname,
        name: "All Shipments",
        permission: ["allShipments"],
        boldName: false,
      },
      {
        url: Breadcrumbs.SHIPPER_DETAILS.pathname,
        name: "Shipper Details",
        permission: ["shipperDetails"],
        boldName: false,
      },
      // {
      //   url: Breadcrumbs.COURIER_LIST.pathname,
      //   name: 'Courier List',
      //   permission: ['courierList'],
      //   boldName: false,
      // },
    ],
  },
  {
    name: "Products",
    key: "products",
    permission: ["products"],
    icon: <Icon icon="carbon:product" />,
    children: [
      {
        url: Breadcrumbs.PRODUCTS.pathname,
        name: "Products",
        boldName: false,
      },
      // {
      //   url: Breadcrumbs.CREATE_PRODUCT.pathname,
      //   name: "Create Product",
      //   boldName: false,
      // },
    ],
  },
  // {
  //   name: "Inventory",
  //   key: "inventory",
  //   permission: [],
  //   icon: <Icon icon="carbon:inventory-management" />,
  //   url: Breadcrumbs.INVENTORY.pathname,
  // },
  {
    name: "Purchases",
    key: "purchases",
    permission: [],
    icon: <Icon icon="clarity:dollar-line" />,
    url: Breadcrumbs.PURCHASES.pathname,
    children: [
      {
        url: Breadcrumbs.PENDING_PURCHASE.pathname,
        name: "Pending Purchases",
        boldName: false,
      },
      {
        url: Breadcrumbs.PURCHASE_ORDERS.pathname,
        name: "Purchase Orders",
        boldName: false,
      },
      // {
      //   url: Breadcrumbs.SUPPLIER_LIST.pathname,
      //   name: "Supplier List",
      //   boldName: false,
      // },
      // {
      //   url: Breadcrumbs.PROOF_OF_PURCHASE.pathname,
      //   name: "Proof of Purchase",
      //   boldName: false,
      // },
    ],
  },
  {
    name: "Settings",
    key: "settings",
    permission: ["settings"],
    icon: <Icon icon="icon-park-outline:setting-config" />,
    url: Breadcrumbs.SETTINGS.pathname,
    children: [
      {
        url: Breadcrumbs.STORE_DETAILS.pathname,
        name: "Store Details",
        permission: ["storeDetails"],
        boldName: false,
      },
      {
        url: Breadcrumbs.USERS_AND_PERMISIONS.pathname,
        name: "Users And Permissions",
        permission: ["usersAndPermissions"],
        boldName: false,
      },
      {
        url: Breadcrumbs.COURIERS.pathname,
        name: "Courier Accounts",
        permission: ["usersAndPermissions"],
        boldName: false,
      },
      // {
      //   url: Breadcrumbs.SALES_AND_CHANNELS.pathname,
      //   name: "Sales And Channels",
      //   permission: ["salesAndChannels"],
      //   boldName: false,
      // },
      {
        url: Breadcrumbs.LANGUAGES.pathname,
        name: "Languages",
        permission: ["languages"],
        boldName: false,
      },
      {
        url: Breadcrumbs.POLICIES.pathname,
        name: "Policies",
        permission: ["policies"],
        boldName: false,
      },
    ],
  },
];

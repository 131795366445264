import { Layout, Menu } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { menu } from '../../commons/menu'
import { check2ArrayPermission } from '../../utils/funcs'
import { BoxChildSubMenu, BoxSideBar } from './SideBar.styles'

const { SubMenu } = Menu
const { Sider } = Layout

function SideBar() {
  const { t } = useTranslation()
  const { permissionKey } = useSelector((state) => state.account)
  const { sideBarMobile } = useSelector((state) => state.common)

  const location = useLocation()
  console.log(location)
  return (
    <BoxSideBar
      className={classNames({
        close: sideBarMobile,
      })}
    >
      <div className='icon__togged'>
        <div className='short'>Ware</div>
        <div className='full'>Ware Harum</div>
      </div>
      <Sider theme='light' className='sidebar'>
        <Menu
          mode='inline'
          selectedKeys={[location.pathname]}
          defaultOpenKeys={['registration']}
          theme='light'
          className='menu-component'
        >
          {menu.map((item) =>
            item.children ? (
              <SubMenu
                key={item.key}
                title={t(item.name)}
                icon={item.icon}
                disabled={
                  !check2ArrayPermission(permissionKey, item.permission)
                }
              >
                {item.children?.map((itemChild) => (
                  <Menu.Item
                    key={itemChild.url}
                    disabled={
                      !check2ArrayPermission(permissionKey, item.permission)
                    }
                  >
                    <NavLink to={itemChild.url}>
                      <BoxChildSubMenu>
                        {itemChild?.boldName && (
                          <div className='step'>
                            <b>{itemChild.boldName}</b>
                          </div>
                        )}
                        <span className='name-step'>{t(itemChild.name)}</span>
                      </BoxChildSubMenu>
                    </NavLink>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={item.url} icon={item.icon}>
                <NavLink to={item.url}>{t(item.name)}</NavLink>
              </Menu.Item>
            )
          )}
        </Menu>
      </Sider>
    </BoxSideBar>
  )
}

export default SideBar

import { Carousel, Col, Divider, List, message, Modal, Row } from "antd";
import purchaseApi from "apis/purchaseApi";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import LinkButton from "components/linkButton/LinkButton";

// this modal will display similar information like make purchase does
// so we need to fetch specific purchase-order/{id}

const ModalPurchaseOrder = ({
  isModal,
  onOkHandler,
  onCancelHandler,
  orderNumber,
  proofOfPurchase,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [purchaseItem, setPurchaseItem] = useState({
    itemPurchased: [],
    addItem: [],
  });

  const getPurchaseOrder = () => {
    setIsLoading(true);
    purchaseApi
      .getPurchaseOrder(orderNumber)
      .then(({ purchaseOrderDetails }) => {
        setIsLoading(false);
        setPurchaseOrder(() => purchaseOrderDetails);

        message.success("Succefully fetch data", 1);
      })
      .catch((error) => {
        setIsLoading(false);
        message.error(error, 5);
      });
  };

  const distributePurchaseItem = () => {
    if (!!purchaseOrder.purchaseItems) {
      purchaseOrder.purchaseItems.map((item) => {
        if (!!item.proofOfPurchase) {
          return setPurchaseItem((purchaseItem) => {
            if (purchaseItem.itemPurchased)
              return {
                ...purchaseItem,
                itemPurchased: [...purchaseItem.itemPurchased, item],
              };
          });
        } else {
          return setPurchaseItem((purchaseItem) => {
            return {
              ...purchaseItem,
              addItem: [...purchaseItem.addItem, item],
            };
          });
        }
      });
    }
  };

  const removeItem = (item) => {
    setPurchaseItem((prev) => {
      const newItemPurchased = prev.itemPurchased.filter((obj) => obj !== item);
      return { ...prev, itemPurchased: newItemPurchased };
    });
    setPurchaseItem((prev) => {
      const newAddItem = [...prev.addItem, item];
      return { ...prev, addItem: newAddItem };
    });
  };

  const addItem = (item) => {
    setPurchaseItem((prev) => {
      const newAddItem = prev.addItem.filter((obj) => obj !== item);
      return { ...prev, addItem: newAddItem };
    });
    setPurchaseItem((prev) => {
      const newItemPurchased = [...prev.itemPurchased, item];
      return { ...prev, itemPurchased: newItemPurchased };
    });
  };

  const purchaseMadeTotal = (purchase) => {
    return purchase
      .map((item) => item.purchasedQty)
      .reduce((prev, curr) => prev + curr);
  };

  useEffect(() => {
    getPurchaseOrder();
  }, []);

  useEffect(() => {
    distributePurchaseItem();
  }, [purchaseOrder]);

  console.log(
    "PO",
    purchaseOrder.purchaseItems?.map((item) => item.purchasedQty)
  );
  console.log("POP", proofOfPurchase);
  console.log("PURCHASE ITEM", purchaseOrder);
  // console.log(!!purchaseOrder?.purchaseItems.proofOfPurchase);
  // console.log("PURCHASE ORDER", purchaseOrder.purchaseItems);

  return (
    <Modal
      title="Purchase Order"
      visible={isModal}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      width={1000}
    >
      <Row gutter={16}>
        <Col md={12}>
          {proofOfPurchase?.length ? (
            <Carousel>
              {proofOfPurchase.map((popImage, index) => (
                <img
                  key={index}
                  title={popImage.fileName}
                  src={popImage.fileUrl}
                  style={{
                    width: "100%",
                    height: "100%",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                  alt={popImage.fileName}
                />
              ))}
            </Carousel>
          ) : (
            <NoImage>
              <SearchOutlined height={500} />
              "Please go back to Make Purchase Page to upload the image.."
              <LinkButton
                to={`/purchases/pending-purchases/make-purchase?purchaseOrderId=${purchaseOrder.orderNumber}`}
                type="primary"
              >
                Make Purchase
              </LinkButton>
            </NoImage>
          )}
        </Col>
        <Col md={12}>
          <Row>
            <List
              loading={isLoading}
              style={{ width: "100%" }}
              header={<div className="bold">Item Purchased</div>}
              dataSource={purchaseItem.itemPurchased}
              renderItem={(item) => (
                <List.Item>
                  <div
                    style={{
                      width: "100px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.variantName}
                  </div>
                  <div>{item.purchasedQty}</div>
                  {!!item.proofOfPurchase ? (
                    <SavedTag>SAVED</SavedTag>
                  ) : (
                    <CloseCircleTwoTone onClick={() => removeItem(item)} />
                  )}
                </List.Item>
              )}
            />
          </Row>
          <Row md={24}>
            <List
              loading={isLoading}
              style={{ width: "100%" }}
              header={<div className="bold">Add Item</div>}
              dataSource={purchaseItem.addItem}
              renderItem={(item) => (
                <List.Item>
                  <div
                    style={{
                      width: "100px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.variantName}
                  </div>
                  <div>{item.purchasedQty}</div>
                  <CheckCircleTwoTone
                    twoToneColor="#5beb2f"
                    onClick={() => addItem(item)}
                  />
                </List.Item>
              )}
            />
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col>
          <h1>Purchase Order Details</h1>
          <DetailList>
            <li>
              <span className="bold">Purchase Made: </span>
              <span className="grey-text">
                {purchaseOrder.purchaseItems?.length
                  ? purchaseMadeTotal(purchaseOrder.purchaseItems) + " Items"
                  : "0"}
              </span>
            </li>
            <li>
              <span className="bold">Purchaser: </span>
              <span className="grey-text">{purchaseOrder.purchaser}</span>
            </li>
          </DetailList>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalPurchaseOrder;

ModalPurchaseOrder.propTypes = {
  orderNumber: PropTypes.number,
};

const NoImage = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SavedTag = styled.div`
  border: 1px solid #003cff;
  color: #003cff;
  border-radius: 3px;
  padding: 3px 10px;
`;

const DetailList = styled.ul`
  .bold {
    font-weight: 600;
  }
  .grey-text {
    color: #3c3c3c;
  }
`;

import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd'
import Box from 'components/box/Box'
import Container from 'components/container/Container'
import TitleHeader from 'components/title-header'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Path from '../../../commons/breadcrumbs'
import sampleUserData from '../sampleUserData.json'

const { TextArea } = Input
const { Paragraph } = Typography

const generalOptions = [
  'orders',
  'orderDetails',
  'shipments',
  'readyToShip',
  'createShipment',
  'todayShipment',
  'allShipments',
  'courierList',
  'products',
  'productDetail',
  'settings',
  'storeDetails',
  'usersAndPermissions',
  'salesAndChannels',
  'languages',
  'policies',
  'addStaff',
  'editStaff',
]

const EditStaffWithId = ({ history, location, match }) => {
  const { firstName, lastName, email, isOwner } = location.state
  const [avatarImage, setAvatarImage] = useState(null)
  const [checkAll, setCheckAll] = useState(false)
  const [indeterminate, setIndeterminate] = useState(true)
  const [isModal, setIsModal] = useState(false)
  const [currentUser, setCurrentUser] = useState({})
  const [checkedList, setCheckedList] = useState([])

  useEffect(() => {
    // effects
    const response = sampleUserData.users.find(
      (user) => String(user.id) === String(match.params.id)
    )
    setCheckedList(response.permissionsPage)
    setCurrentUser(response)
  }, [match])

  // onChange for Checkbox.Group produce checkedValue
  const onChangeHandler = (checkedValue) => {
    setCheckedList(checkedValue)
    setIndeterminate(
      !!checkedValue.length && checkedValue.length < generalOptions
    )
    setCheckAll(checkedValue.length === generalOptions.length)
  }

  const onChangeSelectAllHandler = (event) => {
    setCheckedList(event.target.checked ? generalOptions : [])
    setIndeterminate(false)
    setCheckAll(event.target.checked)
  }

  const redirect = () => {
    const path = '/settings/users-and-permissions'
    history.push(path)
  }

  const handleOk = () => {
    // handleOk modal here
    setIsModal(false)
    message.success(`Succesfully deleted ${firstName}`, 2)
    redirect()
  }

  const handleCancel = () => {
    // handleOk modal here
    setIsModal(false)
  }

  const removeModalHandler = () => {
    setIsModal(true)
  }

  const saveHandler = () => {
    // when people click save handler
    // PUT all bio, personal website and checked permission to user-endpoint
    message.info('All information has been saved', 2)
    redirect()
  }

  const discardHandler = () => {
    // when people click save handler
    // PUT all bio, personal website and checked permission to user-endpoint
    message.warning('Discarded', 2)
    redirect()
  }

  console.log(currentUser)

  return (
    <div>
      <Modal
        title={`Remove ${firstName}`}
        visible={isModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Yes, I'm Sure"}
        okType='danger'
      >
        <p
          style={{ marginTop: '25px' }}
        >{`Are you sure want to remove ${firstName} ?`}</p>
      </Modal>
      <TitleHeader
        currentHistory={history}
        listOfBreadcrumb={[
          { name: 'Home', path: '/' },
          { name: Path.SETTINGS.name, path: Path.SETTINGS.pathname },
          {
            name: Path.USERS_AND_PERMISIONS.name,
            path: Path.USERS_AND_PERMISIONS.pathname,
          },
          { name: Path.EDIT_STAFF_WITH_ID.name },
        ]}
        title={location.state.firstName}
      />
      <Box>
        <Container>
          <Row>
            <Space>
              <Avatar
                size='large'
                style={{
                  border: '1px solid black',
                }}
                src={
                  avatarImage
                    ? avatarImage
                    : `https://ui-avatars.com/api/?name=${firstName}+${
                        lastName ? lastName : ''
                      }`
                }
              />
              <div>
                <div>{firstName}</div>
                <div>{email}</div>
              </div>
            </Space>
          </Row>
          <Card style={{ marginTop: '24px' }} title='Details'>
            <Form layout='vertical'>
              <Form.Item label='Bio (optional)'>
                <TextArea showCount maxLength={100} />
              </Form.Item>
              <Form.Item label='Personal website address (optional)'>
                <Input type='text' />
              </Form.Item>
            </Form>
          </Card>
          <Card style={{ marginTop: '24px' }} title='Permissions'>
            <Form layout='vertical'>
              <GridCheckboxSpace>
                <Checkbox
                  checked={checkAll}
                  onChange={onChangeSelectAllHandler}
                  indeterminate={indeterminate}
                >
                  Select All
                </Checkbox>
              </GridCheckboxSpace>
              <GridCheckboxSpace>
                <Form.Item label='General'>
                  <Checkbox.Group
                    options={generalOptions}
                    value={checkedList}
                    onChange={onChangeHandler}
                  />
                </Form.Item>
              </GridCheckboxSpace>
            </Form>
          </Card>

          {/* 
            REMOVE CARD
            only available for limited user not store owner
          */}
          {!isOwner && (
            <Card style={{ marginTop: '24px' }} title={`Remove ${firstName}`}>
              <Space direction='vertical'>
                <Paragraph>
                  Removed staff members will be permanently removed from your
                  store. This action cannot be reversed.
                </Paragraph>
                <Button danger onClick={removeModalHandler}>
                  Remove {firstName}
                </Button>
              </Space>
            </Card>
          )}
          <Divider />
          <Space align='end'>
            <Button type='primary' onClick={saveHandler}>
              Save
            </Button>
            <Button type='danger' onClick={discardHandler}>
              Discard
            </Button>
          </Space>
        </Container>
      </Box>
    </div>
  )
}

export default EditStaffWithId

const GridCheckboxSpace = styled(Row)`
  .ant-checkbox-wrapper,
  .ant-checkbox-group-item {
    /* border: 1px solid black; */
    margin-bottom: 10px;
  }
`

import { Typography } from 'antd'
import FourZeroFour from 'components/svg/FourZeroFour'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MiddleBox } from './index.style'

function NotFound() {
  const { t } = useTranslation()
  return (
    <MiddleBox>
      <Typography.Title>{t('Are you lost?')}</Typography.Title>
      <Typography.Paragraph>
        {t(
          "Sorry we couldn't find a page that you are looking for. Or maybe you are NOT ALLOWED by admin to be here."
        )}
      </Typography.Paragraph>
      <FourZeroFour />
    </MiddleBox>
  )
}

export default NotFound

import React, { useEffect, useState, createRef, useMemo } from "react";
import { Table, Input, Button, DatePicker, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment, { isMoment } from "moment";
import { get } from "lodash";

const { RangePicker } = DatePicker;

const filterCaseInsensitive = (recordValue, query) =>
  recordValue
    ? recordValue?.toString().toLowerCase().includes(query?.toLowerCase())
    : "";

const filterTable = ({ value, recordValue, type }) => {
  let filtered = true;
  switch (type) {
    case "text":
      filtered = filterCaseInsensitive(recordValue, value);
      break;
    case "date": {
      const recordValueTime = moment(recordValue).unix();
      if (!recordValue || !isMoment(moment(recordValue))) {
        filtered = false;
      } else {
        if (isMoment(value?.min)) {
          filtered =
            value.min?.clone().startOf("date").unix() <= recordValueTime;
        }
        if (isMoment(value?.max)) {
          filtered =
            filtered &&
            recordValueTime <= value.max?.clone().endOf("date").unix();
        }
      }
      break;
    }
    default:
      break;
  }
  return filtered;
};

const FilterTable = ({ columns, ...props }) => {
  const [searchRefs, setSearchRefs] = useState([]);

  const getColumnSearchProps = ({
    dataIndex,
    searchInputRef,
    type = "text",
    getValue,
  }) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      getValue,
    }) => (
      <Row
        gutter={[8, 12]}
        style={{ width: type === "min-max" ? 318 : 260, padding: 12 }}
        justify="end"
      >
        <Col xs={24}>
          {type === "text" && (
            <Input
              ref={searchInputRef}
              placeholder="Type and Enter to search"
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => confirm()}
              style={{ marginBottom: 8, display: "block" }}
            />
          )}
          {type === "date" && (
            <RangePicker
              allowClear={false}
              onChange={(value) =>
                setSelectedKeys([{ min: value[0], max: value[1] }])
              }
              value={[selectedKeys[0]?.min, selectedKeys[0]?.max]}
              ranges={{
                Today: [moment(), moment()],
                Yesterday: [
                  moment().subtract(1, "day"),
                  moment().subtract(1, "day"),
                ],
                "Last 7 days": [
                  moment().startOf("week"),
                  moment().endOf("week"),
                ],
                "This Week": [moment().startOf("week"), moment().endOf("week")],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
              ref={searchInputRef}
            />
          )}
        </Col>
        <Col xs>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 80 }}
          />
        </Col>
        <Col xs>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 80 }}
          >
            Reset
          </Button>
        </Col>
        <Col xs>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
            }}
          >
            Filter
          </Button>
        </Col>
      </Row>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      filterTable({
        value,
        recordValue: dataIndex ? get(record, dataIndex) : getValue(record),
        type,
      }),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(
          () =>
            type === "date"
              ? searchInputRef?.current?.focus()
              : searchInputRef?.current?.select(),
          100
        );
      }
    },
  });

  useEffect(() => {
    if (columns?.length) {
      setSearchRefs((elRefs) =>
        Array(columns?.length)
          .fill(null)
          .map((_, i) => elRefs[i] || createRef())
      );
    }
  }, [columns?.length]);

  const filterCols = useMemo(() => {
    if (searchRefs.length) {
      return columns?.map((col, index) =>
        col.filter
          ? {
              ...getColumnSearchProps({
                searchInputRef: searchRefs[index],
                ...col.filter,
              }),
              ...col,
            }
          : col
      );
    }
    return columns;
  }, [columns, searchRefs]);

  return <Table columns={filterCols} {...props} />;
};

export default FilterTable;

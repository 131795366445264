import styled from 'styled-components'

export const BoxChildSubMenu = styled.div`
  display: flex;

  .step {
    display: block;
    width: 30px;
    margin-right: 15px;
    font-weight: bold;
    font-size: 18px;
  }

  .name-step {
    font-size: 12px;
  }
`

export const BoxSideBar = styled.div`
  position: fixed;
  height: 100%;
  top: 0;
  z-index: 10;
  box-shadow: 1px 0 20px rgb(0 0 0 / 8%);
  background-color: #20222a;
  .full {
    display: none;
  }
  .icon__togged {
    height: 60px;
    color: #fff;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sidebar {
    max-width: 70px!important;
    min-width: 70px!important;
    width: 70px!important;
    padding-bottom: 0;
    transition: .05s ease-in;
    overflow: auto;
    height: 100%;
    .ant-menu > .ant-menu-item {
      a {
        color: #fff;
      }
      g {
        stroke: #fff;
      }
      .ant-menu-item-icon {
        font-size: 16px;
        color: #fff;
      }
      .ant-menu-title-content {
        display: inline-block;
        opacity: 0;
        a {
          color: #fff;
        }
      }
      &.ant-menu-item-selected {
        .ant-menu-item-icon {
          font-size: 16px;
          color: var(--primary);
        }
        .anticon + span {
          a {
            color: var(--primary);
          }
        }
      }
      &.ant-menu-item-selected {
        .ant-menu-item-icon {
          font-size: 16px;
          color: var(--primary);
        }
        a {
          color: var(--primary);
        }
        g {
          stroke: var(--primary);
        }
      }
    }
    .ant-menu {
      transition: none;
      .ant-menu-item:first-child {
        margin-top: 0;
      }
      .ant-menu-submenu {
        .ant-menu-submenu-title {
          .ant-menu-title-content, svg {
            color: #fff;
          }
        }
        &.ant-menu-submenu-selected {
          .ant-menu-submenu-title {
            .ant-menu-title-content, svg {
              color: var(--primary);
            }
          }
        }
      }
      .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
        color: #fff;
      }
    }
    .ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
      transition: none;
    }
  }

  &:hover {
    width: 200px;
    .full {
      display: block;
    }
    .short {
      display: none;
    }
    .sidebar {
      max-width: 200px!important;
      min-width: 200px!important;
      width: 200px!important;
      .ant-menu > .ant-menu-item {
        .ant-menu-item-icon {
          font-size: 14px;
        }
        .ant-menu-title-content {
          display: inline-block;
          opacity: 1;
        }
      }
    }
  }

  .sidebar {
    flex: 1 !important;
    background-color: #20222a;

    .ant-menu {
      background-color: transparent;
      height: 100%;
    }
    .ant-layout-sider-trigger {
      display: none;
    }
  }
`

import { Card, Col, Row, Typography } from "antd";
import React from "react";

const { Title, Paragraph } = Typography;

const SummaryCard = ({ selectedShipper }) => {
  return (
    <Card title="Summary">
      <Row>
        <Col md={24}>
          <Title level={4}>Shipper</Title>
          <Paragraph>
            <ul>
              <li>
                <strong>ID</strong>: <span>{selectedShipper?.id}</span>
              </li>
              <li>
                <strong>Name</strong>: <span>{selectedShipper?.name}</span>
              </li>
              <li>
                <strong>Company Name</strong>:{" "}
                <span>{selectedShipper?.companyName}</span>
              </li>
              <li>
                <strong>Phone Number</strong>:{" "}
                <span>{selectedShipper?.phoneNumber}</span>
              </li>
              <li>
                <strong>Email</strong>: <span>{selectedShipper?.email}</span>
              </li>
              <li>
                <strong>Address 1</strong>:{" "}
                <span>{selectedShipper?.address1}</span>
              </li>
              <li>
                <strong>Address 2</strong>:{" "}
                <span>{selectedShipper?.address2}</span>
              </li>
              <li>
                <strong>City</strong>: <span>{selectedShipper?.city}</span>
              </li>
              <li>
                <strong>Province Code</strong>:{" "}
                <span>{selectedShipper?.provinceCode}</span>
              </li>
            </ul>
          </Paragraph>
        </Col>
      </Row>
    </Card>
  );
};

export default SummaryCard;

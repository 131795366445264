import { Button, Card, DatePicker, Form, Input, Radio, Space } from "antd";
import React, { useEffect } from "react";
import { RadioStyled } from "./index.style";

const { RangePicker } = DatePicker;

const ProductFilterForm = ({ form, setParams }) => {
  const onFinish = (value) => {
    setParams((prev) => {
      return {
        ...prev,
        title: value.title,
        inTransit: value.inTransit,
        inStock: value.inStock,
      };
    });
  };

  useEffect(() => {
    console.log(form.setFieldsValue({}));
  }, []);

  return (
    <>
      <Card style={{ paddingBottom: 0 }} title="Product Filter">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item name="title" label="Search for:">
            <Input placeholder="Product Title/ Variant Title" />
          </Form.Item>
          <Form.Item name="inTransit" label="In Transit">
            <Radio.Group>
              <RadioStyled value={true}>Yes</RadioStyled>
              <RadioStyled value={false}>No</RadioStyled>
              <RadioStyled value="">None</RadioStyled>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="inStock" label="In Stock">
            <Radio.Group>
              <RadioStyled value={true}>Yes</RadioStyled>
              <RadioStyled value={false}>No</RadioStyled>
              <RadioStyled value="">None</RadioStyled>
            </Radio.Group>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                Apply Filter
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default ProductFilterForm;

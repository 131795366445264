import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import kodNegara from "kod-negara";
import produce from "immer";
import { debounce } from "lodash";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { twoDecimal } from "utils/funcs";
import {
  ButtonPlacement,
  FormBody,
  FormHeader,
  FormWrapper,
} from "./index.style";

const { Option } = Select;

const ExportDeclare = ({
  // setMinWeight,
  form,
  currency,
  detectBookCategory,
  // activeCourier,
  piecesRecheckDataHandler,
}) => {
  const weightUnit = "kg";

  const recheckDataHandler = debounce(() => {
    const exportDeclarationItems = form.getFieldValue("exportDeclarationItems");
    if (
      exportDeclarationItems === undefined ||
      exportDeclarationItems.length === 0
    ) {
      return form.setFieldsValue({
        totalDeclaredValue: 0,
        totalDeclaredWeight: 0,
        totalDeclaredGrossWeight: 0,
      });
    } else if (exportDeclarationItems.length >= 1) {
      const totalDeclaredWeight = exportDeclarationItems
        ?.map((item) => Number(item.weight))
        .reduce((prev, curr) => prev + curr);

      const totalDeclaredGrossWeight = exportDeclarationItems
        ?.map((item) => Number(item.grossWeight))
        .reduce((prev, curr) => prev + curr);

      const totalDeclaredValue = exportDeclarationItems
        ?.map((item) => Number(item.totalValue))
        .reduce((prev, curr) => prev + curr);

      const pieces = form.getFieldValue("pieces");

      const prodPieces = produce(pieces, (draft) => {
        draft[0].weight = twoDecimal(totalDeclaredWeight);
        draft[0].grossWeight = twoDecimal(totalDeclaredGrossWeight);
        draft[0].value = twoDecimal(totalDeclaredValue);
      });

      // 1.update TDW
      // 2.update TDGW
      // 3.update TV
      // 4.update pieces[0].weight
      // 5.update pieces[0].grossWeight
      // 6.update pieces[0].value
      form.setFieldsValue({
        totalDeclaredWeight: twoDecimal(totalDeclaredWeight),
        totalDeclaredGrossWeight: twoDecimal(totalDeclaredGrossWeight),
        totalDeclaredValue: twoDecimal(totalDeclaredValue),
        pieces: prodPieces,
      });
    }
    piecesRecheckDataHandler();
  }, [500]);

  const onChangeCountry = (value, index) => {
    const result = produce(form.getFieldsValue(), (draft) => {
      draft.exportDeclarationItems[index].originCountry =
        value.match("[a-zA-Z]+")[0];
    });
    form.setFieldsValue(result);
  };

  const categoryOnChangeHandler = (value, row) => {
    const exportDeclarationItems = form.getFieldValue("exportDeclarationItems");
    const oriDesc = exportDeclarationItems[row].description;

    if (value.toLowerCase() === "book") {
      if (!oriDesc.toLowerCase().startsWith("book")) {
        const newDesc = `BOOK ${oriDesc}`;
        const newDescription = produce(exportDeclarationItems, (draft) => {
          draft[row].description = newDesc;
        });
        const newContents = newDescription
          ?.map((item) => item.description)
          .join(", ");
        form.setFieldsValue({
          exportDeclarationItems: newDescription,
          contents: newContents,
        });
      }
    } else if (value.toLowerCase() === "other") {
      if (oriDesc.toLowerCase().startsWith("book")) {
        const newDesc = oriDesc.slice(5, -1);
        const newDescription = produce(exportDeclarationItems, (draft) => {
          draft[row].description = newDesc;
        });
        const newContents = newDescription
          ?.map((item) => item.description)
          .join(", ");
        form.setFieldsValue({
          exportDeclarationItems: newDescription,
          contents: newContents,
        });
      }
    }
  };

  const manualType = debounce((event) => {
    const exportDeclarationItems = form.getFieldValue("exportDeclarationItems");
    const row = event.target.id.split("_")[1];

    const newContent = exportDeclarationItems
      ?.map((item) => item.description)
      .join(", ");

    if (detectBookCategory(event.target.value)) {
      if (
        !exportDeclarationItems[row].description
          .toLowerCase()
          .startsWith("book")
      ) {
        form.setFieldsValue({
          contents: newContent,
        });
      }
    } else {
      form.setFieldsValue({
        contents: newContent,
      });
    }
  }, 500);

  // this function will be using by weight and grossWeight
  const checkWeightZero = debounce((event) => {
    const exportDeclarationItems = form.getFieldValue("exportDeclarationItems");
    const arr = event.target.id.split("_");
    const index = Number(arr[1]);
    const value = event.target.value;
    if (Number(value) <= 0) {
      const result = produce(exportDeclarationItems, (draft) => {
        draft[index].weight = twoDecimal(0);
        draft[index].grossWeight = twoDecimal(0);
      });

      form.setFieldsValue({
        exportDeclarationItems: result,
      });
    } else {
      const result = produce(exportDeclarationItems, (draft) => {
        draft[index].weight = twoDecimal(value);
        draft[index].grossWeight = twoDecimal(value);
      });

      form.setFieldsValue({
        exportDeclarationItems: result,
      });
    }

    // in case they want back 0.02
    // if (activeCourier?.apiType?.toLowerCase() === "dhl") {
    //   if (Number(value) === 0 || value === undefined || Number(value) <= 0.02) {
    //     const result = produce(exportDeclarationItems, (draft) => {
    //       draft[index].weight = "0.02";
    //       draft[index].grossWeight = "0.02";
    //     });
    //     form.setFieldsValue({
    //       exportDeclarationItems: result,
    //     });
    //   } else {
    //     const result = produce(exportDeclarationItems, (draft) => {
    //       draft[index].weight = twoDecimal(value);
    //       draft[index].grossWeight = twoDecimal(value);
    //     });
    //     form.setFieldsValue({
    //       exportDeclarationItems: result,
    //     });
    //   }
    // } else {
    //   const result = produce(exportDeclarationItems, (draft) => {
    //     draft[index].weight = twoDecimal(value);
    //     draft[index].grossWeight = twoDecimal(value);
    //   });
    //   form.setFieldsValue({
    //     exportDeclarationItems: result,
    //   });
    // }

    return recheckDataHandler();
  }, 3000);

  const quantityAndUnitPriceOnChange = debounce((event) => {
    const exportDeclarationItems = form.getFieldValue("exportDeclarationItems");
    const arr = event.target.id.split("_");
    const index = Number(arr[1]);
    const value = event.target.value;
    if (arr[2] === "quantity") {
      const unitPrice = exportDeclarationItems[index].unitPrice;
      const newTotalValue =
        Number(unitPrice) === 0 || isNaN(Number(unitPrice))
          ? 0
          : Number(unitPrice) * Number(value);
      const prod = produce(exportDeclarationItems, (draft) => {
        draft[index].totalValue = newTotalValue;
      });

      form.setFieldsValue({
        exportDeclarationItems: prod,
      });
    } else if (arr[2] === "unitPrice") {
      const quantity = exportDeclarationItems[index].quantity;
      const newTotalValue =
        Number(quantity) === 0 || isNaN(Number(quantity))
          ? 0
          : Number(quantity) * Number(value);
      const prod = produce(exportDeclarationItems, (draft) => {
        draft[index].totalValue = newTotalValue;
      });

      form.setFieldsValue({
        exportDeclarationItems: prod,
      });
    }
    recheckDataHandler();
  }, 3000);

  // in case they want the 0.02 back
  //
  // const checkForDhl = () => {
  //   const badValue = (value) => {
  //     if (
  //       Number(value) === 0 ||
  //       isNaN(Number(value)) ||
  //       value === undefined ||
  //       value === "0.00"
  //     ) {
  //       return true;
  //     }
  //   };
  //   const exportDeclarationItems = form.getFieldValue("exportDeclarationItems");
  //   if (activeCourier?.apiType?.toLowerCase() === "dhl") {
  //     const newExport = exportDeclarationItems.map((item) => {
  //       return {
  //         ...item,
  //         weight: badValue(item.weight) ? 0.02 : item.weight,
  //         grossWeight: 0.02,
  //       };
  //     });
  //     console.log("NEW EXPORT", newExport);
  //     form.setFieldsValue({
  //       exportDeclarationItems: newExport,
  //     });
  //     return recheckDataHandler();
  //   }
  // };

  // useEffect(() => {
  //   checkForDhl();
  // }, [activeCourier]);

  useEffect(() => {
    recheckDataHandler();
  }, []);

  return (
    <FormWrapper>
      <FormHeader className="greenBoldTitle">Export Declaration</FormHeader>
      <FormBody>
        <Row gutter={24}>
          <Col lg={8}>
            {/* dont simply change this name! It will broke entire logic! */}
            <Form.Item
              name="totalDeclaredWeight"
              label="Total Declared Weight"
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value || value < 0.001) {
                      return Promise.reject(
                        new Error("Cannot less than 0.001")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                placeholder="1.00"
                addonBefore={weightUnit.toUpperCase()}
                disabled
              />
            </Form.Item>
          </Col>
          <Col lg={8}>
            {/* dont simply change this name! It will broke entire logic! */}
            <Form.Item
              name="totalDeclaredGrossWeight"
              label="Total Declared Gross Weight"
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value || value < 0.001) {
                      return Promise.reject(
                        new Error("Cannot less than 0.001")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                placeholder="1.00"
                addonBefore={weightUnit.toUpperCase()}
                disabled
              />
            </Form.Item>
          </Col>
          <Col lg={8}>
            {/* dont simply change this name! It will broke entire logic! */}
            <Form.Item
              name="totalDeclaredValue"
              label="Total Declared Value"
              rules={[
                {
                  required: true,
                  message: "Please recheck data",
                },
                () => ({
                  validator(_, value) {
                    if (!value || value < 0.001) {
                      return Promise.reject(
                        new Error("Cannot less than 0.001")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input placeholder="1.00" addonBefore="USD" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={8}>
            <Form.Item
              name="invoice"
              label="Invoice Number"
              rules={[
                {
                  required: true,
                  message: "Invoice number is required",
                },
              ]}
            >
              <Input placeholder="1234567890" />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              name="invoiceDate"
              label="Invoice Date"
              initialValue={moment()}
            >
              <DatePicker format="YYYY-M-DD" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        <Form.List name="exportDeclarationItems">
          {(fields, { add, remove }) => {
            return (
              <Fragment>
                <ButtonPlacement>
                  <Button
                    className="submit"
                    onClick={() => {
                      add();
                      return recheckDataHandler();
                    }}
                  >
                    Add Item
                  </Button>
                </ButtonPlacement>
                {fields.map((exportItem) => {
                  return (
                    <Fragment key={exportItem.name}>
                      <Divider />
                      <p className="greenBoldTitle">
                        Item Declaration {exportItem.name + 1}
                      </p>

                      <Row gutter={16}>
                        <Col lg={24}>
                          <Form.Item
                            onChange={manualType}
                            name={[exportItem.name, "description"]}
                            label="Description"
                            rules={[
                              {
                                required: true,
                                message: "Description is required",
                              },
                            ]}
                            hasFeedback
                          >
                            <Input.TextArea placeholder="Description..." />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col md={6}>
                          <Form.Item
                            name={[exportItem.name, "sku"]}
                            label="SKU"
                          >
                            <Input placeholder="SKU..." />
                          </Form.Item>
                        </Col>
                        <Col md={6}>
                          <Form.Item
                            name={[exportItem.name, "commCode"]}
                            label="Comm Code"
                          >
                            <Input placeholder="Comm Code..." />
                          </Form.Item>
                        </Col>
                        <Col md={6}>
                          <Form.Item
                            name={[exportItem.name, "hsCode"]}
                            label="HSCode"
                          >
                            <Input placeholder="HSCode..." />
                          </Form.Item>
                        </Col>
                        <Col lg={6}>
                          <Form.Item
                            name={[exportItem.name, "updateVariant"]}
                            label="Update Product Variant"
                          >
                            <Checkbox.Group
                              options={[
                                {
                                  label: "True",
                                  value: "updateVariant",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col md={8}>
                          <Form.Item
                            name={[exportItem.name, "weight"]}
                            label="Weight"
                            rules={[
                              {
                                required: true,
                                message: "Weight is required",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const weight = Number(
                                    getFieldValue("exportDeclarationItems")[
                                      exportItem.name
                                    ]["weight"]
                                  );
                                  if (!value || weight < 0) {
                                    return Promise.reject(
                                      new Error("Cannot less than 0")
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                            hasFeedback
                            initialValue="0.00"
                          >
                            <Input
                              placeholder="Weight..."
                              addonBefore={weightUnit.toUpperCase()}
                              onChange={checkWeightZero}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8}>
                          <Form.Item
                            name={[exportItem.name, "grossWeight"]}
                            label="Gross Weight"
                            initialValue="0.00"
                            rules={[
                              {
                                required: true,
                                message: "Weight is required",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const weight = Number(
                                    getFieldValue("exportDeclarationItems")[
                                      exportItem.name
                                    ]["weight"]
                                  );
                                  if (!value || weight < 0) {
                                    return Promise.reject(
                                      new Error("Cannot less than 0")
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                            hasFeedback
                          >
                            <Input
                              placeholder="Gross Weight..."
                              addonBefore={weightUnit.toUpperCase()}
                              onChange={checkWeightZero}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8}>
                          <Form.Item
                            name={[exportItem.name, "unitPrice"]}
                            label="Unit Price"
                            initialValue={0}
                            rules={[
                              {
                                required: true,
                                message: "Unit Price is required",
                              },
                            ]}
                            hasFeedback
                            onChange={recheckDataHandler}
                          >
                            <Input
                              placeholder="Unit Price..."
                              addonBefore={currency}
                              onChange={quantityAndUnitPriceOnChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col md={6}>
                          <Form.Item
                            name={[exportItem.name, "originCountry"]}
                            label="Origin Country"
                            rules={[
                              {
                                required: true,
                                message: "Origin Country is required",
                              },
                            ]}
                            hasFeedback
                            tooltip="The origin of product"
                            initialValue={"KR"}
                          >
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              onChange={(value) =>
                                onChangeCountry(value, exportItem.name)
                              }
                            >
                              {kodNegara.all().map((item, index) => (
                                <Option key={index} d value={item.countryCode}>
                                  {item.countryNameEn} ({item.countryCode})
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={6}>
                          <Form.Item
                            name={[exportItem.name, "quantity"]}
                            label="Quantity"
                            initialValue={0}
                            rules={[
                              {
                                required: true,
                                message: "Quantity is required",
                              },
                            ]}
                            hasFeedback
                          >
                            <Input
                              placeholder="Quantity..."
                              addonBefore={"pcs".toUpperCase()}
                              onChange={quantityAndUnitPriceOnChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={6}>
                          <Form.Item
                            name={[exportItem.name, "totalValue"]}
                            label="Total Value"
                            initialValue={0}
                            rules={[
                              {
                                required: false,
                              },
                            ]}
                          >
                            <Input
                              placeholder="Total Value..."
                              addonBefore={currency}
                              onChange={recheckDataHandler}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={6}>
                          <Form.Item
                            name={[exportItem.name, "category"]}
                            label="Category"
                            hasFeedback
                            initialValue="Other"
                          >
                            <Select
                              onChange={(value) =>
                                categoryOnChangeHandler(value, exportItem.name)
                              }
                            >
                              <Select.Option value="OTHER">Other</Select.Option>
                              <Select.Option value="BOOK">Book</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <ButtonPlacement>
                        <Button
                          onClick={() => {
                            remove(exportItem.name);
                            return recheckDataHandler();
                          }}
                          className="redBoldReset"
                        >
                          Remove Item
                        </Button>
                        {/* {exportItem.name > 0 && (
                          <Button
                            onClick={() => {
                              remove(exportItem.name);
                              return recheckDataHandler();
                            }}
                            className="redBoldReset"
                          >
                            Remove Item
                          </Button>
                        )} */}
                      </ButtonPlacement>
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          }}
        </Form.List>
      </FormBody>
    </FormWrapper>
  );
};

export default ExportDeclare;

import React from 'react';
import styled from 'styled-components';

const BadgeStyled = styled.span`
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: ${({ color }) => {
    switch (color) {
      case 'warning':
        return '#000';
      default:
        return '#fff';
    }
  }};
  background-color: ${({ color }) => {
    switch (color) {
      case 'danger':
        return '#dc3545';
      case 'success':
        return '#28a745';
      case 'warning':
        return '#ffc107';
      case 'info':
        return '#17a2b8';
      default:
        return color || '#28a745';
    }
  }};
`;

function Badge({ color, children }) {
  return <BadgeStyled color={color}>{children}</BadgeStyled>;
}

export { Badge };

import styled from 'styled-components'

export const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  background-color: #edf1f5;
  padding: 12px 24px;
  border-bottom: 1px solid #78828c21;
  h1 {
    font-size: 16px;
    text-transform: uppercase;
    color: #3e5569;
    font-weight: 600;
    margin-bottom: 0;
  }
`

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

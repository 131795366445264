import axiosClient from "./axiosClient";

const productsApi = {
  getProducts: (payload) =>
    axiosClient.get("/api/products", { params: payload }),
  getProductVariant: (id) => axiosClient.get(`/api/products/variant/${id}`),
  getDailyStatusProducts: (payload) =>
    axiosClient.get("/api/products", { params: payload }),
  getProductShopify: (id) => axiosClient.get(`/api/products/get-shopify/${id}`),
  getOrdersVariant: (id) =>
    axiosClient.get(`/api/orders/list-orders-containing-variant/${id}`),
  getProductById: (id) => axiosClient.get(`/api/products/${id}`),
  createProduct: (payload) => axiosClient.post("/api/products/", payload),
};

export default productsApi;

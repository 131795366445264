export const STATUS_REQUEST = {
  UNAUTHORIZED: 401,
}

export const FOLDER_FILE_S3 = {
  product: (folderId, productId) => `public/photo/product/${folderId}/${productId}_${new Date().getTime()}`,
}

export const SPIN_STATUS = {
  IN_READY: 1,
  IN_PROGRESS: 2,
  FINISH: 3,
  LOTTERY: 4,
  SEND_TO_MALL: 5,
}

export const PRODUCT_STATUS = {
  UNAVAILABLE: { value: 1, name: 'Unavailable' },
  AVAILABLE: { value: 2, name: 'Available' },
}

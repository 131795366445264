import {
  Button,
  Calendar,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  message,
  Select,
} from "antd";
import Box from "components/box/Box";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import Path from "../../commons/breadcrumbs";
import productsApi from "../../apis/productsApi";
import produce from "immer";
const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const CreateProduct = ({ history }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState({
    onlineStatusAvailabilityModal: false,
    editSeo: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [createNewProduct, setCreateNewProduct] = useState({
    title: "",
    vendor: "",
    type: "",
    tags: [],
    note: "",
    options: [],
    variants: [
      {
        title: "",
        sku: "",
        sourceUrl: "",
        options: [],
        price: null,
        weight: null,
      },
    ],
    publishOnShopify: false,
    bodyHtml:
      "<h1>Title Here</h1><br><h2>Sub-Title Here</h2><p>Please tell us more about this product</p>",
  });

  const OnlineStatusAvailability = () => {
    const handleOk = () => {
      setModal((prev) => {
        return { ...prev, onlineStatusAvailabilityModal: false };
      });
    };
    const handleCancel = () => {
      // what you want for cancel
      setModal((prev) => {
        return { ...prev, onlineStatusAvailabilityModal: false };
      });
    };
    return (
      <Modal
        title={t("Schedule Online Store availability")}
        onOk={handleOk}
        onCancel={handleCancel}
        visible={modal.onlineStatusAvailabilityModal}
        width={1000}
      >
        <Form>
          <Row>
            <Space>
              <DatePicker />
            </Space>
          </Row>
          <Row>
            <Calendar fullscreen={false} />
            <Calendar fullscreen={false} mode="month" />
          </Row>
        </Form>
      </Modal>
    );
  };

  const createProductBtn = async () => {
    setIsLoading(true);
    try {
      //

      const res = await productsApi.createProduct(
        JSON.stringify(createNewProduct)
      );
      console.log(res);
      message.success("Product has been created", 3);
      setIsLoading(false);
    } catch (error) {
      //
      message.error(error, 3);
      setIsLoading(false);
    }
  };

  const inputChangeHandler = (event) => {
    setCreateNewProduct((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  const variantsInputHandler = (event, indexVar) => {
    const prod = produce(createNewProduct, (draft) => {
      draft.variants[indexVar][event.target.name] = event.target.value;
    });
    return setCreateNewProduct(prod);
  };

  const addVariantHandler = () => {
    const newVariantObj = {
      ...createNewProduct.variants[0],
    };
    message.success("Variant Added", 1);
    return setCreateNewProduct((prev) => {
      return { ...prev, variants: [...prev.variants, newVariantObj] };
    });
  };

  const removeVariantHandler = (varIndexCard) => {
    // this function for add another card of create variant
    message.info("Variant Deleted", 1);
    return setCreateNewProduct((prev) => {
      return {
        ...prev,
        variants: prev.variants.filter(
          (varItem, index) => index !== varIndexCard
        ),
      };
    });
  };

  const variantOptionsHandler = (event, varIndexCard, optionIndex) => {
    const value = event.target.value;
    const newValue = produce(createNewProduct, (draft) => {
      draft.variants[varIndexCard].options[optionIndex] = value;
    });
    return setCreateNewProduct(newValue);
  };

  console.log(createNewProduct);

  return (
    <>
      <OnlineStatusAvailability />
      <TitleHeader
        listOfBreadcrumb={[
          { name: "Home", path: "/" },
          { name: Path.PRODUCTS.name, path: Path.PRODUCTS.pathname },
          {
            name: Path.CREATE_PRODUCT.name,
          },
        ]}
        title={t("Create Product")}
        currentHistory={history}
      />
      <Box>
        <Title level={3}>{t("Create product")}</Title>
        <Paragraph style={{ marginBottom: "24px" }}>
          {t(
            "The details displayed often include size, color, price, shipping information, reviews, and other relevant information customers may want to know before making a purchase."
          )}
        </Paragraph>
        <Form layout="vertical">
          <Row gutter={24}>
            <Col className="left-row" md={16}>
              <Container>
                {/* Title Card  */}
                <Card style={{ marginBottom: "24px" }}>
                  <Title level={5}>{t("Product")}</Title>
                  <Row gutter={16}>
                    <Col md={8}>
                      <Form.Item label={t("Title")}>
                        <Input
                          name="title"
                          value={createNewProduct.title}
                          onChange={inputChangeHandler}
                          placeholder="Product name"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label={t("Vendor")}>
                        <Input
                          name="vendor"
                          value={createNewProduct.vendor}
                          onChange={inputChangeHandler}
                          placeholder="Vendor name"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label={t("Product Type")}>
                        <Input
                          name="type"
                          value={createNewProduct.type}
                          onChange={inputChangeHandler}
                          placeholder="Eg. Mensware"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col md={12}>
                      <Form.Item label={t("Options")}>
                        <Select
                          mode="tags"
                          style={{ width: "100%" }}
                          placeholder="Eg. Size, Pattern, Colour"
                          onChange={(value) =>
                            setCreateNewProduct((prev) => {
                              return { ...prev, options: value };
                            })
                          }
                        >
                          {createNewProduct.options}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item label={t("Tags")}>
                        <Select
                          mode="tags"
                          style={{ width: "100%" }}
                          placeholder="Eg. Size, Pattern, Colour"
                          onChange={(value) =>
                            setCreateNewProduct((prev) => {
                              return { ...prev, tags: value };
                            })
                          }
                        >
                          {createNewProduct.options}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col md={24}>
                      <Form.Item label={t("Note")}>
                        <TextArea
                          name="note"
                          rows={4}
                          value={createNewProduct.note}
                          onChange={inputChangeHandler}
                          placeholder="You can leave any note to admin or courier here..."
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    label={t("Description")}
                    style={{ marginBottom: 0 }}
                  >
                    <ReactQuill
                      theme="snow"
                      value={createNewProduct.bodyHtml}
                      onChange={(content) => {
                        return setCreateNewProduct((prev) => {
                          return { ...prev, bodyHtml: content };
                        });
                      }}
                    />
                  </Form.Item>
                </Card>

                <Divider />
                <Space align="center" direction="horizontal">
                  <Button
                    loading={isLoading}
                    onClick={createProductBtn}
                    type="primary"
                  >
                    Create Product
                  </Button>
                  <Button type="danger">Discard</Button>
                </Space>
              </Container>
            </Col>

            {/* Right column side start here */}
            <Col className="right-row" md={8}>
              <Container>
                {/* variants  */}
                {createNewProduct.variants.map((cardVariant, varIndexCard) => (
                  <Card key={varIndexCard} style={{ marginBottom: "24px" }}>
                    <Title level={5}>{t(`Variant ${varIndexCard + 1}`)}</Title>
                    <Row gutter={16}>
                      <Col md={24}>
                        <Form.Item
                          label={t("Title")}
                          extra="Eg. With Flower Pattern"
                        >
                          <Input
                            name="title"
                            value={
                              createNewProduct.variants[varIndexCard].title
                            }
                            onChange={(event) => {
                              variantsInputHandler(event, varIndexCard);
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={24}>
                        <Form.Item
                          label={t("SKU")}
                          extra="Each product has their own unique id"
                        >
                          <Input
                            name="sku"
                            value={createNewProduct.variants[varIndexCard].sku}
                            onChange={(event) => {
                              variantsInputHandler(event, varIndexCard);
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={24}>
                        <Form.Item
                          label={t("Source URL")}
                          extra="Product's Link"
                        >
                          <Input
                            name="sourceUrl"
                            value={
                              createNewProduct.variants[varIndexCard].sourceUrl
                            }
                            onChange={(event) => {
                              variantsInputHandler(event, varIndexCard);
                            }}
                          />
                        </Form.Item>
                      </Col>

                      {createNewProduct.options.map((item, index) => (
                        <Col md={24} key={index}>
                          <Form.Item label={t(`${item} Options`)}>
                            <Input
                              name="options"
                              value={
                                createNewProduct.variants[varIndexCard].options
                                  ? createNewProduct.variants[varIndexCard]
                                      .options[index]
                                  : "Empty"
                              }
                              onChange={(event) =>
                                variantOptionsHandler(
                                  event,
                                  varIndexCard,
                                  index
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                      ))}
                    </Row>
                    <Row gutter={16}>
                      <Col md={12}>
                        <Form.Item label={t("Price")}>
                          <Input
                            name="price"
                            type="number"
                            step={0.05}
                            addonBefore={"MYR"}
                            value={
                              createNewProduct.variants[varIndexCard].price
                            }
                            onChange={(event) => {
                              variantsInputHandler(event, varIndexCard);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item label={t("Weight")}>
                          <Input
                            name="weight"
                            type="number"
                            step={0.01}
                            addonAfter="KG"
                            value={
                              createNewProduct.variants[varIndexCard].weight
                            }
                            onChange={(event) => {
                              variantsInputHandler(event, varIndexCard);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {varIndexCard + 1 === createNewProduct.variants.length && (
                      <Space align="end">
                        <Button type="primary" onClick={addVariantHandler}>
                          Add Variant
                        </Button>
                        <Button
                          type="danger"
                          disabled={createNewProduct.variants.length < 2}
                          onClick={() => removeVariantHandler(varIndexCard)}
                        >
                          Delete
                        </Button>
                      </Space>
                    )}
                  </Card>
                ))}
              </Container>
            </Col>
          </Row>
        </Form>
      </Box>
    </>
  );
};

export default CreateProduct;

import {
  Avatar,
  Tooltip,
  Col,
  List,
  Row,
  Space,
  Typography,
  Tag,
  message,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import JsBarcode from "jsbarcode";
import { Link, useHistory } from "react-router-dom";
import { kebabCase } from "lodash";
import { currencyExchangeFunc } from "utils/funcs";
import { useSelector } from "react-redux";
import { currencyExchangeApi } from "apis/currencyExchangeApi";
import Path from "commons/breadcrumbs";
import ordersApi from "apis/ordersApi";
import { ShipCard } from "../index.styles";
import moment from "moment";
import purchaseApi from "apis/purchaseApi";
import queryString from "query-string";
import styled from "styled-components";
import productsApi from "apis/productsApi";

const { Title, Paragraph } = Typography;

const ProductVariantCard = ({
  id,
  productId,
  title,
  price,
  weight,
  currency,
  shopify,
  in_stock,
  in_transit,
  allocatedInventory,
  setIsLoading,
  productName,
  supplier,
  setActiveVariant,
  setIsModal,
}) => {
  const history = useHistory();
  const ref = React.useRef(null);
  const cont = React.useRef(0);
  const curr = useSelector((state) => state.common.currency);
  const [convertedPrice, setConvertedPrice] = useState(0);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoad, setButtonLoad] = useState(false);

  const totalAvailable = in_stock + in_transit;

  // const pendingProduct = pendingProducts.find(
  //   (item) => item.productVariantId === id
  // );

  const makePurchaseHandler = (indexRow) => {
    setIsLoading(true);
    try {
      const makePurchase = {
        purchaseDate: moment().format("MM/DD/YYYY"),
        purchaseTime: moment().format("HH:mm:ss"),
        purchaser: "Kaamesh",
        purchaseItem: [
          {
            productName: productName,
            variantName: title,
            variantId: id,
            supplier: supplier || "",
            purchaseLink: "",
            pendingQty: 0,
            purchasedQty: 1,
            supplierPrice: 1,
            unitPrice: price,
            supplierCurrency: currency,
            paidFrom: "Credit Card - 8876982268752",
            exchangeRate: 1180.62,
          },
        ],
      };

      purchaseApi
        .postMakePurchaseOrder(makePurchase)
        .then(({ payload }) => {
          let purchaseOrderId = [];
          purchaseOrderId.push(payload.id);
          message.success("Successfully Create Make Purchase", 5);
          history.push({
            pathname: "/purchases/pending-purchases/make-purchase",
            search: queryString.stringify({
              purchaseOrderId,
            }),
          });
        })
        .catch((error) => {
          message.error(error.message, 3);
        });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      return message.error(error.message, 5);
    }
  };

  const getOrderVariant = async () => {
    setLoading(true);
    const params = {
      page: 1,
      pageSize: 10,
      fields: ["id", "orderNumber", "updatedAt", "status"],
    };
    try {
      const { orders } = await ordersApi.getOrdersContainingVariant(id, params);
      setOrders(orders);
      setLoading(false);
    } catch (error) {
      message.error(error.message, 10);
    } finally {
      setLoading(false);
    }
  };

  const openModal = async () => {
    setButtonLoad(true);
    try {
      const res = await productsApi
        .getProductVariant(id)
        .then(({ variant }) => variant);

      console.log("DELETE TERUS LEPAS PAKAI", res);
      const draft = [
        {
          key: res.id,
          id: res.id,
          title: res.title,
          in_stock: res.in_stock,
          quantity: 0,
        },
      ];
      setActiveVariant(draft);
    } catch (error) {
      console.log(error.message);
    } finally {
      setButtonLoad(false);
      setIsModal(true);
    }
  };

  useEffect(() => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, shopify?.barcode || productId, {
      width: 1,
      height: 36,
      textMargin: 0,
      margin: 0,
      fontSize: 12,
    });
    ref.current.innerHTML = "";
    ref.current.appendChild(canvas);
    cont.current = cont.current + 1;
  }, [ref]);

  useEffect(() => {
    currencyExchangeApi
      .then((res) => {
        setConvertedPrice(
          currencyExchangeFunc(curr, currency, res.data.rates, price).toFixed(2)
        );
      })
      .catch((error) => alert(error));
  }, [currency]);

  useEffect(() => {
    getOrderVariant();
  }, [id]);

  return (
    <ShipCard
      key={id}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title style={{ margin: 0 }} level={5}>
            {title}
          </Title>
          <Space direction="horizontal">
            <Button onClick={makePurchaseHandler} loading={loading}>
              Make Purchase
            </Button>
            <Button type="secondary" loading={buttonLoad} onClick={openModal}>
              Receive Inventory
            </Button>
          </Space>
        </div>
      }
    >
      <Row align="middle" justify="space-between">
        <Col>
          <Avatar
            size={82}
            shape="square"
            src={shopify?.image || "/no-image.png"}
          />
        </Col>
        <Col>
          <div className="box-data">Barcode: {shopify?.barcode}</div>
          <div className="box-data">{`Price: ${convertedPrice}${curr}`}</div>
          <div className="box-data">{`Weight: ${
            Math.round(weight * 100) / 100
          }${shopify?.weight_unit}`}</div>
          <div className="box-data">Packaging: {"none"}</div>
        </Col>
        <Col>
          <div className="box-data">Inventory</div>
          <TableData>
            <InfoCard>
              <strong>
                <Paragraph>Available</Paragraph>
              </strong>
              <div className="number">{totalAvailable}</div>
            </InfoCard>
            <InfoCard>
              <strong>
                <Paragraph>Purchasing</Paragraph>
              </strong>
              <div className="number">{in_transit}</div>
            </InfoCard>
            <InfoCard>
              <strong>
                <Paragraph>Reserved</Paragraph>
              </strong>
              <div className="number">{allocatedInventory}</div>
            </InfoCard>
          </TableData>
          {/* <div className="box-table-data">
            <table>
              <thead>
                <tr>
                  <th>Available</th>
                  <th>Purchasing</th>
                  <th>Reserved</th>
                  <th>To Purchase</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{totalAvailable}</td>
                  <td>{in_transit}</td>
                  <td>{allocatedInventory}</td>
                  <td>
                    {Math.max(
                      unallocatedInventory -
                        orderStatistics?.totalCount?.unallocated,
                      0
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
        </Col>
        <Col className="barcode">
          <Space direction="vertical" align="center">
            <QRCode value={`${shopify?.barcode || id || ""}`} size={75} />
            <div ref={ref}></div>
          </Space>
        </Col>
        <Col span={24} className="order-containing-variants">
          <h4>{`Order containing Variants ${
            !loading ? `(${orders?.length || 0} )` : ""
          }`}</h4>
          {(loading || orders?.length > 0) && (
            <List
              grid={{
                gutter: 8,
              }}
              loading={loading}
              dataSource={orders}
              renderItem={(item) => (
                <List.Item style={{ width: 120, marginBottom: 8 }}>
                  <Link
                    target="_blank"
                    to={Path.ORDER_DETAIL.pathParam(item.id)}
                  >
                    <Tooltip title={item.status}>
                      <Tag className={kebabCase(item.status)}>
                        {item.orderNumber}
                      </Tag>
                    </Tooltip>
                  </Link>
                </List.Item>
              )}
            />
          )}
        </Col>
      </Row>
    </ShipCard>
  );
};

export default ProductVariantCard;

const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #dfdfdf;
  padding: 10px;
  border-radius: 10px;
  .number {
    font-size: 2.4rem;
    font-weight: 700;
  }
`;

const TableData = styled.div`
  display: flex;
  gap: 10px;
`;

import axiosClient from "./axiosClient";

const ordersApi = {
  postFullFillOrder: (payload, data) =>
    axiosClient.post(`/api/orders/fulfill-order?${data}`, payload),
  getOrders: (payload) =>
    axiosClient.get("/api/orders/list", { params: payload }),
  getCombineOrders: (payload) =>
    axiosClient.get("/api/orders/combined", { params: payload }),
  getOrderById: ({ id, params }) => axiosClient.get(`/api/orders/get/${id}`, { params }),
  createOrder: (payload) => axiosClient.post(`/api/orders/create`, payload),
  getPossibleCombineOrders: () =>
    axiosClient.get(`/api/orders/list-possible-combine-orders`),
  createCombineOrder: (payload) =>
    axiosClient.post(`/api/orders/combined/create`, payload),
  appendOrderToCombineOrder: ({ id, data }) =>
    axiosClient.patch(`/api/orders/combined/append/${id}`, data),
  removeOrderFromCombineOrder: ({ id, data }) =>
    axiosClient.patch(`/api/orders/combined/pop/${id}`, data),
  getOrdersContainingVariant: (id, data) =>
    axiosClient.get(`/api/orders/list-orders-containing-variant/${id}`, {
      params: data,
    }),
};

export default ordersApi;

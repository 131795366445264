import axiosClient from "./axiosClient";

const shipmentsApi = {
  addShipperDetails: (payload) =>
    axiosClient.post("/api/shipment/add-shipper-details", payload),
  removeShipperDetails: (payload) =>
    axiosClient.post("/api/shipment/remove-shipper-details", payload),
  printShipmentDocuments: (payload) =>
    axiosClient.post("/api/shipment/print-shipment-documents", payload),
  getListShipper: (payload) =>
    axiosClient.get("/api/shipment/list-shipper-details", { params: payload }),
  getShipments: (payload) =>
    axiosClient.get("/api/shipment/list-shipments", { params: payload }),
  getListShippingAcc: (payload) =>
    axiosClient.get("/api/shipment/list-shipping-accounts", {
      params: payload,
    }),
  rateShipmentFedex: (payload) =>
    axiosClient.post("/api/shipment/rate-shipment-rest-fedex", payload),
  rateShipmentDHL: (payload) =>
    axiosClient.post("/api/shipment/rate-shipment-dhl", payload),
  createShipmentFedex: (payload) =>
    axiosClient.post("/api/shipment/create-shipment-rest-fedex", payload),
  createShipmentDhl: (payload) =>
    axiosClient.post("/api/shipment/create-shipment-rest-dhl", payload),
  createShipmentRincos: (payload) =>
    axiosClient.post("/api/shipment/create-shipment-rest-rincos", payload),
  getRateList: (payload) =>
    axiosClient.post("/api/shipment/get-rate-list", payload),
  getListShippingAccounts: (payload) =>
    axiosClient.get("/api/shipment/list-shipping-accounts", payload),
  getPrinters: (payload) =>
    axiosClient.get("/api/shipment/get-db-printers", payload),
  addShippingAccount: (payload) =>
    axiosClient.post("/api/shipment/add-shipping-account", payload),
  updateShippingAccount: (payload) =>
    axiosClient.put("/api/shipment/update-shipping-account", payload),
  removeShippingAccount: (payload) =>
    axiosClient.post("/api/shipment/remove-shipping-account", payload),
};

export default shipmentsApi;

import { PlusOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import { mediaApi } from "apis/mediaApi";
import queryString from "query-string";
import React, { useCallback, useState } from "react";

const { Option } = Select;

// this one can delete after successfully fetch from existing product
const dummyOptionsParent = ["Colors", "Size"];

const CreateVariantModal = ({ variant, setVariant, ...props }) => {
  // later fetch existing options from parrent then update here
  // for now im using dummy array for options selections
  const [optionParent, setOptionParent] = useState(dummyOptionsParent);
  const [additionalOption, setAdditionalOption] = useState("");
  const [image, setImage] = useState(null);

  const optionChangeHandler = (event) => {
    return setAdditionalOption(event.target.value);
  };

  const pushOption = () => {
    setOptionParent((prev) => {
      return [...prev, additionalOption];
    });
    message.success("Successfully added", 3);
  };

  const getImageUrl = async (id) => {
    return await mediaApi.getImageURLS(id);
  };

  const imagePath = (url) => {
    const param = { path: `tmp/${url}` };
    const qs = queryString.stringify(param);
    return qs.split("=").at(1);
  };

  const imageUploadHandler = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    mediaApi
      .uploadImage(formData)
      .then((res) => {
        const jsonReq = { ids: [res?.mediaId] };
        getImageUrl(JSON.stringify(jsonReq))
          .then(({ medias }) => {
            setImage(medias[0]);
            const url = medias[0].url.split("/").at(-1);
            variant.images.push(imagePath(url));
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  const inputChangeHandler = useCallback((event) => {
    return setVariant((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  }, []);

  const inputNumberChangeHandler = useCallback((name, value) => {
    return setVariant((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }, []);

  return (
    <Modal {...props}>
      <Form layout="vertical" style={{ marginBottom: 0, marginTop: "30px" }}>
        <Row gutter={16}>
          <Col md={6}>
            <Form.Item label="Price">
              <InputNumber
                name="price"
                style={{ width: "100%" }}
                value={Number(variant.price)}
                precision={2}
                step={0.05}
                addonAfter={variant.currency}
                onChange={(value) => inputNumberChangeHandler("price", value)}
              />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item label="Weight">
              <InputNumber
                name="weight"
                style={{ width: "100%" }}
                value={Number(variant.weight)}
                precision={2}
                step={0.05}
                addonAfter="KG"
                onChange={(value) => inputNumberChangeHandler("weight", value)}
              />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item label="Unallocated">
              <InputNumber
                name="unallocatedInventory"
                style={{ width: "100%" }}
                disabled={true}
                value={variant.unallocatedInventory}
                onChange={(value) =>
                  inputNumberChangeHandler("unallocatedInventory", value)
                }
              />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item label="Allocated">
              <InputNumber
                name="allocatedInventory"
                style={{ width: "100%" }}
                disabled={true}
                value={variant.allocatedInventory}
                onChange={(value) =>
                  inputNumberChangeHandler("allocatedInventory", value)
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item label="Title">
              <Input
                name="title"
                value={inputChangeHandler.title}
                onChange={inputChangeHandler}
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Currency">
              <Select
                defaultValue="MYR"
                onChange={(value) =>
                  setVariant((prev) => {
                    return { ...prev, currency: value };
                  })
                }
              >
                <Option value="MYR">MYR</Option>
                <Option value="USD">USD</Option>
                <Option value="KRW">KRW</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item label="SKU">
              <Input
                name="sku"
                value={inputChangeHandler.sku}
                onChange={inputChangeHandler}
              />
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item label="Source URL">
              <Input
                name="sourceUrl"
                value={inputChangeHandler.sourceUrl}
                onChange={inputChangeHandler}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item label="Shopify ID">
              <Input
                name="shopifyId"
                value={inputChangeHandler.shopifyId}
                onChange={inputChangeHandler}
              />
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item label="Product ID">
              <Input
                name="productId"
                value={inputChangeHandler.productId}
                onChange={inputChangeHandler}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item label="Options" extra="Limited to 3 options only">
              <Select
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        padding: 8,
                      }}
                    >
                      <Input
                        style={{ flex: "auto" }}
                        value={additionalOption}
                        onChange={optionChangeHandler}
                        disabled={
                          optionParent.length === 3 || optionParent.length >= 3
                        }
                      />
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
                      <a
                        style={{
                          flex: "none",
                          padding: "8px",
                          display: "block",
                          cursor: "pointer",
                        }}
                        onClick={pushOption}
                      >
                        <PlusOutlined /> Add item
                      </a>
                    </div>
                  </div>
                )}
              >
                {optionParent.map((option) => (
                  <Option key={option}>{option}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Images">
              <input type="file" onChange={imageUploadHandler} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateVariantModal;

import React from "react";
import { useDispatch } from "react-redux";
import { setProfile } from "store/slices/accountSlice";
import { changeLanguage } from "../store/slices/commonSlice";
import LocalStorage from "../utils/storage";

function Init({ children }) {
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();

  const getProfile = () => {
    setLoading(false);
  };

  React.useEffect(() => {
    if (LocalStorage.has("token")) {
      getProfile();
    } else {
      setLoading(false);
    }

    dispatch(
      setProfile({
        pagePermissions: [
          "allOrders",
          "createOrder",
          "orders",
          "orderDetails",
          "shipments",
          "readyToShip",
          "createShipment",
          "todayShipment",
          "allShipments",
          "courierList",
          "products",
          "productDetail",
          "settings",
          "storeDetails",
          "usersAndPermissions",
          "salesAndChannels",
          "languages",
          "policies",
          "addStaff",
          "editStaff",
          "createProduct",
          "createdShipment",
          "rateShipment",
          "shipperDetails",
        ],
      })
    );
  }, []);

  React.useEffect(() => {
    const currentLanguage = LocalStorage.has("lang")
      ? LocalStorage.get("lang")
      : "en";
    dispatch(changeLanguage({ language: currentLanguage }));
  }, []);

  return loading ? null : children;
}

export default Init;

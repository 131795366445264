import { createSlice } from '@reduxjs/toolkit'
import LocalStorage from '../../utils/storage'

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    token: LocalStorage.has('token')
      ? LocalStorage.get('token')
      : 'KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy',
    profile: {},

    permissionKey: [],
  },
  reducers: {
    login(state, action) {
      const { token } = action.payload
      state.token = token
    },
    logout(state) {
      state.token = ''
      state.profile = {}
      state.permissionKey = []
    },
    setProfile(state, action) {
      state.profile = action?.payload?.info
      state.permissionKey = action.payload.pagePermissions
    },
  },
})

const { actions, reducer } = accountSlice

export const { login, logout, setProfile } = actions

export default reducer

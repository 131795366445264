import { Button, Modal } from 'antd'
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { BoxActionsDialog } from './DialogConfirmLogout.styles'

function DialogConfirmLogout({ onSubmit, open, onCancel }) {
  const { t } = useTranslation()
  return (
    <Modal
      visible={open}
      onCancel={onCancel}
      destroyOnClose
      width={400}
      title={t('logout.title_dialog')}
      footer={null}
    >
      <BoxActionsDialog>
        <Button onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button
          type="primary"
          onClick={onSubmit}
        >
          {t('confirm')}
        </Button>
      </BoxActionsDialog>
    </Modal>
  )
}

DialogConfirmLogout.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default DialogConfirmLogout

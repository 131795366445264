import { Button, Card, Col, Input, Row, Space, Form, Select } from "antd";
import kodNegara from "kod-negara";
import { values } from "lodash";
import React from "react";

const { Option } = Select;

const CheckRateCard = ({ onFinish, form }) => {
  const onSelect = (value) => {
    const countryCode = value.match("[a-zA-Z]+")[0];
    return form.setFieldsValue({ shipperCountryCode: countryCode });
  };

  return (
    <Card title="Check Rate Form" style={{ marginBottom: "10px" }}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item
              name="weight"
              label="Weight"
              rules={[
                {
                  required: true,
                  message: "Weight is required",
                },
              ]}
              style={{ marginBottom: 0 }}
              hasFeedback
            >
              <Input placeholder="Weight" type="number" addonAfter={"KG"} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name="postal"
              label="Postal"
              rules={[
                {
                  required: true,
                  message: "Postal is required",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Postal" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: "City is required",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="City" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name="shipperCountryCode"
              label="Country Code"
              rules={[
                {
                  required: true,
                  message: "Country Code is required",
                },
              ]}
              hasFeedback
            >
              <Select showSearch style={{ width: "100%" }} onSelect={onSelect}>
                {kodNegara.all().map((item, index) => (
                  <Option key={index} value={item.countryCode}>
                    {item.countryNameEn} ({item.countryCode})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Space>
          <Button type="primary" htmlType="submit">
            Check Rate
          </Button>
          <Button type="ghost" htmlType="reset">
            Reset
          </Button>
        </Space>
      </Form>
    </Card>
  );
};

export default CheckRateCard;

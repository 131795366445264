import { Card, Divider, Space, Typography } from "antd";
import { RateCard } from "./index.style";
import React, { Fragment } from "react";
import { numberWithCommas } from "utils/funcs";

const { Paragraph } = Typography;

const RateAndErrorColumn = ({ rates, ratesError }) => {
  return (
    <>
      <Card title="Rate" style={{ marginBottom: "10px" }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          {rates?.map((rate) => {
            if (!!rate.rateNetCharge) {
              return (
                <RateCard className="rate">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <strong>
                      {rate.courier.toUpperCase()} {rate.accountId}
                    </strong>

                    <strong style={{ color: "#2BABE3" }}>
                      {rate.currency} {numberWithCommas(rate.rateNetCharge)}
                    </strong>
                  </div>
                  <Divider />
                  <Paragraph>{rate.serviceType}</Paragraph>
                </RateCard>
              );
            }
          })}
        </Space>
      </Card>
      <Card title="Error" style={{ marginBottom: "10px" }}>
        <Space direction="vertical">
          {ratesError?.map((error, index) => {
            return (
              <Fragment key={index}>
                {error.courier === "fedex" ? (
                  <RateCard className="rate">
                    <strong>{error.courier.toUpperCase()}</strong> <Divider />
                    {error.errors?.map((err) => (
                      <div>{err.message}</div>
                    ))}
                  </RateCard>
                ) : error.courier === "dhl" ? (
                  <RateCard className="rate" key={index}>
                    <strong>{error.courier.toUpperCase()}</strong> <Divider />
                    <div>{error.errors?.detail}</div>
                  </RateCard>
                ) : (
                  <RateCard className="rate" key={index}>
                    <strong>{error.courier.toUpperCase()}</strong> <Divider />
                    {error.errors?.map((err) => (
                      <div>{err.name}</div>
                    ))}
                  </RateCard>
                )}
              </Fragment>
            );
          })}
        </Space>
      </Card>
    </>
  );
};

export default RateAndErrorColumn;

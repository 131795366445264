import {
  Button,
  Col,
  Divider,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Typography,
  List,
  Spin,
  Avatar,
  Image,
} from "antd";
import purchaseApi from "apis/purchaseApi";
import Container from "components/container/Container";
import produce from "immer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import Path from "../../commons/breadcrumbs";
import Breadcrumbs from "../../components/breadcrumb/Breadcrumb";
import { TitleBox } from "./index.style";
import queryString from "query-string";
import styled from "styled-components";
import DragDropFile from "components/drag-drop-image";
import { debounce, isArray } from "lodash";
import LinkButton from "components/linkButton/LinkButton";
import productsApi from "apis/productsApi";

const { Title } = Typography;
const { Column } = Table;
const { Option } = Select;

const MakePurchase = () => {
  const [complete, setComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [activePurchaseItem, setActivePurchaseItem] = useState(null); // active id
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // <-- array
  const [purchaseItem, setPurchaseItem] = useState([]);
  const [gotProofOfPurchase, setGotProofOfPurchase] = useState([]);
  const { search } = useLocation();
  const purchaseOrderId = queryString.parse(search).purchaseOrderId;
  const { t } = useTranslation();
  const [uploaded, setUploaded] = useState(false);
  const [loadingVariantImage, setLoadingVariantImage] = useState(false);

  // selected rows
  const rowSelection = {
    onChange: (selectedRowKeys) => {
      console.log("ACTIVE PURCHASE ITEM", selectedRowKeys);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  // const getProductItem = () => {
  //   setIsLoading(true);
  //   Promise.all(
  //     activePurchaseItem.map((pi) => purchaseApi.getPurchaseItem(pi.id))
  //   ).then((res) => console.log("RES UPLOADED", res));
  // };

  const handleGetVariantImage = async (purchaseItems) => {
    setLoadingVariantImage(true);
    try {
      const productShopify = await Promise.all(purchaseItems?.map((item) => (
        productsApi.getProductShopify(item.variant?.product?.id)
      )));

      setPurchaseItem((prev) => (
        prev?.map((item) => {
          const index = purchaseItems.findIndex((variant) => variant.variant?.id === item.variantId);
          if (index === -1) {
            return item;
          }
          const variantImage = productShopify[index].product?.images?.find((image) => (
            image.variant_ids.includes(parseFloat(purchaseItems[index].variant?.shopify_id))
          ))?.src;
          return { ...item, variantImage };
        })
      ));
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoadingVariantImage(false);
    }
  };

  const getProductVariant = () => {
    setIsLoading(true);
    purchaseApi
      .getPurchaseOrder(purchaseOrderId)
      .then((res) => {
        console.log("RES!", res);
        setComplete((prev) => {
          return res.purchaseOrderDetails.purchaseItems
            .map((item) => Number(item.proofOfPurchase) > 0)
            .includes(true);
        });

        console.log("hehe", res.purchaseOrderDetails.purchaseItems);

        setPurchaseItem((prev) => {
          return res.purchaseOrderDetails.purchaseItems.map((item) => {
            return {
              key: item.id,
              id: item.id,
              variantName: `${item.productName} ${item.variantName}`,
              supplier: item.supplier,
              pendingQty: item.pendingQty,
              purchasedQty: item.purchasedQty,
              supplierPrice: item.supplierPrice,
              supplierCurrency: item.supplierCurrency,
              paidFrom: "Credit Card - 8876982268752",
              unitPrice: item.unitPrice,
              variantId: item.variantId,
            };
          });
        });

        setGotProofOfPurchase((prev) => {
          return res.purchaseOrderDetails.purchaseItems.filter(
            (item) => Number(item.proofOfPurchase) >= 0
          );
        });
        setIsLoading(false);
        handleGetVariantImage(res.purchaseOrderDetails.purchaseItems);
      })
      .catch((error) => {
        message.error(error.message, 5);
        setIsLoading(false);
      });
  };

  const saveSubmitHandler = () => {
    setIsLoading(true);

    const newPurchaseItem = purchaseItem.filter((item) => {
      return selectedRowKeys.includes(item.key);
    });

    Promise.all(
      newPurchaseItem.map((item) => {
        const params = {
          purchasedQty: Number(item?.purchasedQty),
          supplierPrice: Number(item?.supplierPrice),
          paidFrom: item?.paidFrom,
        };

        console.log("NEW PURCHASE ITEM", queryString.stringify(params));

        return purchaseApi.putPurchaseItem(
          item.id,
          queryString.stringify(params)
        );
      })
    )
      .then((res) => {
        console.log("PURCHASE ITEM RESPONSE!", res);
        setIsLoading(false);
        res.map(() => {
          return message.success(`Succesfully Update `, 5);
        });
      })
      .catch((error) => {
        setIsLoading(false);
        message.error(error.message, 5);
      });
  };

  const numberChangeHandler = (event, index) => {
    const key = event.target.name;
    const value = event.target.value;
    const newParams = produce(purchaseItem, (draft) => {
      draft[index][key] = Number(value);
    });
    return setPurchaseItem(newParams);
  };

  useEffect(() => {
    getProductVariant();
  }, []);

  useEffect(() => {
    getProductVariant();
  }, [uploaded]);

  return (
    <div>
      <TitleBox className="title-page">
        <Title>{t("Make Purchase")}</Title>
        <Breadcrumbs
          items={[
            { name: "Home", path: "/" },
            {
              name: Path.PENDING_PURCHASE.name,
              path: Path.PENDING_PURCHASE.pathname,
            },
            { name: Path.MAKE_PURCHASE.name },
          ]}
        />
      </TitleBox>
      <Container>
        <Table
          loading={isLoading}
          dataSource={purchaseItem}
          rowSelection={{
            ...rowSelection,
          }}
          pagination={false}
        >
          <Column
            title="Product"
            dataIndex="variantName"
            key="variantName"
            // render={(_, record) => {
            //   return <>2022 Lezhin Black Tiger Is Coming Calendar</>;
            // }}
          />
          <Column
            title="Image"
            dataIndex="variantImage"
            key="variantImage"
            render={(value) => {
              if (loadingVariantImage) {
                return <Spin />;
              }
              if (!value) {
                return (
                  <Avatar size={82} shape="square" src={"/no-image.png"} />
                );
              }
              return (
                <Image width={82} src={value} />
              );
            }}
          />
          <Column
            title="Supplier"
            dataIndex="supplier"
            key="supplier"
            render={(text, record, index) => {
              return (
                <>
                  <Select
                    defaultValue={text}
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      return console.log("this is from supplier", value);
                    }}
                  >
                    <Option>{text}</Option>
                  </Select>
                </>
              );
            }}
          />
          <Column
            title="Pending Quantity"
            dataIndex="pendingQty"
            key="pendingQty"
          />
          <Column
            title="Purchased Quantity"
            dataIndex="purchasedQty"
            key="purchasedQty"
            render={(value, record, index) => {
              return (
                <>
                  <Input
                    name="purchasedQty"
                    addonAfter="PCS"
                    step={1}
                    type="number"
                    value={value}
                    onChange={(event) => numberChangeHandler(event, index)}
                  />
                </>
              );
            }}
          />
          <Column
            title="Supplier Price"
            dataIndex="supplierPrice"
            key="supplierPrice"
            render={(value, record, index) => {
              return (
                <Input
                  name="supplierPrice"
                  addonBefore={record.supplierCurrency}
                  step={0.01}
                  type="number"
                  value={value}
                  onChange={(event) => numberChangeHandler(event, index)}
                />
              );
            }}
          />
          <Column
            title="Margin"
            dataIndex="unitPrice"
            key="unitPrice"
            render={(unitPrice, record, index) => {
              console.log(record);
              const supplierPrice = record.supplierPrice;
              return (
                <>
                  <Input
                    addonBefore={record.supplierCurrency}
                    value={
                      supplierPrice - unitPrice <= 0
                        ? `Loss (${supplierPrice - unitPrice})`
                        : supplierPrice - unitPrice
                    }
                  />
                </>
              );
            }}
          />
        </Table>
        <Divider />
        <Row style={{ marginBottom: "30px" }}>
          <Col md={14} style={{ paddingRight: "30px" }}>
            <h1>Proof of Purchase</h1>
            <DragDropFile
              selectedRowKeys={selectedRowKeys}
              purchaseItem={purchaseItem}
              setUploaded={setUploaded}
            />
          </Col>
          <Col md={10}>
            <h1>Purchase Order Summary</h1>
            <List
              dataSource={gotProofOfPurchase}
              renderItem={(item) => (
                <List.Item>
                  <div>{item.productName}</div>
                  {/* <div>2022 Lezhin Black Tiger Is Coming Calendar</div> */}
                  <ProofOfPurchase>
                    <span
                      className={item.proofOfPurchase ? "available" : "non"}
                    >
                      Proof Of Purchase ID{" "}
                      <strong>
                        {item.proofOfPurchase ? item.proofOfPurchase : "NONE"}
                      </strong>
                    </span>
                  </ProofOfPurchase>
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Row align="end">
          <Space>
            <Button
              disabled={!selectedRowKeys.length}
              type="primary"
              onClick={saveSubmitHandler}
            >
              Save
            </Button>
            <LinkButton
              disabled={!complete}
              type="third"
              to="/purchases/pending-purchases"
            >
              Complete
            </LinkButton>
          </Space>
        </Row>
      </Container>
    </div>
  );
};

export default MakePurchase;

const ProofOfPurchase = styled.div`
  .non,
  .available {
    padding: 5px 13px;
    border-radius: 5px;
    color: white;
  }

  .non {
    background: #cf001c;
  }

  .available {
    background: #005acf;
  }
`;

import { Button, Divider } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import ModalPurchaseOrder from "../ModalPurchaseOrder";

const PurchaseOrderCardItem = ({ index, item }) => {
  const [isModal, setIsModal] = useState(false);
  const {
    orderNumber,
    orderDate,
    purchaser,
    status,
    proofOfPurchase,
    totalPendingQty,
    totalProductCount,
    totalPurchasedQty,
  } = item;

  const modalViewPurchaseOrder = () => {
    setIsModal(!isModal);
  };

  const onOkHandler = () => {
    setIsModal(false);
  };

  const onCancelHandler = () => {
    setIsModal(false);
  };

  // console.log(item);

  return (
    <>
      <ModalPurchaseOrder
        orderNumber={orderNumber}
        proofOfPurchase={proofOfPurchase}
        isModal={isModal}
        onOkHandler={onOkHandler}
        onCancelHandler={onCancelHandler}
      />
      <CardItem>
        <CardTop>
          <span className="title">Purchase Order #{orderNumber}</span>
          <Divider type="vertical" />
          <PendingPill>
            {totalProductCount} Items ({totalPendingQty} Pending Proof of
            Purchase)
          </PendingPill>
        </CardTop>
        <Divider />
        <CardWrapper>
          <CardContent>
            <ul>
              <li>
                <span className="grey-text">Created By: </span>
                <span className="bold-text">{purchaser}</span>
              </li>
              <li>
                <span className="grey-text">Created: </span>
                <span className="bold-text">{orderDate}</span>
              </li>
            </ul>
            <ul>
              <li>
                <span className="grey-text">Total Purchase: </span>
                <span className="bold-text">{totalPurchasedQty}</span>
              </li>
              <li>
                <span className="grey-text">Status: </span>
                <span className="tag">{status}</span>
              </li>
            </ul>
          </CardContent>
          <Button onClick={modalViewPurchaseOrder}>View Purchase Order</Button>
        </CardWrapper>
      </CardItem>
    </>
  );
};

export default PurchaseOrderCardItem;

const CardTop = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  .title {
    font-weight: 600;
    font-size: 1.4rem;
  }
`;
const PendingPill = styled.div`
  background-color: red;
  padding: 5px 15px;
  border-radius: 20px;
  color: white;
`;

const CardItem = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 5px 4.5px 10px -0.5px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 5px 4.5px 10px -0.5px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 5px 4.5px 10px -0.5px rgba(0, 0, 0, 0.19);

  .grey-text {
    color: #686868;
  }
  .bold-text {
    color: #000000;
    font-weight: 600;
  }
  .tag {
    padding: 3px 10px;
    border-radius: 13px;
    color: white;
    background-color: #2cabe3;
  }
`;

const CardContent = styled.div`
  width: 100%;
  display: flex;
  ul {
    width: 100%;
  }
  li {
    margin-bottom: 10px;
  }
  .grey-text {
    color: #686868;
  }
  .bold-text {
    color: #000000;
    font-weight: 600;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  width: 100%;
`;

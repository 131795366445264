import React from "react";
import ReactDOM from "react-dom";
import { message } from "antd";
import axiosClient from "./apis/axiosClient";
import i18n from "./locales/i18n";
import MainApp from "./app/MainApp";

import "./assets/styles/root-style.less";
import "antd/dist/antd.less";

import reportWebVitals from "./reportWebVitals";

message.config({
  top: 10,
  maxCount: 1,
});

axiosClient.defaults.headers.common = {
  // 'X-Server-Key': LocalStorage.has('token') ? `${LocalStorage.get('token')}` : 'KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy',
  "X-Server-Key":
    "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
  "Accept-Language": i18n.language,
  "Access-Control-Allow-Origin": "*",
};

ReactDOM.render(
  <React.StrictMode>
    <MainApp />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();

import { Button } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

const LinkButton = ({ history, to, onClick, ...rest }) => {
  return (
    <Button
      {...rest}
      onClick={event => {
        onClick && onClick(event)
        history.push(to)
      }}
    />
  )
}

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.any,
  onClick: PropTypes.func
}

export default withRouter(LinkButton)

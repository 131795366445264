import {
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Typography,
  Upload,
} from "antd";
import productsApi from "apis/productsApi";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import ProductVariant from "../ProductVariant";

const { Title } = Typography;
const { Search, TextArea } = Input;

const ProductDetails = ({
  addedOrder,
  setAddedOrder,
  setIsModal,
  setCreateNewProduct,
  inputChangeHandler,
}) => {
  const { t } = useTranslation();

  // -2
  // data type : Object
  const [currentTempProduct, setCurrentTempProduct] = useState({});
  const [productParams, setProductParams] = useState({
    title: "",
    page: 1,
    pageSize: 10,
  });

  // -1
  const addOrderBtn = (variantIndex) => {
    // setAddedOrder with current selected Product
    setAddedOrder((prev) => [
      ...prev,
      currentTempProduct.variants[variantIndex],
    ]);
    message.success("Variant Was Added", 1);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const handleChange = ({ fileList }) => this.setState({ fileList });

  const productSearch = (value) =>
    setProductParams((prev) => {
      return { ...prev, title: value };
    });

  const selectedProduct = async () => {
    try {
      let filter = [];
      if (productParams.title) {
        filter.push(`title_eq=${productParams.title}`);
      }
      const paramsRequest = {
        page: 1,
        pageSize: 1,
        filter,
      };
      const res = await productsApi.getProducts(paramsRequest);
      const resSingleProduct = await productsApi.getProductById(
        res.products[0]?.id
      );
      console.log("Response single product", resSingleProduct.product);
      setCurrentTempProduct(resSingleProduct.product);
    } catch (error) {
      message.error(error, 3);
    }
  };

  useEffect(() => {
    selectedProduct();
  }, [productParams.title]);

  return (
    <>
      {/* Product Details */}
      <Card style={{ marginBottom: "24px" }}>
        <Title level={5}>{t("Product Details")}</Title>
        <Form.Item
          label="Search by name"
          extra="You need to type exact product's name in database"
          style={{ marginBottom: 0 }}
        >
          <Search onSearch={productSearch} />
        </Form.Item>
        <Divider />
        <Title level={5}>{t("Variants")}</Title>
        <ProductVariant
          variants={currentTempProduct?.variants}
          setIsModal={setIsModal}
          addOrderBtn={addOrderBtn}
        />
        <Divider />

        {/* Sub details  */}
        <Title level={5}>{t("Details")}</Title>
        <Row gutter={16}>
          <Col xs="auto" md={12}>
            <Form.Item label="Title">
              <Input
                value={currentTempProduct?.title}
                name="title"
                onChange={inputChangeHandler}
              />
            </Form.Item>
          </Col>
          <Col xs="auto" md={12}>
            <Form.Item label="Vendor">
              <Input
                value={currentTempProduct?.vendor}
                name="vendor"
                onChange={inputChangeHandler}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs="auto" md={12}>
            <Form.Item label="Tags">
              <Input
                value={currentTempProduct?.tags}
                name="tags"
                onChange={inputChangeHandler}
              />
            </Form.Item>
          </Col>
          <Col xs="auto" md={12}>
            <Form.Item label="Type">
              <Input
                value={currentTempProduct?.type}
                name="vendor"
                onChange={inputChangeHandler}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs="auto" md={24}>
            <Form.Item label="Note">
              <TextArea
                rows={4}
                value={currentTempProduct?.note}
                name="note"
                onChange={inputChangeHandler}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item label="Images">
            {/* fix images ni kenapa x display  */}
            {/* identify apa data type dia  */}
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={currentTempProduct?.images}
              onPreview={handlePreview}
              onChange={handleChange}
            ></Upload>
          </Form.Item>
        </Row>
        <Row gutter={16}>
          <Col md={24}>
            <Form.Item label="Description" style={{ marginBotton: 0 }}>
              <ReactQuill
                value={currentTempProduct?.bodyHtml || ""}
                style={{ width: "100%" }}
                onChange={(content) => {
                  return setCreateNewProduct((prev) => {
                    return { ...prev, bodyHtml: content };
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ProductDetails;

import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import React from 'react'
import styled from 'styled-components'

const { Option } = Select
const { RangePicker } = DatePicker

const FilterPurchaseOrder = () => {
  return (
    <FilterWrapper>
      <Form>
        <Row gutter={24}>
          <Col md={8}>
            <Form.Item label='Date'>
              <RangePicker format='YYYY-MM-DD' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item label='Purchaser'>
              <Input placeholder="Purchaser's name" />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item label='Status'>
              <Select placeholder='Status'>
                <Option>Complete</Option>
                <Option>Save</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row align='end'>
          <Button size='default' type='primary'>
            Filter
          </Button>
        </Row>
      </Form>
    </FilterWrapper>
  )
}

export default FilterPurchaseOrder

const FilterWrapper = styled.div`
  padding: 23px;
  background-color: #f3f3f3;
`

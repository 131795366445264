import { Breadcrumb as BaseBreadcrumb } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { StyledBreadcrumb } from './Breadcrumb.styles'

function Breadcrumb({ items }) {
  const { t } = useTranslation()
  return (
    <StyledBreadcrumb className='breadcrumb' separator='>'>
      {items.map((item, index) => (
        <BaseBreadcrumb.Item key={index}>
          {(item?.path || item?.pathname) ? (
            <Link to={item.path || item?.pathname}>{t(item.name)}</Link>
          ) : (
            t(item.name)
          )}
        </BaseBreadcrumb.Item>
      ))}
    </StyledBreadcrumb>
  )
}

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        path: PropTypes.string,
        name: PropTypes.string,
      }),
    ])
  ),
}

export default Breadcrumb

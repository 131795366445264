import styled from "styled-components";

export const CourierFormStyle = styled.div`
  .actions {
    .ant-space {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
`;

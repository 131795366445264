import { Icon } from "@iconify/react";
import { Button, Card, Divider, Space, Typography, message } from "antd";
import HarumioLogo from "components/svg/HarumioLogo";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import lodashMap from "lodash.map";

const { Title, Paragraph } = Typography;

const OrderSummary = ({
  params,
  addedOrder,
  setAddedOrder,
  confirmedCreateOrder,
  salesFunnel,
  isLoading,
}) => {
  const { t } = useTranslation();

  // remove item already in
  const removeOrderBtn = (variantIndex) => {
    setAddedOrder((prev) => {
      return prev.filter((item, index) => index !== variantIndex);
    });
    message.info(`${addedOrder[variantIndex].title} Was Removed`, 1);
  };

  return (
    <Card style={{ marginBottom: "24px" }}>
      <div
        style={{
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "25% 75%",
        }}
      >
        <HarumioLogo width={70} height={70} />
        <Title level={1} style={{ margin: 0 }}>
          {t("HARUMIO")}
        </Title>
      </div>
      <Divider />
      <Space direction="vertical" style={{ marginBottom: 0 }}>
        <Title level={5}>{t("Sales Funnel")}</Title>
        {salesFunnel.toLowerCase() === "custom" ? (
          <HarumioLogo width="70" height="70" />
        ) : !salesFunnel ? (
          "Please Select Sales Funnel"
        ) : (
          <Icon
            icon={`logos:${salesFunnel.toLowerCase()}`}
            width="70"
            height="70"
          />
        )}
      </Space>
      <Divider />
      {/* created and modified records  */}
      <Space
        direction="horizontal"
        style={{ marginBottom: 0 }}
        align="baseline"
        size="small"
      >
        <div>
          <Title level={5}>{t("Created")}</Title>
          <div>
            <Paragraph>{"Staff Name"}</Paragraph>
            <Paragraph>{"12/08/2021 @ 8.09AM(KST)"}</Paragraph>
          </div>
        </div>
        <div>
          <Title level={5}>{t("Modified")}</Title>
          <div>
            <Paragraph>{"Staff Name"}</Paragraph>
            <Paragraph>{"12/08/2021 @ 8.09AM(KST)"}</Paragraph>
          </div>
        </div>
      </Space>
      <Divider />
      {/* receiver details  */}
      <Space
        direction="vertical"
        style={{ marginBottom: 0 }}
        align="baseline"
        size="small"
      >
        <Title level={5} style={{ margin: 0 }}>
          {t("Receiver Details")}
        </Title>
        <TableForm>
          <Paragraph>
            <Icon icon="zmdi:account-circle" />
          </Paragraph>
          <Paragraph>{params.customerFirstName}</Paragraph>
          <Paragraph>
            <Icon icon="zmdi:phone" />
          </Paragraph>
          <Paragraph>{params.customerPhone}</Paragraph>
          <Paragraph>
            <Icon icon="zmdi:email" />
          </Paragraph>
          <Paragraph>{params.customerEmail}</Paragraph>
        </TableForm>
      </Space>
      <Divider />
      {/* sender details  */}
      <Space
        direction="vertical"
        style={{ marginBottom: 0 }}
        align="baseline"
        size="small"
      >
        <Title level={5} style={{ margin: 0 }}>
          {t("Sender Details")}
        </Title>
        <TableForm>
          <Paragraph>
            <Icon icon="zmdi:account-circle" />
          </Paragraph>
          <Paragraph> Jonathan Dormer</Paragraph>
          <Paragraph>
            <Icon icon="zmdi:phone" />
          </Paragraph>
          <Paragraph>000789651</Paragraph>
          <Paragraph>
            <Icon icon="zmdi:email" />
          </Paragraph>
          <Paragraph>john.doe@mail.com</Paragraph>
          <Paragraph>
            <Icon icon="zmdi:home" />
          </Paragraph>
          <Paragraph>
            789 Tesla Avenue, 90210, Miami,California, United States,
          </Paragraph>
        </TableForm>
      </Space>
      <Divider />
      {/* Order Product */}
      <Space direction="vertical" style={{ width: "100%" }} size="small">
        <Title level={5} style={{ margin: 0 }}>
          {t("Order Product")}
        </Title>
        <TableFormOrder>
          {addedOrder.length > 0
            ? addedOrder.map((orderItem, index) => (
                <div className="orderList" key={index}>
                  <Paragraph>{orderItem?.title}</Paragraph>
                  <Paragraph>
                    {orderItem?.currency} {orderItem?.price}
                  </Paragraph>
                  <Button onClick={() => removeOrderBtn(index)}>Delete</Button>
                </div>
              ))
            : "Empty Order"}
        </TableFormOrder>

        {/* reserve spot for free gift  */}

        <TableFormOrder>
          <div className="orderList">
            <Paragraph style={{ fontWeight: 600, fontSize: "1.1rem" }}>
              {" "}
              {t("Grand Total")}
            </Paragraph>
            <Paragraph style={{ fontWeight: 600, fontSize: "1.1rem" }}>
              {addedOrder[0]?.currency}{" "}
              {lodashMap(addedOrder, "price").length > 0 &&
                lodashMap(addedOrder, "price").reduce(
                  (prev, curr) => Number(prev) + Number(curr)
                )}
            </Paragraph>
          </div>
        </TableFormOrder>
      </Space>
      <Space style={{ marginTop: "10px", width: "100%" }}>
        <Button
          loading={isLoading}
          onClick={confirmedCreateOrder}
          size="large"
          style={{ width: "100%" }}
        >
          Confirmed
        </Button>
      </Space>
    </Card>
  );
};

export default OrderSummary;

const TableForm = styled.div`
  margin-top: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: 7% 93%;
  column-gap: 10px;
  row-gap: 15px;
`;
const TableFormOrder = styled.div`
  width: 100%;
  .orderList {
    padding-top: 14px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

// Uncomment this if Kaamesh want Free Gift feature and paste them under the products orders
//  <Title level={5} style={{ margin: 0 }}>
//           {t('Free Gifts')}
//         </Title>
//         <TableFormOrder>
//           <Paragraph>
//             Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quis,
//             debitis!
//           </Paragraph>
//           <Paragraph>RM 10</Paragraph>
//           <Paragraph>
//             Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quis,
//             debitis!
//           </Paragraph>
//           <Paragraph>RM 10</Paragraph>
//         </TableFormOrder>

import moment from "moment";
import momentTz from "moment-timezone";
import errorHelper from "./errorHelper";
import kodNegara from "kod-negara";

export const twoDecimal = (number) =>
  (Math.round(number * 100) / 100).toFixed(2);

export const numberWithCommas = (x) => {
  return x.toLocaleString();
};

export const generateIdWithChar = () => {
  const charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var randomString = "";
  for (let i = 0; i < 4; i++) {
    const randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }

  return `#${randomString}_${(Math.random() * (10000 - 1000) + 1000).toFixed(
    0
  )}`;
};

export const alertUndefinedObj = (
  object,
  messageForUndefined = "has undef"
) => {
  const obj = { ...object };
  for (const key in obj) {
    if (obj[key] === undefined) {
      alert(`${key} ${messageForUndefined}`);
      return true;
    }
  }
};

export const gramToKg = (gram) => {
  return gram / 1000;
};

export const generateRandom = (min, max) => {
  const random = Math.random() * (max - min) + min;
  return random.toFixed(0);
};
// activeCurrency: String = the activated currency in settings
// allRates: Object = all USD base rates that u get from currencyExchangeApi
// responseRate: String = the currecy code Eg. MYR, KRW, USD u get from BE responses
// value: Number = the value u get from BE responses
export const currencyExchangeFunc = (
  activeCurrency,
  respondedCurrency,
  allRates,
  value
) => {
  const ratio = Number(value) / Number(allRates[respondedCurrency]);

  if (activeCurrency === "USD") {
    return Number(ratio);
  } else {
    return Number(ratio) * Number(allRates[activeCurrency]);
  }
};

export const validFunc = (key, arr) => {
  return !arr?.map((item) => Number(item[key])).some((item) => isNaN(item));
};

export const getCountryObj = (countryName) => {
  return kodNegara.findOne("countryNameEn", countryName);
};

export const getCountryCode = (countryName) => {
  if (countryName.toLowerCase() === "united states") {
    return kodNegara.findOne("countryNameEn", "United States of America")
      ?.countryCode;
  } else {
    return kodNegara.findOne("countryNameEn", countryName)?.countryCode;
  }
};

export const getCountryNameWithCode = (countryName) => {
  const country = kodNegara.findOne("countryNameEn", countryName);
  return `${country["countryNameEn"]} (${country["countryCode"]})`;
};

export const formatToCurrency = (currency, amount) => {
  if (currency.toUpperCase() === "KRW") {
    return String(currency) + " " + Number(amount).toLocaleString();
  } else {
    return String(currency) + " " + Number(amount).toFixed(2).toLocaleString();
  }
};

export const genId = () => Math.random().toString(36).substr(2, 9);

export const check2ArrayPermission = (
  arrPermissionAccount = [],
  arrPermissionFeature = []
) => {
  if (arrPermissionFeature.length === 0) {
    return true;
  }

  return arrPermissionFeature.some((per) => arrPermissionAccount.includes(per));
};

export const checkRuleKey = (ruleCheck, arrKey = []) => {
  if (arrKey.length === 0) {
    return true;
  }

  return arrKey.find((rule) => rule === ruleCheck);
};

export const getPeriod = (expired) => {
  const expiredMoment = moment(expired).startOf("date");
  const now = moment().startOf("date");
  const years = expiredMoment.diff(now, "years");
  const months = expiredMoment.diff(now, "months") - years * 12;
  let period = "";
  if (expiredMoment.isSameOrBefore(now)) {
    return "Expired";
  }
  if (years > 0) {
    period = `${years} year${years > 1 ? "s" : ""}`;
  }
  if (months > 0) {
    period += `${period ? " " : ""}${months} month${months > 1 ? "s" : ""}`;
  }
  if (years === 0 && months === 0) {
    period = "less than 1 month";
  }
  return period;
};

export const getYearMonthPassportRemaining = (expired) => {
  const expiredMoment = moment(expired).startOf("date");
  const now = moment().startOf("date");
  const years = expiredMoment.diff(now, "years");
  const months = expiredMoment.diff(now, "months") - years * 12;

  return {
    years,
    months,
  };
};

export const formatCurrency = (value, separate = ",", decimal = 2) =>
  value
    ? value
        .toFixed(decimal)
        .replace(/\d(?=(\d{3})+(?!\d))/g, `$&${separate}`)
        .replace(".00", "")
    : value;

export const removeBlankNull = (objValue) => {
  const value = { ...objValue };
  // eslint-disable-next-line no-restricted-syntax
  for (const param in value) {
    if (value[param] === null || value[param] === "") {
      delete value[param];
    } else if (Array.isArray(value[param]) && value[param].length === 0) {
      delete value[param];
    }
  }
  return value;
};

export const renderPrice = (cell) => {
  if (cell === null || cell === undefined || cell === "") {
    return "";
  }
  return `${formatCurrency(cell)} 円`;
};
export const renderDate = (cell) => moment(cell).format("YYYY-M-DD HH:mm:ss");

export const disabledEndDate = (current, startTime) => {
  if (!current || !startTime) {
    return false;
  }
  const startDate = startTime.clone();
  return current < startDate.startOf("day");
};

export function disabledBeforeNow(current) {
  // Can not select days before today
  return current && current < moment().startOf("day");
}
export function handleErrorCallback(api, callback = () => null) {
  // Can not select days before today
  api
    .catch((err) => {
      errorHelper(err);
    })
    .finally(() => {
      callback();
    });
}

export { moment, momentTz };

import axiosClient from "./axiosClient";

const purchaseApi = {
  putPurchaseItem: (id, params) =>
    axiosClient.put(`/api/purchasing/purchase-item/${id}?${params}`),
  getListOrders: (payload) =>
    axiosClient.get("/api/purchasing/list-orders", { params: payload }),
  getListPendingVariants: (payload) =>
    axiosClient.get("/api/purchasing/list-pending-variants", {
      params: payload,
    }),
  getSupplier: (payload, id) =>
    axiosClient.get(`/api/purchasing/supplier/${id}`, { params: payload }),
  postCreateSupplier: (payload) =>
    axiosClient.post(`/api/purchasing/create-supplier`, { params: payload }),
  getPurchaseOrder: (id) =>
    axiosClient.get(`/api/purchasing/purchase-order/${id}`),
  postMakePurchaseOrder: (payload) =>
    axiosClient.post("/api/purchasing/make-purchase-order", payload),
  getPurchaseItem: (id) =>
    axiosClient.get(`/api/purchasing/get-purchase-item/${id}`),
  getListPurchaseOrders: (payload) =>
    axiosClient.get(`/api/purchasing/list-purchase-orders`, {
      params: payload,
    }),
  putUpdateProofOfPurchase: (id, proofId) =>
    axiosClient.put(
      `/api/purchasing/update-proof-of-purchase/${id}/${proofId}`
    ),
};

export default purchaseApi;

import {
  Button,
  Divider,
  Space,
  Card,
  Form,
  Row,
  Col,
  Input,
  Select,
  Table,
  message,
} from "antd";
import shipmentsApi from "apis/shipmentsApi";
import Box from "components/box/Box";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Path from "../../commons/breadcrumbs";
import kodNegara from "kod-negara";

const ShipperDetails = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [form] = Form.useForm();

  const getListShippers = async () => {
    setIsLoading(true);
    try {
      const payload = {
        page: 1,
        pageSize: 10,
      };
      const { shipper_details, totalCount } = await shipmentsApi.getListShipper(
        payload
      );
      if (shipper_details) {
        const restructure = shipper_details.map((item) => {
          return { ...item, key: item.id };
        });
        setData(restructure);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const addShipperDetails = async (value) => {
    setIsLoading(true);
    try {
      const payload = {
        name: value.name,
        phonenumber: value.phonenumber,
        utcOffset: "+09:00",
        email: value.email,
        address1: value.address1,
        address2: value.address2,
        city: value.city,
        postal: value.postal,
        provinceCode: value.provinceCode,
        countryCode: value.countryCode,
        country: value.country,
      };
      await shipmentsApi.addShipperDetails(payload);
      getListShippers();
      message.success("Succesfully added");
      setIsLoading(false);
    } catch (error) {
      message.error(error.message);
      setIsLoading(false);
    }
  };

  const removeShipperDetails = async (rows) => {
    console.log(rows.id);
    setIsLoading(true);
    try {
      await shipmentsApi.removeShipperDetails({ id: rows.id });
      getListShippers();
      setIsLoading(false);
      message.success("Succesfully delete");
    } catch (error) {
      setIsLoading(false);
      message.error(error.message);
    }
  };

  console.log(form.getFieldsValue());

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Postal Code",
      dataIndex: "postal",
      key: "postal",
    },
    {
      title: "Country Code",
      dataIndex: "countryCode",
      key: "countryCode",
    },
    {
      title: "Account",
      key: "account",
      render: (value, records) => (
        <Button type="danger" onClick={() => removeShipperDetails(records)}>
          Delete
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getListShippers();
  }, []);

  return (
    <>
      <TitleHeader
        listOfBreadcrumb={[
          { name: "Home", path: "/" },
          { name: Path.SHIPPER_DETAILS.name },
        ]}
        title={t(Path.SHIPPER_DETAILS.name)}
      />
      <Box>
        <Container>
          <Card title="Available Shipper List">
            <Table
              dataSource={data}
              columns={columns}
              rowSelection={{
                onChange: (selectedRowKeys, selectedRows) => {
                  return setSelectedRow(selectedRows);
                },
              }}
            />
          </Card>
          <Divider />
          <Card title="Add Shipper" style={{ marginBottom: "24px" }}>
            <Form layout="vertical" form={form} onFinish={addShipperDetails}>
              <Row gutter={16}>
                <Col md={24}>
                  <p>
                    Shopify and your customers will use this information to
                    contact you.
                  </p>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="name"
                    label="Shipper Name"
                    rules={[
                      {
                        required: true,
                        message: "Name is required",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input placeholder="Sender's name" />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="email"
                    label="Shipper Email"
                    rules={[
                      {
                        required: true,
                        message: "Name is required",
                      },
                      {
                        type: "email",
                        message: "Shipper email is not valid",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input placeholder="Company's email" />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="phonenumber"
                    label="Shipper Phone"
                    rules={[
                      {
                        required: true,
                        message: "Contact number is required",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input placeholder="Company Phone Number" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={8}>
                  <Form.Item
                    name="address1"
                    label="Address 1"
                    rules={[
                      {
                        required: true,
                        message: "Address 1 is required",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input placeholder="Your Shipper name" />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="shipperAddressTwo"
                    label="Address 2"
                    rules={[
                      {
                        required: true,
                        message: "Address 2 is required",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input placeholder="Your Shipper name" />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="city"
                    label="City"
                    rules={[
                      {
                        required: true,
                        message: "City is required",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input placeholder="Your Shipper name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={8}>
                  <Form.Item
                    name="postal"
                    label="Postal"
                    rules={[
                      {
                        required: true,
                        message: "Postal is required",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input placeholder="Your Shipper name" />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="provinceCode"
                    label="Province Code"
                    rules={[
                      {
                        required: true,
                        message: "Province code is required",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input placeholder="Your Shipper name" />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="countryCode"
                    label="Country Code"
                    rules={[
                      {
                        required: true,
                        message: "Country Code",
                      },
                    ]}
                    initialValue={"KR"}
                    hasFeedBack
                  >
                    <Select>
                      {kodNegara.all().map((negara) => {
                        return (
                          <Select.Option value={negara.countryCode}>
                            {negara.countryNameEn} ({negara.countryCode})
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Space>
                <Button
                  type="primary"
                  disabled={isLoading}
                  htmlType="submit"
                  loading={isLoading}
                >
                  Add Shipper
                </Button>
              </Space>
            </Form>
          </Card>
          <Divider />
        </Container>
      </Box>
    </>
  );
};

export default ShipperDetails;

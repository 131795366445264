export const fakeDataUsers = [
  {
    id: 1,
    firstName: 'Kaamesh',
    lastName: 'Kaamesh',
    email: 'kaamesh.kamalaaharan@gmail.com',
    password: 'jhjgyyaadw76836436832o4ajsgalkgdas',
    isAdmin: true,
    isOwner: true,
    bio: '',
    lastLogin: '2021-11-24:11:07:34',
    personalWebsite: '',
    lang: 'en',
    regionalFormat: 'MY',
    permissionsPage: [
      'Order Details',
      'Orders Management',
      'Shipment Management',
      'Create Shipment',
      'Product Detail',
      'Products',
      'Store Details',
      'Users And Permissions',
      'Sales And Channels',
      'Languages',
      'Policies',
      'Add Staff',
      'Edit Staff',
    ],
  },
  {
    id: 2,
    firstName: 'Sharon',
    lastName: '',
    email: 'sharonc1928@gmail.com',
    password: 'jhjgyyaadw76836436832o4ajsgalkgdas',
    isAdmin: true,
    isOwner: false,
    bio: '',
    lastLogin: '2021-11-24:11:07:34',
    personalWebsite: '',
    lang: 'en',
    regionalFormat: 'MY',
    permissionsPage: [
      'Order Details',
      'Orders Management',
      'Shipment Management',
      'Create Shipment',
      'Product Detail',
      'Products',
      'Store Details',
      'Users And Permissions',
      'Sales And Channels',
      'Languages',
    ],
  },
  {
    id: 3,
    firstName: 'Ahmad Hazim',
    lastName: 'Ahmad Fuad',
    email: 'kaamesh.kamalaaharan@gmail.com',
    password: '12345',
    isAdmin: true,
    isOwner: false,
    bio: '',
    lastLogin: '2021-11-24:11:07:34',
    personalWebsite: '',
    lang: 'en',
    regionalFormat: 'MY',
    permissionsPage: [
      'Order Details',
      'Orders Management',
      'Shipment Management',
      'Create Shipment',
      'Product Detail',
      'Products',
      'Store Details',
      'Users And Permissions',
      'Sales And Channels',
      'Languages',
    ],
  },
  {
    id: 4,
    firstName: 'F',
    lastName: 'D',
    email: 'jhjgyyaadw76836436832o4ajsgalkgdas',
    password: '12345',
    isAdmin: true,
    isOwner: false,
    bio: '',
    lastLogin: '2021-11-24:11:07:34',
    personalWebsite: '',
    lang: 'en',
    regionalFormat: 'MY',
    permissionsPage: [
      'Order Details',
      'Orders Management',
      'Shipment Management',
      'Create Shipment',
      'Product Detail',
      'Products',
      'Store Details',
      'Users And Permissions',
      'Sales And Channels',
      'Languages',
    ],
  },
]

import Box from "components/box/Box";
import { Checkbox, Space, Radio, Typography } from "antd";
import styled from "styled-components";

const { Paragraph } = Typography;

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  background-color: white;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 7px;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background: #f9f9f9;
  }

  &::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 10px;
  }
`;

export const VariantsList = styled.div`
  display: fled;
  align-items: center;
  border-bottom: 1px dotted #f0f0f0;
  width: 100%,
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 7px;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background: #f9f9f9;
  }

  &::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 10px;
  }
`;

export const ListHeader = styled.div`
  margin-bottom: "10px";
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: #7f7f7f;
  }
`;

export const ProductDescription = styled(Paragraph)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const RadioStyled = styled(Radio)`
  line-height: 32px;
`;

export const CheckboxGroupStyled = styled(Checkbox.Group)`
  .ant-checkbox-group-item {
    display: flex;
    border: 1px solid #d9d9d9;
    padding: 2px 0 2px 6px;
    border-radius: 6px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  background-color: #edf1f5;
  padding: 12px 24px;
  border-bottom: 1px solid #78828c21;
  h1 {
    font-size: 16px;
    text-transform: uppercase;
    color: #3e5569;
    font-weight: 600;
    margin-bottom: 0;
  }
`;

export const ProductPageStyled = styled(Box)`
  .statistic {
    .ant-col:nth-child(1) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #707cd2;
      }
      .ant-card-body {
        background-color: #c6cdfd;
      }
    }
    .ant-col:nth-child(2) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #ffc36d;
      }
      .ant-card-body {
        background-color: #ffedd4;
      }
    }
    .ant-col:nth-child(3) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #2cd07e;
      }
      .ant-card-body {
        background-color: #b2ffd9;
      }
    }
    .ant-col:nth-child(4) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #ff5050;
      }
      .ant-card-body {
        background-color: #ffcaca;
      }
    }
    .ant-col:nth-child(5) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #707cd2;
      }
      .ant-card-body {
        background-color: #c6cdfd;
      }
    }
    .ant-col:nth-child(6) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #ffc36d;
      }
      .ant-card-body {
        background-color: #ffedd4;
      }
    }
    .ant-col:nth-child(7) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #2cd07e;
      }
      .ant-card-body {
        background-color: #b2ffd9;
      }
    }
    .ant-col:nth-child(8) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #ff5050;
      }
      .ant-card-body {
        background-color: #ffcaca;
      }
    }
    .ant-col:nth-child(9) {
      .ant-statistic-content-value-int,
      .ant-statistic-title {
        color: #ff5050;
      }
      .ant-card-body {
        background-color: #ffcaca;
      }
    }
    .ant-card {
      margin-right: 20px;
      transition: all 0.25s ease;
      border: 0 solid rgba(0, 0, 0, 0.125);
      &:hover {
        transform: translateY(-4px) scale(1.01);
        box-shadow: 0 14px 24px rgb(62 57 107 / 10%);
      }
    }
    .ant-card-body {
      padding: 8px;
      .ant-statistic {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        .ant-statistic-content-value-int {
          font-size: 36px;
          font-weight: normal;
        }
        .ant-statistic-title {
          font-size: 14px;
        }
      }
    }
  }
`;

export const ActionBox = styled(Space)`
  &.action-box {
    button {
      min-width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      &.btn-update {
        border-color: #d9d9d9;
      }
    }
  }
`;

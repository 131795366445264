import styled from 'styled-components'

export const BoxContainer = styled.div`
  &.container-box {
    min-height: 100vh;
    height: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #edf1f5;
    padding: 24px;
  }
`

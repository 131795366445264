import {
  Button,
  Col,
  Divider,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
  Form,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import shipmentsApi from "../../apis/shipmentsApi";
import Path from "../../commons/breadcrumbs";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Container from "../../components/container/Container";
import ShipmentTable from "./ShipmentTable";
import ShipmentFilterForm from "./ShipmentFilterForm";
import { DownloadButton, ShipmentPageStyled, TitleBox } from "./index.style";
import LiveFeedsData from "./LiveFeedsData";
import useStateCallback from "hooks/useStateCallback";
import { useHistory } from "react-router";
import queryString from "query-string";
import { moment, removeBlankNull } from "../../utils/funcs";
import axios from "axios";

const { Title } = Typography;

const Shipments = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [labelLoading, setLabelLoading] = useState(false);
  const [data, setData] = useState({});
  const { t } = useTranslation();
  const [init, setInit] = useState(false);
  const [params, setParams] = useStateCallback({
    page: 1,
    pageSize: 10,
    search: "",
    createdAt: [],
    courier: [],
    sort: ["id_desc"],
  });

  const [labelModal, setLabelModal] = useState({
    label: {
      status: false,
      payload: [],
    },
    invoice: {
      status: false,
      payload: "",
    },
  });

  // fetch shipment data
  const getShipments = async (callback = () => null) => {
    setIsLoading(true);
    const filter = [];
    if (params.courier?.length) {
      params.courier?.map((item) => filter.push(item));
    }
    if (params.createdAt?.length) {
      filter.push(
        `createdAt_gte=${params.createdAt[0]
          .clone()
          .startOf("date")
          .utc()
          .format()}`
      );
      filter.push(
        `createdAt_lte=${params.createdAt[1]
          .clone()
          .endOf("date")
          .utc()
          .format()}`
      );
    }

    const param = {
      page: params.page,
      pageSize: params.pageSize,
      search: params.search,
      filter,
      fields: [
        "id",
        "orderId",
        "sourceId",
        "courier",
        "airwayBillNumber",
        "pltStatus",
        "payload",
        "shippingFee",
        "printedCount",
        "invoiceBase64",
        "labelBase64",
        "labelZpl",
        "status",
      ],
      sort: params.sort,
    };
    try {
      const res = await shipmentsApi.getShipments(param);
      if (res === undefined) {
        return alert("Shipment is undefined");
      }
      const { shipment, totalCount } = res;
      const reformShipment = shipment?.map((item, index) => {
        return { ...item, key: index };
      });
      setData((prev) => {
        return { ...prev, shipment: reformShipment, totalCount };
      });
    } catch (error) {
      message.error(error, 3);
    } finally {
      setIsLoading(false);
      callback();
    }
  };

  const downloadAll = (urls) => {
    const link = document.createElement("a");

    link.setAttribute("download", "label");
    link.style.display = "none";

    document.body.appendChild(link);

    for (let i = 0; i < urls.length; i++) {
      link.setAttribute("href", urls[i]);
      link.click();
    }

    document.body.removeChild(link);
  };

  const downloadExportData = async () => {
    const response = await axios({
      url: `${
        process.env.REACT_APP_API_URL
      }/api/shipment/get-export-list?startDate=2022-01-17T17%3A00%3A00.000Z&endDate=2022-03-18T16%3A59%3A59.999Z&firstTimeDownload=${
        process.env.REACT_APP_DEVELOPMENT ? false : true
      }`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        "X-Server-Key":
          "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
      },
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${response.headers["content-disposition"]
        .split(" ")
        .at(-1)
        .split("=")
        .at(-1)}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    const paramsQuery = queryString.parse(history.location.search);
    const data = removeBlankNull(paramsQuery);
    if (data.page) {
      data.page = parseInt(data.page);
    }
    if (data.createdAt) {
      data.createdAt = [
        moment(data.createdAt[0] + "T00:00Z", "YYYY-MM-DDTHH:mmZ"),
        moment(data.createdAt[1] + "T00:00Z", "YYYY-MM-DDTHH:mmZ"),
      ];
    }

    setParams({ ...params, ...data }, () => {
      setInit(true);
    });
  }, []);

  useEffect(() => {
    // call an api to get new shipment
    const data = removeBlankNull(params);
    delete data.pageSize;
    if (data.page === 1) {
      delete data.page;
    }
    if (data.createdAt?.length) {
      data.createdAt = [
        data.createdAt[0].utc().format("YYYY-MM-DD"),
        data.createdAt[1].utc().format("YYYY-MM-DD"),
      ];
    }
    history.push({
      pathname: "/shipments",
      search: `?${queryString.stringify(data)}`,
    });
    getShipments();
  }, [params, init]);

  return (
    <>
      <Modal
        title="Label"
        footer={
          <Space>
            {/* {labelModal.label.payload?.map((item, index) => (
              <DownloadButton key={index} href={item} download="ShipmentLabel">
                Download
              </DownloadButton>
            ))} */}
            <Button onClick={() => downloadAll(labelModal.label.payload)}>
              Download
            </Button>
          </Space>
        }
        visible={labelModal.label.status}
        onCancel={() =>
          setLabelModal((prev) => {
            const draft = {
              ...prev.label,
              status: false,
            };
            return {
              ...prev,
              label: draft,
            };
          })
        }
        width={900}
      >
        {labelLoading ? (
          <Space>
            <Spin />
          </Space>
        ) : (
          <>
            {labelModal.label.payload.length > 1 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {labelModal.label.payload?.map((item, index) => (
                  <img
                    key={index}
                    src={item}
                    width={400}
                    alt="zplLabel"
                    style={{ padding: "10px" }}
                  />
                ))}
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={labelModal.label.payload[0]}
                  width={800}
                  alt="zplLabel"
                  style={{ padding: "10px" }}
                />
              </div>
            )}
          </>
        )}
      </Modal>
      <Modal
        title="Invoice"
        footer={
          <Space>
            <DownloadButton
              href={`data:application/pdf;base64,${labelModal.invoice.payload}`}
              download="Invoice"
            >
              Download
            </DownloadButton>
          </Space>
        }
        visible={labelModal.invoice.status}
        onCancel={() =>
          setLabelModal((prev) => {
            const draft = {
              ...prev.invoice,
              status: false,
            };
            return {
              ...prev,
              invoice: draft,
            };
          })
        }
        width={800}
      >
        <iframe
          width="100%"
          height={800}
          frameBorder="0"
          src={`data:application/pdf;base64,${labelModal.invoice.payload}`}
          title="invoice"
        ></iframe>
      </Modal>
      <TitleBox className="title-page">
        <Title>{t("Shipments")}</Title>
        <Breadcrumb
          items={[{ name: "Home", path: "/" }, { name: Path.SHIPMENTS.name }]}
        />
      </TitleBox>
      <ShipmentPageStyled title={t("list_product")}>
        <LiveFeedsData />
        <Container>
          <Space>
            <div>
              <Title>Shipment</Title>
              {/* <Paragraph>
                {params.totalPage} shipments was created. To create a new
                shipment, you can go <Link to="/create-shipment">here.</Link>
              </Paragraph> */}
              <Button onClick={downloadExportData}>Download Export Data</Button>
            </div>
          </Space>
          <Divider />
          <Row gutter={24}>
            {/* left side  */}
            <Col sm={24} md={24} lg={6}>
              <ShipmentFilterForm
                setParams={setParams}
                form={form}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </Col>
            {/* Table here  */}
            <Col sm={24} md={24} lg={18}>
              {isLoading ? (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    grid: "10px",
                  }}
                >
                  <Title>Fetching...</Title>
                  <Spin size="large" />
                </div>
              ) : (
                <CardWrapper>
                  <ShipmentTable
                    data={data}
                    params={params}
                    setParams={setParams}
                    getShipments={getShipments}
                    labelModal={labelModal}
                    setLabelModal={setLabelModal}
                    labelLoading={labelLoading}
                    setLabelLoading={setLabelLoading}
                  />
                </CardWrapper>
              )}
            </Col>
          </Row>
        </Container>
      </ShipmentPageStyled>
    </>
  );
};

export default Shipments;
const CardWrapper = styled.div`
  overflow: auto;
`;

import { Icon } from '@iconify/react'
import { Space, message } from 'antd'
import HarumioLogo from 'components/svg/HarumioLogo'
import React from 'react'
import { SalesFunnelButton } from './index.style'

const SalesFunnelBtn = ({ children, setCheckedValue, btnName, check }) => {
  const onClickHandler = (event) => {
    setCheckedValue((prev) => {
      return { ...prev, [btnName]: children.toLowerCase() }
    })
    message.success(`${children} was selected`, 3)
  }

  return (
    <SalesFunnelButton>
      <button onClick={onClickHandler}>
        <Space direction='vertical' align='center'>
          {children.toLowerCase() === 'custom' ? (
            <HarumioLogo width='70' height='70' />
          ) : (
            <Icon
              icon={`logos:${children.toLowerCase()}`}
              width='70'
              height='70'
            />
          )}
          {children}
        </Space>
      </button>
    </SalesFunnelButton>
  )
}

export default SalesFunnelBtn

// Cause I'm thinking where to put the status=transit/received
// in purchase order. Whether in the purchase item or in the
// main order HAHAHA

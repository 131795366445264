import { message, Tabs, Typography } from "antd";
import productsApi from "apis/productsApi";
import purchaseApi from "apis/purchaseApi";
import produce from "immer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { removeBlankNull } from "utils/funcs";
import Path from "../../commons/breadcrumbs";
import Breadcrumbs from "../../components/breadcrumb/Breadcrumb";
import AllPendingPurchases from "./AllPendingPurchase";
import CustomPendingPurchases from "./Custom";
import { CardContainer, TitleBox } from "./index.style";
import ShopifyPendingPurchases from "./Shopify";
const { Title } = Typography;

const { TabPane } = Tabs;

const PendingPurchases = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingVariantImage, setLoadingVariantImage] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    totalPage: null,
    sort: [],
    productFilter: '',
    variantFilter: '',
    orderFilter: [],
  });

  const gettingListPending = async (newParam) => {
    const param = removeBlankNull({ ...params, ...newParam });
    delete param.totalPage;
    if (param.productFilter) {
      param.productFilter = `title_iLike=%${param.productFilter}%`;
    }
    if (param.variantFilter) {
      param.variantFilter = `title_iLike=%${param.variantFilter}%`;
    }
    const { orderFilter } = param;
    if (Array.isArray(orderFilter) && orderFilter.some((item) => item)) {
      param.orderFilter = [];
      if (orderFilter[0]) {
        param.orderFilter.push(`createdAt_gte=${orderFilter[0].utc().startOf('date').format()}`)
      }
      if (orderFilter[1]) {
        param.orderFilter.push(`createdAt_lte=${orderFilter[1].utc().startOf('date').format()}`)
      }
    }
    setIsLoading(true);
    try {
      const res = await purchaseApi.getListPendingVariants(param);
      if (res === undefined) {
        return alert("Response undefined");
      }

      const { pendingProducts, totalCount } = res;

      const reformPendingProds = pendingProducts.map((obj, index) => {
        return { ...obj, key: index };
      });
      setDataList(reformPendingProds);
      setParams((prev) => {
        return {
          ...prev,
          totalPage: totalCount,
        };
      });
      message.success("Succesfully fetch all pending", 5);
      setIsLoading(false);

      setLoadingVariantImage(true);
      const productShopify = await Promise.all(reformPendingProds.map((purchase) => productsApi.getProductShopify(purchase.productId)));
      setDataList((prev) => produce(prev, (draft) => {
        productShopify.forEach((product, index) => {
          const variantImage = product.product?.images?.find((image) => image.variant_ids.includes(parseFloat(draft[index].productVariantShopifyId)))?.src;
          const productImage = product.product?.image?.src;
          draft[index].variantImage = variantImage || productImage;
        });
      }));
      setLoadingVariantImage(false);
    } catch (error) {
      message.error(error.message);
      setIsLoading(false);
      setLoadingVariantImage(false);
    }
  };

  useEffect(() => {
    gettingListPending(params);
  }, []);

  console.log("DATA LISt", dataList);

  return (
    <>
      <TitleBox className="title-page">
        <Title>{t("Pending Purchase")}</Title>
        <Breadcrumbs
          items={[
            { name: "Home", path: "/" },
            { name: Path.PENDING_PURCHASE.name },
          ]}
        />
      </TitleBox>
      <CardContainer>
        <Tabs type="card">
          <TabPane tab="All" key="1">
            <AllPendingPurchases
              params={params}
              setParams={setParams}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              dataList={dataList}
              setDataList={setDataList}
              gettingListPending={gettingListPending}
              loadingVariantImage={loadingVariantImage}
            />
          </TabPane>
          <TabPane tab="Shopify" key="2">
            <ShopifyPendingPurchases />
          </TabPane>
          <TabPane tab="Custom" key="3">
            <CustomPendingPurchases />
          </TabPane>
        </Tabs>
      </CardContainer>
    </>
  );
};

export default PendingPurchases;

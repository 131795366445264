import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import {
  Avatar,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Space,
} from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import accountApis from "../../apis/accountApi";
import { BREAKPOINT } from "../../commons/styles";
import DialogConfirmLogout from "../dialog-confirm-logout/DialogConfirmLogout";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { logout } from "../../store/slices/accountSlice";
import errorHelper from "../../utils/errorHelper";
import LocalStorage from "../../utils/storage";
import { toggedSideBar } from "../../store/slices/commonSlice";
import SideBar from "../sidebar/SideBar";
import { BoxHeader } from "./Header.styles";
import ordersApi from "apis/ordersApi";
import Path from "../../commons/breadcrumbs";

function Header() {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory();
  const { sideBarMobile } = useSelector((state) => state.common);
  const [isLogout, setIsLogout] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const onToggleMenu = () => {
    dispatch(toggedSideBar());
  };

  const onLogout = () => {
    accountApis
      .logout()
      .then(() => {
        dispatch(logout());
        LocalStorage.remove("token");
        history.push("/login");
      })
      .catch((err) => {
        errorHelper(err);
      });
  };

  const menu = (
    <Menu>
      <Menu.Item>Profile</Menu.Item>
      <Menu.Item>
        Logout{" "}
        <LogoutOutlined
          onClick={() => setIsLogout(true)}
          className="icon__logout"
        />
      </Menu.Item>
    </Menu>
  );

  const searchOrder = async (orderNumber) => {
    const filter = [];
    filter.push(`orderNumber_iLike=%${orderNumber}%`);

    const payload = {
      page: 1,
      pageSize: 1,
      filter,
    };
    setIsLoading(true);
    try {
      const res = await ordersApi.getOrders(payload);
      console.log(res);
      if (res.orders && res.orders.length) {
        const orderId = res.orders.find(
          (item) => item.orderNumber === `#${orderNumber}`
        ).id;
        history.push({
          pathname: Path.ORDER_DETAIL.pathParam(orderId),
        });
      } else {
        message.info("No order was found");
      }
      setIsLoading(false);
    } catch (error) {
      message.error(error.message);
      setIsLoading(false);
    }
  };

  const onSearch = (orderNumber, event) => {
    if (orderNumber) {
      searchOrder(orderNumber);
    }
  };

  return (
    <BoxHeader>
      {/* <Form>
        <Form.Item
          style={{ width: "300px", margin: "0 20px", borderRadius: "10px" }}
          name='searchOrderNumber'
          rules={[
            {

            }
          ]}
        >
          <Input.Search placeholder="Search Order Number" />
        </Form.Item>
      </Form> */}

      <Input.Search
        placeholder="Search Order Number"
        style={{ width: "300px", margin: "0 20px", borderRadius: "10px" }}
        onSearch={onSearch}
        loading={isLoading}
        allowClear
      />
      <div />
      <Dropdown overlay={menu} className="box__right-header">
        <Space>
          <Avatar style={{ verticalAlign: "middle" }} size="large">
            Admin
          </Avatar>
          <div>Admin</div>
          <DownOutlined />
        </Space>
      </Dropdown>

      {width <= BREAKPOINT.XL && (
        <Drawer
          placement="left"
          closable={false}
          onClose={onToggleMenu}
          visible={!sideBarMobile}
          width={250}
        >
          <SideBar />
        </Drawer>
      )}
      <DialogConfirmLogout
        onSubmit={onLogout}
        open={isLogout}
        onCancel={() => {
          setIsLogout(false);
        }}
      />
    </BoxHeader>
  );
}

export default Header;

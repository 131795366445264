import { Col, Form, Input, Row, Select } from "antd";
import kodNegara from "kod-negara";
import { FormBody, FormHeader, FormWrapper } from "./index.style";

const { Option } = Select;

const ShipperDetail = ({ form }) => {
  const onSelect = (value) => {
    const countryCode = value.match("[a-zA-Z]+")[0];
    return form.setFieldsValue({ shipperCountryCode: countryCode });
  };

  return (
    <FormWrapper>
      <FormHeader className="greenBoldTitle">Shipper Details</FormHeader>
      <FormBody>
        <Row gutter={16}>
          <Col lg={6}>
            <Form.Item
              name="shipperName"
              label="Shipper Name"
              rules={[
                {
                  required: true,
                  message: "Receiver name is required",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Shipper Name" />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              name="shipperEmail"
              label="Email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please insert consignee email",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Email" type="email" />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              name="shipperPhoneNumber"
              label="Phone Number"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Consignee's contact number",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Office Number" />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              name="shipperCompanyName"
              label="Company Name"
              rules={[
                {
                  required: true,
                  message: "Company name is required",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Company Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={8}>
            <Form.Item
              name="shipperAddress1"
              label="Address Line 1"
              rules={[
                {
                  required: true,
                  message: "Please insert consignee email",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Address 1" />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              name="shipperAddress2"
              label="Address Line 2"
              rules={[
                {
                  required: false,
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Address 2" />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              name="shipperCity"
              label="City"
              rules={[
                {
                  required: true,
                  message: "City is required",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="City" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={8}>
            <Form.Item
              name="shipperPostal"
              label="Postal Code"
              rules={[
                {
                  required: true,
                  message: "Please insert consignee email",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Postal Code" />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              name="shipperProvinceCode"
              label="Province Code"
              rules={[
                {
                  required: false,
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Province Code" />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              name="shipperCountryCode"
              label="Country Code"
              rules={[
                {
                  required: true,
                  message: "Country Code is required",
                },
              ]}
              hasFeedback
            >
              <Select showSearch style={{ width: "100%" }} onSelect={onSelect}>
                {kodNegara.all().map((item, index) => (
                  <Option key={index} value={item.countryCode}>
                    {item.countryNameEn} ({item.countryCode})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </FormBody>
    </FormWrapper>
  );
};

export default ShipperDetail;

import { Breadcrumb } from 'antd'
import styled from 'styled-components'

export const StyledBreadcrumb = styled(Breadcrumb)`
  &.breadcrumb {
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: hidden;
    text-transform: capitalize;
    font-size: 16px;
    .ant-breadcrumb-link {
      font-size: 14px;
      line-height: 16px;
      color: #858585;
      a {
        color: #1a1a1e;
      }
    }
    .ant-breadcrumb-separator {
      color: #858585;
    }
    @media (max-width: 1024px) {
      height: 32px;
      padding: 6px 12px 6px 0;
      .ant-breadcrumb-link {
        letter-spacing: 0.3px;
      }
    }
  }
`

import styled from "styled-components";

export const ContainerBox = styled.div`
  padding: 24px;
  display: flex;
  background-color: #ffffff;

  main {
    flex: 1;
    width: 100%;
    .table-box {
      padding-top: 12px;
      padding-left: 12px;
      padding-right: 12px;
      .last-updated {
        padding-bottom: 12px;
        font-weight: 300;
        color: #a1aab2;
      }
    }
  }
`;

import { Card, Col, Form, Input, Row, Select } from "antd";
import React from "react";

const { Option } = Select;

const COURIER_API_TYPE = ["dhl", "fedex", "rincos"];

function GeneralAccountDetails() {
  return (
    <Card title="General Account details" style={{ marginBottom: 12 }}>
      <Row gutter={[10]}>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            name="apiType"
            label="Courier"
            rules={[{ required: true, message: "Courier is required" }]}
          >
            <Select>
              {COURIER_API_TYPE.map((type) => (
                <Option key={type} value={type}>
                  {type.toUpperCase()}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            name="accountName"
            label="Account Name"
            rules={[{ required: true, message: "Account Name is required" }]}
            extra="This is the account name that will be used to"
          >
            <Input maxLength={80} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            name="accountNumber"
            label="Account Number"
            rules={[
              {
                required: true,
                message: "Account Number is required",
              },
            ]}
          >
            <Input maxLength={50} type="number" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            name="meterNumber"
            label="Meter number"
            rules={[
              {
                required: true,
                message: "Meter number is required",
              },
            ]}
          >
            <Input maxLength={50} type="number" />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
}
export default GeneralAccountDetails;

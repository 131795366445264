import React from 'react'

const ShopifyPendingPurchases = () => {
  return (
    <div>
      <h1>Shopify Pending Purchases</h1>
    </div>
  )
}

export default ShopifyPendingPurchases

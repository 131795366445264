import styled from 'styled-components'

export const BoxActionsDialog = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin: 5px 10px;
    min-width: 100px;
  }
`

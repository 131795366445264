import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Input, Tooltip } from 'antd'
import classNames from 'classnames'
import QueryString from 'query-string'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import accountApis from '../../apis/accountApi'
import axiosClient from '../../apis/axiosClient'
import { COLORS } from '../../commons/styles'
import Box from '../../components/box/Box'
import { login, setProfile } from '../../store/slices/accountSlice'
import errorHelper from '../../utils/errorHelper'
import LocalStorage from '../../utils/storage'
import yup from '../../utils/yup'
import { BoxForm, Label, StyleForm } from './Login.styles'

const schema = yup.object().shape({
  email: yup.string().email().required().trim(),
  password: yup.string().required().trim().min(6).max(32),
})

function Login() {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = QueryString.parse(history.location.search)

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  })
  const [isLoading, setIsLoading] = React.useState(false)

  const onSubmit = (values) => {
    setIsLoading(true)
    return accountApis
      .login(values)
      .then((res) => {
        setIsLoading(false)
        if (res.success) {
          LocalStorage.set('token', res?.data?.token)
          LocalStorage.set('profile', res?.data?.info)
          axiosClient.defaults.headers.common = {
            Authorization: `Bearer ${res?.data?.token}`,
            'Accept-Language': i18n.language,
          }
          dispatch(login(res.data))
          console.log('RESPONDATA:', res.data)
          dispatch(setProfile(res.data))
          history.push(params.redirect ? params.redirect : '/orders')
        } else {
          throw res
        }
      })
      .catch((err) => {
        setIsLoading(false)
        errorHelper(err)
      })
  }

  return (
    <Box title={t('login')}>
      <BoxForm>
        <StyleForm onSubmit={handleSubmit(onSubmit)}>
          <div className='login__logo'>
            <img src='/images/ic_glover.png' className='img__logo' alt='' />
          </div>
          <h1 className='login__title'>{t('login')}</h1>
          <div className='login__form-detail'>
            <div className='field'>
              <Label>{t('email')}</Label>
              <Tooltip
                color={COLORS.COLOR_TOOLTIP_ERROR}
                placement='top'
                title={errors.email?.message}
              >
                <Controller
                  name='email'
                  control={control}
                  as={Input}
                  className={classNames('field-input', {
                    'error-field-input': !!errors.email?.message,
                  })}
                  defaultValue=''
                />
              </Tooltip>
            </div>
            <div className='field'>
              <Label>{t('password')}</Label>
              <Tooltip
                color={COLORS.COLOR_TOOLTIP_ERROR}
                placement='top'
                title={errors.password?.message}
              >
                <Controller
                  name='password'
                  control={control}
                  defaultValue=''
                  as={Input.Password}
                  className={classNames('field-input', {
                    'error-field-input': !!errors.password?.message,
                  })}
                />
              </Tooltip>
            </div>
            <div className='field btn-login'>
              <Button
                type='primary'
                htmlType='submit'
                block
                loading={isLoading}
              >
                {t('login')}
              </Button>
            </div>
          </div>
        </StyleForm>
      </BoxForm>
    </Box>
  )
}

export default Login

import { Badge, Button, Col, Row, Table, Tag, message } from "antd";
import ordersApi from "apis/ordersApi";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import Path from "../../../commons/breadcrumbs";
import FilterTable from "../../../components/table/FilterTable";
import { CombineModalStyled } from "./styles";

const columns = (t) => [
  {
    title: t("Order"),
    align: "center",
    key: "id",
    dataIndex: "id",
    width: 50,
    className: "order-id",
    sorter: ({ id: a }, { id: b }) => a - b,
    filter: { dataIndex: "id" },
    render: (item, record) =>
      record.combinedOrderId ? (
        <Link
          to={`${Path.ORDER_DETAIL.pathParam(
            record.combinedOrderId
          )}?combine=true`}
        >
          #{record.combinedOrderId}
        </Link>
      ) : (
        <Link to={Path.ORDER_DETAIL.pathParam(item)}>{record.orderNumber}</Link>
      ),
  },
  {
    title: t("Customer"),
    dataIndex: "customerEmail",
    key: "customerEmail",
    sorter: ({ email: a }, { email: b }) =>
      (a || "").localeCompare(b || "", "en", { sensitivity: "base" }),
    filter: { dataIndex: "customerEmail" },
    render: (email, record) => (
      <>
        <div>{email}</div>
        {record.trackingNumber ? (
          <div>
            <Tag color="#28a745" style={{ borderRadius: 20 }}>
              {record.trackingNumber}
            </Tag>
          </div>
        ) : (
          ""
        )}
      </>
    ),
  },
  {
    title: t("Date ordered"),
    dataIndex: "createdAt",
    key: "createdAt",
    width: 200,
    sorter: ({ createdAt: a }, { createdAt: b }) =>
      moment(a).unix() - moment(b).unix(),
    filter: { dataIndex: "createdAt", type: "date" },
    render: (item) => moment(item).format("DD MMM YYYY, hh:MM a"),
  },
  {
    title: t("Financial status"),
    dataIndex: "financialStatus",
    key: "financialStatus",
    width: 160,
    className: "financial-status",
    sorter: ({ financialStatus: a }, { financialStatus: b }) =>
      (a || "").localeCompare(b || "", "en", { sensitivity: "base" }),
    filters: [
      { text: "Paid", value: "paid" },
      { text: "Pending", value: "pending" },
      { text: "Voided", value: "voided" },
    ],
    onFilter: (value, record) => record.financialStatus?.indexOf(value) === 0,
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: (item) => {
      let color = "success";
      switch (item) {
        case "paid":
          color = "success";
          break;
        case "pending":
          color = "warning";
          break;
        case "voided":
          color = "danger";
          break;
        default:
          break;
      }
      return <Badge color={color}>{item}</Badge>;
    },
  },
  {
    title: t("Status"),
    dataIndex: "status",
    key: "status",
    align: "left",
    width: 180,
    sorter: ({ status: a }, { status: b }) =>
      (a || "").localeCompare(b || "", "en", { sensitivity: "base" }),
    filters: [
      { text: "Confirmed", value: "confirmed" },
      { text: "Received", value: "received" },
      { text: "Cancelled", value: "cancelled" },
    ],
    onFilter: (value, record) => record.status?.indexOf(value) === 0,
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: (item) => {
      let color = "#87d068";
      switch (item) {
        case "confirmed":
          color = "#28a745";
          break;
        case "received":
          color = "#17a2b8";
          break;
        case "cancelled":
          color = "#dc3545";
          break;
        default:
          break;
      }
      return (
        <Tag color={color} style={{ borderRadius: 20 }}>
          {item}
        </Tag>
      );
    },
  },
];

function CombineModal({ visible, onCancel, onSuccess }) {
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (visible?.id) {
      setData(
        visible?.possibleCombinedOrders.filter(
          (item) => item.id !== visible.id && item.status !== "completed"
        )
      );
    }
    return () => {
      setSelectedRowKeys([]);
      setData([]);
    };
  }, [visible]);

  const handleCombine = async () => {
    setLoading(true);
    try {
      if (!visible?.combinedOrders?.length) {
        const res = await ordersApi.createCombineOrder({
          orders: [...selectedRowKeys, visible.id],
        });
        if (res?.combinedOrder?.id) {
          message.success("Saved combined order successfully");
          onSuccess();
        } else {
          message.error("Save combined order failure");
        }
        return;
      }
      const removeIds = [];
      const appendIds = [];
      selectedRowKeys.forEach((selectedKey) => {
        if (!visible.combinedOrders.includes(selectedKey)) {
          appendIds.push(selectedKey);
        }
      });
      visible.combinedOrders?.forEach((combinedOrder) => {
        if (!selectedRowKeys.includes(combinedOrder)) {
          removeIds.push(combinedOrder);
        }
      });
      await Promise.all([
        ...removeIds?.map((order) =>
          ordersApi.removeOrderFromCombineOrder({
            id: visible.combinedOrderId,
            data: { order: order.id },
          })
        ),
        ...appendIds?.map((order) =>
          ordersApi.appendOrderToCombineOrder({
            id: visible.combinedOrderId,
            data: { order: order.id },
          })
        ),
      ]);
      message.success("Saved combined order successfully");
      onSuccess();
    } catch (error) {
      message.error("Save combined order failure");
    } finally {
      setLoading(false);
    }
  };

  const onSelectChange = (value) => {
    setSelectedRowKeys(value);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <CombineModalStyled
      title={`Combinable Orders of #${visible?.id}`}
      visible={!!visible}
      onCancel={onCancel}
      footer={null}
      width="80%"
      destroyOnClose
    >
      <Row justify="end" style={{ paddingTop: 16 }}>
        <Col style={{ paddingBottom: 16 }}>
          <Button type="primary" onClick={handleCombine} loading={loading}>
            Save
          </Button>
        </Col>
      </Row>
      <FilterTable
        bordered
        rowSelection={rowSelection}
        columns={columns(t)}
        dataSource={data}
        rowKey="id"
      />
    </CombineModalStyled>
  );
}

CombineModal.propTypes = {
  handleGetList: PropTypes.func,
};

export default CombineModal;

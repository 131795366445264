import styled from "styled-components";
import { BREAKPOINT } from "../../commons/styles";

export const TitlePage = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const ControlHeader = styled.div`
  align-items: center;
  display: flex;

  .icon {
    &__flag-active {
      align-items: center;
      display: flex;

      img {
        height: auto;
        width: 40px;
      }
    }
  }
`;

export const IconMenu = styled.button`
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  border: none;
  outline: none;

  border-radius: 999px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.19),
    0 1px 1px 0 rgba(56, 56, 56, 0.137);
  margin-right: 20px;

  .icon-menu {
    position: relative;
    width: 100%;
    height: 2px;
    min-height: 2px;
    color: inherit;
    background-color: #2f323e;
    display: block;
    transition: all 0.3s ease;

    &:before,
    &:after {
      position: relative;
      display: block;
      color: inherit;
      background-color: inherit;
      left: 0;
      height: 2px;
      min-height: 2px;
      content: "";
      transition: all 0.3s ease;
    }

    &:before {
      width: 100%;
      bottom: -4px;
    }

    &:after {
      width: 100%;
      top: -6px;
    }
  }
`;

export const BoxHeader = styled.header`
  padding: 0 24px;
  height: 60px;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  display: flex;
  align-items: center;
  width: 100vw;

  background-color: #2cabe3;
  display: flex;
  justify-content: flex-end;
  color: #ffffff;
  z-index: 999;

  @media (max-width: ${BREAKPOINT.MD}px) {
    ${TitlePage} {
      display: none;
    }
  }

  .icon__togged,
  .box__right-header {
    display: flex;
    align-items: center;
  }

  .box__right-header {
    .icon__logout {
      margin-left: 20px;
      font-size: 20px;
      cursor: pointer;
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .account-name-header {
    margin-right: 10px;
  }
`;

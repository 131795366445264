import { Button, Card, Space } from "antd";
import React from "react";

const ShipperAccountsCard = ({ shipperDetails, onClick }) => {
  return (
    <Card title="Shipper Accounts" style={{ marginBottom: "10px" }}>
      <Space>
        {shipperDetails.map((shipper, index) => (
          <Button key={index} onClick={() => onClick("shipper", shipper)}>
            {shipper.name.toUpperCase()} - {shipper.id}
          </Button>
        ))}
      </Space>
    </Card>
  );
};

export default ShipperAccountsCard;

import { Typography, Form, Row, Col, message } from "antd";
import Box from "components/box/Box";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import React, { useEffect, useState } from "react";
import Path from "../../commons/breadcrumbs";
import { useTranslation } from "react-i18next";
import shipmentsApi from "apis/shipmentsApi";
import CheckRateCard from "./CheckRateCard";
import ShipperAccountsCard from "./ShipperAccountsCard";
import SummaryCard from "./SummaryCard";
import produce from "immer";
import moment from "moment";
import RateAndErrorColumn from "./RateAndErrorColumn";
import { isEmpty } from "lodash";

const { Title } = Typography;

const RateShipment = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [shipperDetails, setShipperDetails] = useState([]);
  const [selectedShipper, setSelectedShipper] = useState({});
  const [courierAccDetails, setCourierAccDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rates, setRates] = useState([]);
  const [ratesError, setRatesError] = useState([]);
  const [prefillForm, setPrefillForm] = useState({
    userId: "123",
    shipperDetailsId: 1, //< -- ambik dari shiper_details.id
    sampleResponse: false,
    invoiceNumber: "",
    invoiceDate: "2021-06-29T16:00:00.000Z",
    shipmentDate: "2021-06-29T16:00:00.000Z",
    declaredValue: "10.00",
    email: "abc@test.com",
    notificationMessage: "Shipment for #213313123",
    notification: false,
    shopifyNotification: true,
    contents: "Order #213313123",
    documentsOnly: false,
    signatureRequired: false,
    signatureType: "direct",
    shipmentPurpose: "Sale",
    insuranceCost: "",
    shippingCost: "",
    packingCost: "",
    handlingCost: "",
    otherCost: "",
    pieces: [
      {
        id: "1",
        height: "10",
        width: "10",
        depth: "10",
        value: "10",
        reference: "",
      },
    ],
    consignee: {
      companyName: "United Plt. Ltd.",
      personName: "Test",
      personPhone: "60173538293",
      personEmail: "abc@test.com",
      address1: "Test API, Boolee Apartment",
      address2: "14 Boolee St.",
      city: "Reid",
      division: "Australian Capital Territory",
      provinceCode: "AU",
      postal: "2612",
      countryCode: "AU",
    },
  });

  const onFinish = (value) => {
    console.log("ON FINISH", value);
    if (isEmpty(selectedShipper)) {
      return alert("Please select one shipper before proceed");
    } else {
      const rateShipmentFinal = produce(prefillForm, (draft) => {
        draft.pieces[0].weight = String(value.weight);
        draft.invoiceDate = moment().utc().format();
        draft.shipmentDate = moment().utc().format();
        draft.shipper = selectedShipper;
        draft.shipperDetailsId = Number(selectedShipper.id);
        draft.consignee.city = value.city;
      });

      setIsLoading(true);

      console.log("RATE SHIPMENT JSON:", JSON.stringify(rateShipmentFinal));
      shipmentsApi
        .getRateList(rateShipmentFinal)
        .then((res) => {
          if (res === undefined) {
            setIsLoading(true);
            return alert("Response undefined");
          }
          setIsLoading(true);
          const { rates, errors } = res;
          setRates(rates);
          setRatesError(errors);
        })
        .catch((error) => {
          setIsLoading(true);
          console.log("Error", error);
        });
      console.log(rateShipmentFinal);
    }
  };

  const getShipperAccountList = () => {
    //
    const params = {
      page: 1,
      pageSize: 10,
    };
    shipmentsApi
      .getListShipper(params)
      .then((res) => {
        if (res === undefined) {
          return alert("Undefined response");
        }
        const { shipper_details } = res;
        setShipperDetails(shipper_details);
      })
      .catch((error) => {
        message.error(error, 5);
      });
  };

  const getCourierAccountList = () => {
    shipmentsApi.getListShippingAcc().then((res) => {
      if (res === undefined) {
        return alert("Undefined response");
      }

      const { shipping_account } = res;

      setCourierAccDetails(shipping_account);
    });
  };

  const onClick = (from, data) => {
    if (from === "shipper") {
      setSelectedShipper({
        id: data.id,
        name: data.name,
        companyName: data.companyName,
        phonenumber: data.phonenumber,
        email: data.email,
        utcOffset: data.utcOffset,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        provinceCode: data.provinceCode,
      });
    }
  };

  useEffect(() => {
    getShipperAccountList();
    getCourierAccountList();
  }, []);

  useEffect(() => {
    // fill in shipping accounts
    if (!!courierAccDetails.length) {
      const shippingAccounts = [];
      courierAccDetails.map((courier) => {
        return shippingAccounts.push({
          type: courier.apiType,
          id: courier.id,
        });
      });
      setPrefillForm((prev) => {
        return { ...prev, shippingAccounts };
      });
    }
  }, [courierAccDetails]);

  return (
    <>
      <TitleHeader
        listOfBreadcrumb={[
          { name: "Home", path: "/" },
          { name: Path.RATE_SHIPMENT.name },
        ]}
        title={t(Path.USERS_AND_PERMISIONS.name)}
      />
      <Box>
        <Container>
          <Title level={3}>{t("Rate Shipment")}</Title>
          <Row gutter={8}>
            <Col md={5}>
              <SummaryCard
                rates={rates}
                ratesError={ratesError}
                selectedShipper={selectedShipper}
              />
            </Col>
            <Col md={14}>
              <ShipperAccountsCard
                shipperDetails={shipperDetails}
                onClick={onClick}
              />
              <CheckRateCard
                isLoading={isLoading}
                onFinish={onFinish}
                form={form}
              />
            </Col>

            <Col md={5}>
              <RateAndErrorColumn
                rates={rates}
                ratesError={ratesError}
                selectedShipper={selectedShipper}
              />
            </Col>
          </Row>
        </Container>
      </Box>
    </>
  );
};

export default RateShipment;

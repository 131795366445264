import TitleHeader from 'components/title-header'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Path from '../../commons/breadcrumbs'

const SalesAndChannels = () => {
  const { t } = useTranslation()
  return (
    <div>
      <TitleHeader
        listOfBreadcrumb={[
          { name: 'Home', path: '/' },
          { name: Path.SETTINGS.name, path: Path.SETTINGS.pathname },
          { name: Path.SALES_AND_CHANNELS.name },
        ]}
        title={t(Path.SALES_AND_CHANNELS.name)}
      />
    </div>
  )
}

export default SalesAndChannels

import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  InputNumber,
  Space,
} from "antd";
import React, { useState } from "react";
import { FormBody, FormHeader, FormWrapper } from "./index.style";
import moment from "moment";
import produce from "immer";
import { act } from "react-dom/test-utils";
import { debounce } from "lodash";
const { Option } = Select;

const ShipmentDetail = ({
  shipper,
  form,
  setActiveShipper,
  activeCourier,
  shippingAccounts,
  setActiveCourier,
  currency,
  companyNameChecker,
}) => {
  const [checkInsurance, setCheckInsurance] = useState(false);

  const onChangeShipper = (value) => {
    const id = value - 1;
    form.setFieldsValue({
      shipper: shipper[id].id,
      shipperAddress1: shipper[id]?.address1,
      shipperAddress2: shipper[id]?.address2,
      shipperCity: shipper[id]?.city,
      shipperCompanyName: shipper[id]?.companyName,
      shipperCountryCode: shipper[id]?.countryCode,
      shipperEmail: shipper[id]?.email,
      shipperName: shipper[id]?.name,
      shipperPhoneNumber: shipper[id]?.phoneNumber,
      shipperPostal: shipper[id]?.postal,
      shipperProvinceCode: shipper[id]?.provinceCode,
    });
    setActiveShipper(() => {
      return shipper.filter((ship) => ship.id === value - 1);
    });
  };

  const handleCheckInsurance = (value) => {
    form.setFieldsValue({
      insuranceCost: value ? form.getFieldValue("totalDeclaredValue") : "",
    });
    setCheckInsurance(value);
  };

  const setActiveCourierHandler = (id) => {
    const selectedAcc = shippingAccounts.find((ship) => ship.id === id);
    const companyName = form.getFieldValue("companyName");
    form.setFieldsValue({
      fedexDutiesPaidAccount: selectedAcc.accountNumber,
      companyName: companyNameChecker(companyName, selectedAcc),
    });
    setActiveCourier(selectedAcc);
  };

  const documentChecked = (event) => {
    const exportDeclarationItems = form.getFieldValue("exportDeclarationItems");
    const pieces = form.getFieldValue("pieces");
    const itemFirst = produce(exportDeclarationItems, (draft) => {
      draft[0].weight = 0.5;
      draft[0].description = "Documents";
    });
    const piecesFirst = produce(pieces, (draft) => {
      draft[0].weight = 0.5;
    });

    const isDhl = activeCourier.apiType === "dhl";

    if (isDhl) {
      form.setFieldsValue({
        contents: "Documents",
        exportDeclarationItems: itemFirst,
        pieces: piecesFirst,
      });
    }
  };

  const options = [
    { label: "Notify customer via Courier", value: "notification" },
    { label: "Notify customer via Shopify", value: "shopifyNotification" },
    { label: "ETD/PLT", value: "pltShipment" },
    {
      label: "Documents Only",
      value: "documentsOnly",
      disabled: checkInsurance,
      onChange: documentChecked,
    },
    { label: "Require Signature on Delivery", value: "signatureRequired" },
    { label: "Use Own Invoice", value: "useOwnInvoice" },
  ];

  return (
    <FormWrapper>
      <FormHeader className="greenBoldTitle">Shipment Details</FormHeader>
      <FormBody>
        <Row gutter={16}>
          {/* left column */}
          <Col md={18}>
            <Row gutter={16}>
              <Col lg={6}>
                <Form.Item
                  name="courier"
                  label="Courier"
                  tooltip="This will return undefined when you're not fill in all the informations"
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={setActiveCourierHandler}
                  >
                    {shippingAccounts?.map((acc, index) => (
                      <Option key={index} value={acc.id}>
                        {acc.accountName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={6}>
                {/* payload/shipper  */}
                <Form.Item
                  name="shipper"
                  label="Shipper"
                  rules={[
                    {
                      required: true,
                      message: "Shipper is required",
                    },
                  ]}
                  hasFeedback
                >
                  <Select onChange={onChangeShipper}>
                    {shipper?.map((ship, index) => {
                      return (
                        <Option key={index} value={ship.id}>
                          {ship.name} {ship.id}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {activeCourier?.apiType?.toLowerCase() !== "dhl" ? (
                <Col lg={6}>
                  <Form.Item
                    name="fedexPackagingType"
                    label="Packaging Type"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Packaging Type is required",
                      },
                    ]}
                    hasFeedback
                    initialValue="YOUR_PACKAGING"
                  >
                    <Select>
                      <Option value="YOUR_PACKAGING">YOUR_PACKAGING</Option>
                      <Option value="FEDEX_ENVELOPE">FEDEX_ENVELOPE</Option>
                      <Option value="FEDEX_BOX">FEDEX_BOX</Option>
                      <Option value="FEDEX_10KG_BOX">FEDEX_10KG_BOX</Option>
                      <Option value="FEDEX_25KG_BOX">FEDEX_25KG_BOX</Option>
                      <Option value="FEDEX_PAK">FEDEX_PAK</Option>
                      <Option value="FEDEX_TUBE">FEDEX_TUBE</Option>
                    </Select>
                  </Form.Item>
                </Col>
              ) : (
                <Col lg={6}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Form.Item
                      name="insuranceRequested"
                      valuePropName="checked"
                      style={{ marginBottom: -8 }}
                    >
                      <Checkbox
                        onChange={(e) => handleCheckInsurance(e.target.checked)}
                      >
                        Insurance:
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.totalDeclaredValue !==
                        curValues.totalDeclaredValue
                      }
                    >
                      <Form.Item name="insuranceCost">
                        <InputNumber
                          min={1}
                          max={form.getFieldValue("totalDeclaredValue")}
                          style={{ width: "100%" }}
                          disabled={!checkInsurance}
                          addonBefore={currency}
                        />
                      </Form.Item>
                    </Form.Item>
                  </Space>
                </Col>
              )}
              <Col lg={6}>
                <Form.Item name="invoiceRemark" label="Invoice Remark">
                  <Input placeholder="Descriptions..." />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              {activeCourier?.apiType?.toLowerCase() !== "dhl" && (
                <Col lg={6}>
                  <Form.Item
                    name="fedexServiceType"
                    label="Fedex Service type"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Service Type is required",
                    //   },
                    // ]}
                    hasFeedback
                    initialValue="INTERNATIONAL_PRIORITY"
                  >
                    <Select>
                      <Option value="INTERNATIONAL_PRIORITY">
                        INTERNATIONAL_PRIORITY
                      </Option>
                      <Option value="FEDEX_INTERNATIONAL_CONNECT_PLUS">
                        FEDEX_INTERNATIONAL_CONNECT_PLUS
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {activeCourier?.apiType?.toLowerCase() !== "dhl" && (
                <Col lg={6}>
                  {/* payload/shipper  */}
                  <Form.Item
                    name="fedexDutiesPaidAccount"
                    label="Duties Paid Account"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Fedex Duties Paid Account is required",
                    //   },
                    // ]}
                    hasFeedback
                  >
                    <Input placeholder="Duties paid account ..." />
                  </Form.Item>
                </Col>
              )}
              <Col lg={6}>
                <Form.Item
                  name="shipmentPurpose"
                  label="Reason For Shipment"
                  rules={[
                    {
                      required: true,
                      message: "Shipment Purpose is required",
                    },
                  ]}
                  hasFeedback
                  initialValue="SOLD"
                >
                  <Select>
                    <Option value="GIFT">GIFT</Option>
                    <Option value="NOT_SOLD">NOT_SOLD</Option>
                    <Option value="PERSONAL_EFFECTS">PERSONAL_EFFECTS</Option>
                    <Option value="REPAIR_AND_RETURN">REPAIR_AND_RETURN</Option>
                    <Option value="SAMPLE">SAMPLE</Option>
                    <Option value="SOLD">SOLD</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={6}>
                <Form.Item
                  name="shipmentDate"
                  label="Shipment Date"
                  rules={[
                    {
                      required: true,
                      message: "Shipment Date is required",
                    },
                  ]}
                  hasFeedback
                  initialValue={moment()}
                >
                  <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col lg={12}>
                <Form.Item
                  name="orderNumber"
                  label="Order Number"
                  rules={[
                    {
                      required: true,
                      message: "Order Id is required",
                    },
                  ]}
                >
                  <Input placeholder="Order Id" />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item
                  name="notificationMessage"
                  label="Message to customer"
                >
                  <Input placeholder="Message to customer" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={24}>
                <Form.Item name="contents" label="Contents">
                  <Input.TextArea placeholder="Contents..." />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          {/* right column */}
          <Col md={6}>
            <Form.Item
              name="additionalCourierOptions"
              label="Additional Options"
            >
              <Checkbox.Group layout={{ span: 8 }} options={options}>
                <Checkbox />
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
      </FormBody>
    </FormWrapper>
  );
};
export default ShipmentDetail;

import styled from 'styled-components'

export const SearchPanelStyled = styled.form`
  padding: 12px;
  background-color: rgba(0,0,0,.03);
  .filter-btn {
    float: right;
    height: 30px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .reset-btn {
    float: right;
    height: 30px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .sort-select {
    min-width: 180px;
  }
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      border-color: transparent;
      &.ant-tabs-tab-active {
        border-color: #f0f0f0;
      }
    }
  }
  .filter-box {
    padding-top: 12px;
    .ant-space {
      width: 100%;
      .ant-select {
        width: 100%;
      }
    }
  }
`

import { Card, Col, Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import SalesFunnelBtn from "../SalesFunnelBtn";

const { Title } = Typography;

const SalesFunnel = ({ setParam, checkedValue, setCheckedValue }) => {
  const { t } = useTranslation();
  return (
    <>
      {/* Sales Funnel  */}
      <Card style={{ marginBottom: "24px" }}>
        <Title level={5}>{t("Sales Funnel")}</Title>
        <Row gutter={16} style={{ marginTop: 24 }}>
          <Col>
            <SalesFunnelBtn
              btnName="salesFunnel"
              checkedValue={checkedValue}
              setCheckedValue={setCheckedValue}
            >
              Shopify
            </SalesFunnelBtn>
          </Col>

          <Col>
            <SalesFunnelBtn
              btnName="salesFunnel"
              checkedValue={checkedValue}
              setCheckedValue={setCheckedValue}
            >
              Custom
            </SalesFunnelBtn>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default SalesFunnel;
